import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { useLang } from '@/hooks/useLang';
import { markCallbackAsViewed } from '@/modules/callback/api-req';
import { CallbackItem } from '@/modules/callback/types';
import { useAdvertName } from '@/modules/showroom/advert/hooks';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { Avatar } from '@/ui/Avatar/Avatar';
import { Button } from '@/ui/Button/Button';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { useFormatPhone } from '@/ui/PhoneInput/helpers';
import { cn } from '@/utils/cn';
import { ADV_CUR, APP_URL } from '@/utils/consts';
import { copyToClipboard } from '@/utils/copy';
import { isThisYear, isToday, isYesterday } from '@/utils/date';
import { numberWithSpaces } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './CallbackRow.module.scss';
import { ClockIcon, CopyIcon, PersonIcon, PhoneIcon } from './icons';

type Props = {
  data: CallbackItem;
};

export function CallbackRow({ data }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const ad = data?.advertisement;
  const mod = ad?.modification;

  const createdStr = useMemo(() => {
    const createdDate = new Date(data?.created_at * 1000);
    const today = isToday(createdDate);
    const yesterday = isYesterday(createdDate);
    const thisYear = isThisYear(createdDate);

    if (today || yesterday) {
      const time = createdDate.toLocaleDateString(lang, {
        hour: '2-digit',
        minute: '2-digit'
      });
      const str = today ? t('common.today') : t('common.yesterday');
      return `${str}, ${time}`;
    }

    return createdDate.toLocaleDateString(lang, {
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: 'long',
      year: thisYear ? undefined : 'numeric'
    });
  }, [data?.created_at, lang, t]);

  const advertUrl = ad?.url ? `${APP_URL}/${ad.url}` : '';
  const adName = useAdvertName(data.advertisement);
  const formattedPhone = useFormatPhone(data?.phone);

  const imgPlaceholder = useImgPlaceholder();
  const imgSrc = useMemo(() => {
    if (data.advertisement.photos && data.advertisement.photos[0]) {
      const ph = data.advertisement.photos[0];
      return ph.thumbnail_url || ph.photo_url || imgPlaceholder;
    }
    return imgPlaceholder;
  }, [data.advertisement.photos, imgPlaceholder]);

  const onCopyClick = (copyStr: string) => () => {
    copyToClipboard(copyStr, t('common.copied') as string);
  };

  const customer = data?.customer;

  const [viewed, setViewed] = useState(data?.has_been_viewed ?? false);
  const [markLoading, setMarkLoading] = useState(false);

  const handleMarkCallbackAsViewed = async () => {
    if (markLoading || viewed) return;
    setMarkLoading(true);

    try {
      await markCallbackAsViewed({ ids: [data?.id] });
      setViewed(true);
    } catch (error) {
      showAlert({ error });
    } finally {
      setMarkLoading(false);
    }
  };

  return (
    <div className={cls.root} onMouseEnter={handleMarkCallbackAsViewed}>
      <div className={cls.col}>
        <a
          className={cls.link}
          href={advertUrl || ''}
          target="_blank"
          rel="noreferrer"
        />

        <div className={cls.img}>
          <ImgSkeleton
            className={cls.img_inner}
            src={imgSrc}
            alt=""
            imgProxyWidth={170}
            imgProxyHeight={128}
          />
        </div>

        <div className={cn(cls.mob_ad, cls.hide_desktop)}>
          <p className={cls.advert}>{adName}</p>
          <p className={cls.price}>
            {ad.price && (
              <>
                {t('priceCurrency', {
                  price: numberWithSpaces(ad.price, lang),
                  currency: ADV_CUR
                })}
              </>
            )}
          </p>

          <p className={cls.engine}>
            {t('common.commaSeparated', {
              first: t('horsepower', {
                value: mod?.horse_power?.short_name || ''
              }),
              second: mod?.transmission.short_name
            })}
          </p>
        </div>

        {!viewed && <span className={cn(cls.view_badge, cls.hide_desktop)} />}
      </div>

      <div className={cn(cls.col, cls.hide_mob)}>
        <a
          className={cls.link}
          href={advertUrl || ''}
          target="_blank"
          rel="noreferrer"
        />

        <div className={cls.advert_wrap}>
          <p className={cls.advert}>{adName}</p>
          {ad.status?.title !== AdvertStatusType.published && (
            <span className={cls.advert_status}>{ad.status?.name}</span>
          )}
          {!viewed && <span className={cls.view_badge} />}
        </div>

        {mod && (
          <p className={cls.specs}>
            {ad.price && (
              <>
                {t('priceCurrency', {
                  price: numberWithSpaces(ad.price, lang),
                  currency: ADV_CUR
                })}
                <span>{t('common.dot')}</span>
              </>
            )}
            {mod.volume?.short_name} {mod.transmission.short_name}
            <span>{t('common.dot')}</span>
            {t('common.commaSeparated', {
              first: t('horsepower', {
                value: mod.horse_power?.name || ''
              }),
              second: mod.engine.name
            })}
            <span>{t('common.dot')}</span>
            {mod.drive_unit.short_name}
          </p>
        )}

        <p className={cls.date}>{createdStr}</p>
      </div>

      <div className={cls.col}>
        <p className={cn(cls.col_label, cls.hide_mob)}>
          {t('credits.req.aboutLead')}
        </p>
        <p className={cn(cls.col_label, cls.hide_desktop)}>
          {t('callback.waitingForCall')}
        </p>

        <button
          className={cn(cls.contact, cls.hide_mob)}
          type="button"
          onClick={onCopyClick(data?.name)}
        >
          <PersonIcon />
          <span>{data?.name}</span>
          <CopyIcon />
        </button>
        <button
          className={cn(cls.contact, cls.hide_mob)}
          type="button"
          onClick={onCopyClick(formattedPhone)}
        >
          <PhoneIcon />
          <span>{formattedPhone}</span>
          <CopyIcon />
        </button>
        <button
          className={cls.contact}
          type="button"
          onClick={onCopyClick(data?.name)}
        >
          <ClockIcon />
          <span>{data?.expected_call_time?.name}</span>
        </button>
      </div>

      <div className={cn(cls.mob_contact, cls.hide_desktop)}>
        <Avatar
          size={36}
          user={
            customer
              ? {
                  id: customer.id,
                  name: customer.name || String(customer.id)
                }
              : undefined
          }
          src={customer?.avatar?.photo_url}
        />
        <div className={cls.mob_footer}>
          <p className={cls.customer_name}>
            {customer?.name || String(customer?.id)}
          </p>
          <p className={cls.date}>{createdStr}</p>
        </div>
        <a href={`tel:${data?.phone}`}>
          <Button
            variant="outline"
            color="black"
            size="compact"
            onClick={handleMarkCallbackAsViewed}
          >
            <PhoneIcon />
            {t('common.call')}
          </Button>
        </a>
      </div>
    </div>
  );
}
