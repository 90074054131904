import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useSpecsEffect } from '@/modules/showroom/advert/create/Specs/helpers';
import { useTransportYears } from '@/modules/transport/api';
import { TransportModel, TransportYear } from '@/modules/transport/types';
import { Skeleton } from '@/ui/Skeleton';
import { cn } from '@/utils/cn';
import { MAX_PG_LIMIT } from '@/utils/consts';

import { ChevronIcon } from './icons';
import cls from './Year.module.scss';

const SHORT_COUNT = 15;
const skeletons = new Array(12).fill(0);

type Props = {
  model: TransportModel;
  yearsList: Nullable<TransportYear[]>;
  year: Nullable<TransportYear>;
  onChange: (y: TransportYear) => void;
  disabled?: boolean;
};

export const Year: React.FC<Props> = ({
  model,
  yearsList,
  year,
  onChange,
  disabled
}) => {
  const { t } = useTranslation();

  const [isShort, setShort] = useState(true);

  const req = useMemo(
    () => ({ model_id: model.id, limit: MAX_PG_LIMIT }),
    [model]
  );
  const [years, isLoading] = useTransportYears(req);
  const resultList = useMemo(() => yearsList || years, [years, yearsList]);

  const toggleShort = () => {
    setShort((p) => !p);
  };

  const list = useMemo(() => {
    if (!resultList) return [];
    return isShort ? resultList.slice(0, SHORT_COUNT) : resultList;
  }, [resultList, isShort]);

  useSpecsEffect(year, resultList, onChange);

  return (
    <div className={cls.root}>
      <div className={cls.list_wrap}>
        {!isLoading && (
          <ul className={cls.list}>
            {list.map((y) => (
              <li key={y.id}>
                <button
                  className={cls.year_btn}
                  type="button"
                  onClick={() => onChange(y)}
                  disabled={disabled}
                >
                  {y.name}
                </button>
              </li>
            ))}

            {resultList && resultList.length > SHORT_COUNT && (
              <li
                className={cn(cls.toggler, { [cls.toggler_opened]: !isShort })}
              >
                <button
                  className={cls.year_btn}
                  type="button"
                  onClick={toggleShort}
                >
                  {t(isShort ? 'common.older' : 'common.fold')}
                  <ChevronIcon />
                </button>
              </li>
            )}
          </ul>
        )}

        {isLoading && (
          <ul className={cls.list}>
            {skeletons.map((_, i) => (
              <li key={i}>
                <Skeleton width="100%" height={22} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
