import { cn } from '@/utils/cn';

import cls from './Card.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  flat?: boolean;
};

export function Card({ children, className, style, flat }: Props) {
  return (
    <div
      className={cn(cls.root, className, { [cls.root_flat]: flat })}
      style={style}
    >
      {children}
    </div>
  );
}
