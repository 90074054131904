import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { copyToClipboard } from '@/utils/copy';
import { isMobile } from '@/utils/detect';

export function useShare(text?: string, url?: string) {
  const { t } = useTranslation();

  const onShareClick = useCallback(() => {
    const { origin, pathname } = window.location;
    const defaultUrl = origin + pathname;
    const copyUrl = url || defaultUrl;

    const shareData = { text, url: copyUrl };
    const canShare = !!navigator.canShare && navigator.canShare(shareData);
    if (canShare && isMobile()) {
      navigator.share(shareData);
    } else {
      copyToClipboard(copyUrl, t('common.copied') as string);
    }
  }, [t, text, url]);

  return onShareClick;
}
