import { useTranslation } from 'react-i18next';

import { OptionI } from '@/app/types';
import { cn } from '@/utils/cn';

import cls from './EditorCheckList.module.scss';
import { CheckIcon, DotIcon, RemoveIcon } from './icons';

interface CheckListData extends OptionI {
  checked: boolean;
  disabled: boolean;
  section?: string;
}

type Props = {
  list: CheckListData[];
  onClick: (id: OptionI['id']) => void;
  onResetClick?: () => void;
};

export function EditorCheckList({ list, onClick, onResetClick }: Props) {
  const { t } = useTranslation();
  const handleClick = (id: OptionI['id']) => {
    return () => {
      onClick(id);
    };
  };

  return (
    <div className={cls.root}>
      <ul className={cls.list}>
        {list.map((v, i) => {
          const somePrevDisabled = list
            .slice(0, i)
            .some((item) => item.disabled);
          const disabled = v.disabled || somePrevDisabled;

          return (
            <li key={v.id}>
              <button
                className={cn(cls.text, { [cls.text_checked]: v.checked })}
                type="button"
                onClick={handleClick(v.id)}
                disabled={disabled}
              >
                {v.checked && !disabled ? <CheckIcon /> : <DotIcon />}
                <span>{v.text}</span>
              </button>
            </li>
          );
        })}
      </ul>

      {onResetClick && (
        <button className={cls.reset_btn} type="button" onClick={onResetClick}>
          <RemoveIcon />
          {t('common.resetAll')}
        </button>
      )}
    </div>
  );
}
