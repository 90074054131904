/* eslint-disable import/no-duplicates */

import en from 'date-fns/locale/en-GB';
import ru from 'date-fns/locale/ru';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import 'react-datepicker/dist/react-datepicker.css';
import { Nullable } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import { DEFAULT_LANG, LANGS_MAP } from '@/modules/i18n';
import { cn } from '@/utils/cn';
import { getDateString } from '@/utils/date';

import cls from './DateTimePicker.module.scss';
import { ArrowIcon } from './icons';

registerLocale(LANGS_MAP.ru, ru);
registerLocale(LANGS_MAP.en, en);
setDefaultLocale(DEFAULT_LANG);

type Props = {
  date?: Nullable<Date>;
  endDate?: Nullable<Date>;
  minDate?: Nullable<Date>;
  maxDate?: Nullable<Date>;
  onChange?: (date: Nullable<Date> | undefined) => void;
  onDatesChange?: (dates: Array<Nullable<Date>>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  inline?: boolean;
  doubleMonths?: boolean;
  showTimeInput?: boolean;
  onlyTime?: boolean;
  dateFormat?: string;
  label?: Nullable<string>;
};

export const DateTimePicker: React.FC<Props> = ({
  date,
  endDate,
  minDate,
  maxDate,
  onChange,
  onDatesChange,
  onBlur,
  inline,
  doubleMonths,
  showTimeInput,
  onlyTime,
  dateFormat,
  label
}) => {
  const { t } = useTranslation();
  const [lang] = useLang();
  const defaultDateFormat = showTimeInput ? 'd MMMM, p' : 'dd.MM.yyyy';

  return (
    <div
      className={cn(cls.root, {
        [cls.root_only_time]: onlyTime,
        [cls.root_double]: doubleMonths
      })}
    >
      {!inline && <span className={cls.label}>{label}</span>}
      <DatePicker
        minDate={minDate}
        maxDate={maxDate}
        locale={lang}
        selected={date}
        selectsRange={!!onDatesChange}
        startDate={onDatesChange ? date : null}
        endDate={endDate}
        openToDate={endDate || date || undefined}
        onChange={(d) => {
          if (Array.isArray(d)) {
            if (onDatesChange) {
              onDatesChange(d);
            }
          } else if (onChange) {
            onChange(d);
          }
        }}
        onBlur={onBlur}
        dateFormat={dateFormat || defaultDateFormat}
        timeInputLabel={`${t('time')}:`}
        showTimeInput={showTimeInput}
        shouldCloseOnSelect={!showTimeInput}
        className={cls.field}
        dayClassName={() => cls.day}
        focusSelectedMonth={true}
        monthsShown={doubleMonths ? 2 : undefined}
        inline={inline}
        disabledKeyboardNavigation
        fixedHeight
        renderCustomHeader={({
          date,
          monthDate,
          customHeaderCount,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div className={cls.header}>
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              style={
                doubleMonths && customHeaderCount === 1
                  ? { visibility: 'hidden' }
                  : undefined
              }
            >
              <ArrowIcon />
            </button>
            <span>
              {getDateString(doubleMonths ? monthDate : date, lang, {
                day: undefined,
                year: 'numeric'
              })}
            </span>
            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              style={
                doubleMonths && customHeaderCount === 0
                  ? { visibility: 'hidden' }
                  : undefined
              }
            >
              <ArrowIcon />
            </button>
          </div>
        )}
      />
    </div>
  );
};
