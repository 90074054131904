import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, OptionI } from '@/app/types';
import { useWindowClick } from '@/hooks/useWindowClick';
import { Dropdown, DropdownItem } from '@/ui/Dropdown/Dropdown';
import { Input } from '@/ui/Input/Input';
import { cn } from '@/utils/cn';
import { escapePlus } from '@/utils/format';

import { SELECT_NO_VALUE } from './helpers';
import { ChevronIcon } from './icons';
import cls from './Select.module.scss';

interface Props {
  name: string;
  value?: Nullable<OptionI>;
  options: Array<OptionI>;
  onChange?: (option: OptionI) => void;
  onClose?: () => void;
  label?: Nullable<string>;
  disabled?: boolean;
  readOnly?: boolean;
  dropdownCls?: string;
  dropdownFullWidth?: boolean;
  dropdownToLeft?: boolean;
  dropdownTop?: boolean;
  dropdownModal?: boolean;
  error?: boolean;
  showSearch?: boolean;
  active?: boolean;
  countries?: boolean;
  size?: 's' | 'default';
  emptyOptionsLabel?: string;
  renderOption?: (opt: OptionI, closeDropdown: () => void) => React.ReactNode;
  renderValue?: (value?: Nullable<OptionI>) => React.ReactNode;
}

export const Select: React.FC<Props> = ({
  name,
  value,
  onChange,
  onClose,
  options,
  label,
  disabled,
  readOnly,
  dropdownCls,
  error,
  showSearch,
  active,
  countries,
  dropdownFullWidth,
  dropdownToLeft,
  dropdownTop,
  dropdownModal = true,
  size = 'default',
  emptyOptionsLabel,
  renderOption,
  renderValue
}) => {
  const { t } = useTranslation();
  const rootRef = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const closeDropdown = () => {
    setOpen(false);
    if (onClose) onClose();
  };
  const onSelectBtnClick = () => {
    if (isOpen) {
      closeDropdown();
    } else {
      setOpen(true);
    }
  };

  useWindowClick((e) => {
    const el = e.target as HTMLElement | null;
    const clickedRoot = el?.closest(`.${escapePlus(cls.root)}`);
    const thisRoot = rootRef?.current;

    if (!!clickedRoot && clickedRoot !== thisRoot) {
      closeDropdown();
    }
  });

  // Search
  const [search, setSearch] = useState('');
  const searchLow = search.toLowerCase().trim();
  const foundOptions = useMemo(
    () =>
      showSearch && searchLow
        ? options
            .filter(
              (o) =>
                o.text.toLowerCase().includes(searchLow) ||
                o.extra?.toLowerCase().includes(searchLow) ||
                o.keywords?.toLowerCase().includes(searchLow)
            )
            .sort((a, b) => {
              const bText = countries
                ? b.text.split(' ').slice(1).join(' ').toLowerCase()
                : b.text.toLowerCase();
              const aText = countries
                ? a.text.split(' ').slice(1).join(' ').toLowerCase()
                : a.text.toLowerCase();
              const bVal = bText.startsWith(searchLow) ? 1 : 0;
              const aVal = aText.startsWith(searchLow) ? 1 : 0;
              return bVal - aVal;
            })
        : options,
    [showSearch, searchLow, options, countries]
  );

  const filtered = foundOptions.filter((v) => v.id !== SELECT_NO_VALUE);
  const fullWidth =
    typeof dropdownFullWidth === 'boolean' ? dropdownFullWidth : true;

  return (
    <>
      <div
        ref={rootRef}
        className={cn(cls.root, {
          [cls.root_small]: size === 's',
          [cls.root_active]: active,
          [cls.root_open]: isOpen,
          [cls.root_selected]: !!value,
          [cls.root_error]: !!error,
          [cls.root_disabled]: !!disabled,
          [cls.root_labeled]: !!label
        })}
      >
        {label && <span className={cls.label}>{label}</span>}

        <button
          className={cls.select_btn}
          type="button"
          onClick={onSelectBtnClick}
          disabled={disabled || readOnly}
        >
          {renderValue ? (
            renderValue(value)
          ) : (
            <span className={cls.button_name}>
              <OptionText value={value} countries={countries} />
            </span>
          )}

          <ChevronIcon className={cls.chevron} />
        </button>

        {!readOnly && (
          <Dropdown
            name={name}
            isOpen={isOpen}
            close={closeDropdown}
            className={dropdownCls}
            anchorClassName={cls.dropdown}
            modalClassName={cn({ [cls.dropdown_modal]: showSearch })}
            fullWidth={fullWidth}
            toLeft={dropdownToLeft}
            listTop={dropdownTop}
            withModal={dropdownModal}
          >
            {showSearch && (
              <DropdownItem className={cls.li}>
                <div>
                  <Input
                    value={search}
                    onChange={(e) => setSearch(e.currentTarget.value)}
                    placeholder={t('common.search')}
                    small
                  />
                </div>
              </DropdownItem>
            )}

            {filtered.length !== 0 &&
              filtered.map((v) => {
                const isActive = value?.id === v.id;

                if (renderOption)
                  return (
                    <DropdownItem key={v.id} active={isActive}>
                      {renderOption(v, closeDropdown)}
                    </DropdownItem>
                  );

                return (
                  <DropdownItem key={v.id} active={isActive}>
                    <button
                      className={cn(cls.list_btn, {
                        [cls.list_btn_active]: isActive
                      })}
                      type="button"
                      onClick={() => {
                        closeDropdown();
                        if (onChange) onChange(v);
                      }}
                    >
                      <span
                        className={cn(cls.list_text, {
                          [cls.list_text_fullwidth]: fullWidth
                        })}
                      >
                        <OptionText value={v} countries={countries} />
                      </span>
                      {v.extra && <span className={cls.extra}>{v.extra}</span>}
                    </button>
                  </DropdownItem>
                );
              })}

            {filtered.length === 0 && (
              <DropdownItem>
                <p className={cls.empty}>
                  {emptyOptionsLabel || t('common.emptySelect')}
                </p>
              </DropdownItem>
            )}
          </Dropdown>
        )}
      </div>
    </>
  );
};

Select.displayName = 'Select';

function OptionText({
  value,
  countries
}: {
  value?: OptionI | null;
  countries?: boolean;
}) {
  if (!countries || !value) return <>{value?.text || ''}</>;
  return (
    <>
      <span className="country-emoji">{value.text.split(' ')[0]}</span>
      {value.text.split(' ').slice(1).join(' ')}
    </>
  );
}
