import { PromotionPackageOption } from '@/modules/promotion/types';
import { cn } from '@/utils/cn';


import { CheckIcon, UncheckIcon } from './icons';
import cls from './Package.module.scss';

type Props = {
  options: PromotionPackageOption[];
  isOpen: boolean;
  close: () => void;
};

export function PackageOptions({ options, isOpen, close }: Props) {
  return (
    <div
      className={cn(cls.options, { [cls.options_open]: isOpen })}
      onMouseLeave={close}
    >
      <ul className={cls.options_list}>
        {options.map((opt) => (
          <li key={opt.id}>
            <div
              className={cn(cls.option, {
                [cls.option_disabled]: !opt.include
              })}
            >
              {opt.name}
              {opt.include ? <CheckIcon /> : <UncheckIcon />}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
