import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionI } from '@/app/types';
import { useAccountStore } from '@/modules/accounts/store';
import { CropperHeader } from '@/modules/showroom/ShowroomEdit/CoverLogo/Cover/CropperHeader';
import { NameRow } from '@/modules/showroom/ShowroomEdit/CoverLogo/NameRow/NameRow';
import {
  Header,
  ViewType as TabType
} from '@/modules/showroom/ShowroomEdit/Header/Header';
import { Avatar } from '@/ui/Avatar/Avatar';
import { Button } from '@/ui/Button/Button';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { Tabs } from '@/ui/Tabs/Tabs';
import { cn } from '@/utils/cn';

import { DesktopIcon, TabletIcon, PhoneIcon } from './icons';
import cls from './Preview.module.scss';

type Props = {
  name: string;
  logoUrl: string;
  src: string;
  isOpen: boolean;
  close: () => void;
  onBackClick: () => void;
  onConfirmClick: () => void;
  hideBackButton?: boolean;
};

export function Preview({
  name,
  logoUrl,
  src,
  isOpen,
  close,
  onBackClick,
  onConfirmClick,
  hideBackButton
}: Props) {
  const { t } = useTranslation();

  const accountInfo = useAccountStore((s) => s.accountInfo);
  const id = accountInfo?.id || 1;

  const createdDate = useMemo(
    () =>
      accountInfo
        ? new Date(accountInfo.showroom_created_at * 1000)
        : new Date(),
    [accountInfo]
  );

  // Tabs
  const [tab, setTab] = useState<TabType>(TabType.desktop);
  const tabs = useMemo(
    () => [
      {
        id: TabType.desktop as string,
        text: t(`cover.tabs.${TabType.desktop}`),
        icon: <DesktopIcon />
      },
      {
        id: TabType.tablet as string,
        text: t(`cover.tabs.${TabType.tablet}`),
        icon: <TabletIcon />
      },
      {
        id: TabType.phone as string,
        text: t(`cover.tabs.${TabType.phone}`),
        icon: <PhoneIcon />
      }
    ],
    [t]
  );
  const activeTab = tabs.find((tb) => tb.id === tab);
  const onTabChange = (tb: OptionI) => {
    setTab(tb.id as TabType);
  };

  return (
    <AdaptiveModal name="showroom-cover-preview" isOpen={isOpen} close={close}>
      <div className={cls.root}>
        <CropperHeader
          title={t('cover.preview.title')}
          text={t('cover.preview.text')}
          onCloseClick={close}
          onBackClick={onBackClick}
          hideBack={hideBackButton}
        />
        <div className={cls.tabs}>
          <Tabs
            variant="primary"
            tabs={tabs}
            active={activeTab}
            onChange={onTabChange}
          />
        </div>

        <div className={cls.preview_wrap}>
          <div
            className={cn(cls.preview, {
              [cls.preview_tablet]: tab === TabType.tablet,
              [cls.preview_phone]: tab === TabType.phone
            })}
          >
            <Header
              viewType={tab}
              coverNode={<img className={cls.preview_img} src={src} />}
              logoNode={
                <div className={cls.avatar}>
                  <Avatar src={logoUrl} user={{ id, name }} />
                </div>
              }
              rowNode={
                <NameRow
                  name={name}
                  date={createdDate}
                  nameClassName={cls.name}
                />
              }
            />
          </div>
        </div>

        <Button onClick={onConfirmClick} color="black" fullWidth>
          {t('common.confirm')}
        </Button>
      </div>
    </AdaptiveModal>
  );
}
