import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '@/hooks/useDebounce';
import { useLang } from '@/hooks/useLang';
import { useChatDetailStore } from '@/modules/chat/Detail/store';
import { ChatDirection } from '@/modules/chat/types';
import { useChatUtilsStore } from '@/modules/chat/utils-store';
import { Spinner } from '@/ui/Spinner/Spinner';
import { cn } from '@/utils/cn';

import { ScrollState, groupMessages } from './helpers';
import { MessageWrap } from './Message/MessageWrap';
import cls from './Messages.module.scss';

type Props = {
  detailID: number;
};

export function Messages({ detailID }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const scrollToChatMsgId = useChatUtilsStore((s) => s.scrollToChatMsgId);
  const setScrollToChatMsgId = useChatUtilsStore((s) => s.setScrollToChatMsgId);

  const messages = useChatDetailStore((s) => s.messages);
  const msgsScrollKey = useChatDetailStore((s) => s.msgsScrollKey);
  const setListRef = useChatDetailStore((s) => s.setListRef);
  const isAreaLoading = useChatDetailStore((s) => s.isAreaLoading);
  const fromTop = useChatDetailStore((s) => s.fromTop);
  const isTopLoaded = useChatDetailStore((s) => s.isTopLoaded);
  const fromBot = useChatDetailStore((s) => s.fromBot);
  const isBotLoaded = useChatDetailStore((s) => s.isBotLoaded);
  const isAllMsgsLoaded = useChatDetailStore((s) => s.isAllMsgsLoaded);
  const unreadMsgId = useChatDetailStore((s) => s.unreadMsgId);
  const loadMsgs = useChatDetailStore((s) => s.loadMsgs);
  const pullMsgs = useChatDetailStore((s) => s.pullMsgs);
  const unreadMessages = useChatDetailStore((s) => s.unreadMessages);
  const readMessages = useChatDetailStore((s) => s.readMessages);
  const scrollToMsg = useChatDetailStore((s) => s.scrollToMsg);
  const resetMessages = useChatDetailStore((s) => s.resetMessages);

  const debouncedUnreadLen = useDebounce(unreadMessages.length, 600);
  const notDeletedMessages = useMemo(
    () => messages.filter((m) => !m.is_deleted),
    [messages]
  );
  const groupedMessages = useMemo(
    () => groupMessages(notDeletedMessages, t('common.today'), lang),
    [notDeletedMessages, t, lang]
  );

  // Scroll states
  const [abortController] = useState<AbortController>(new AbortController());

  const [scrollState, setScrollState] = useState<ScrollState>(
    ScrollState.initial
  );
  const scrollStateTimer = useRef<number>(-1);
  const [isScrolled, setScrolled] = useState<boolean>(false);
  const [lastScroll, setLastScroll] = useState<number>(0);
  const [msgsContainerRef, setRef] = useState<HTMLDivElement | null>(null);

  // Load / Pull
  useEffect(() => {
    return () => {
      resetMessages();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scrollToChatMsgId) {
      scrollToMsg(scrollToChatMsgId, () => setScrollToChatMsgId(null));
    } else if (!isAreaLoading && messages.length <= 0) {
      loadMsgs();
    }
  }, [
    scrollToChatMsgId,
    setScrollToChatMsgId,
    scrollToMsg,
    loadMsgs,
    messages.length,
    isAreaLoading
  ]);

  useEffect(() => {
    pullMsgs(detailID, abortController);

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abortController, detailID]);

  // On scroll
  const handleScroll = useCallback(
    ({ currentTarget }: React.UIEvent<HTMLDivElement, UIEvent>) => {
      window.clearTimeout(scrollStateTimer.current);
      setScrollState(ScrollState.scroll);

      const el = currentTarget;
      const scroll = el.scrollTop;

      // Top
      const isScrollingTop = scroll < lastScroll;
      setLastScroll(scroll);

      const allMsgsLoaded = isAllMsgsLoaded();
      const canLoadTop =
        isScrollingTop &&
        scroll <= el.offsetHeight * 2.5 &&
        !fromTop &&
        !isTopLoaded &&
        !allMsgsLoaded;

      if (canLoadTop) {
        loadMsgs(ChatDirection.direct);
      }

      // Bot
      const canLoadBot =
        !isScrollingTop &&
        scroll >= el.scrollHeight - el.clientHeight * 2 &&
        !fromBot &&
        !isBotLoaded &&
        !allMsgsLoaded;

      if (canLoadBot) {
        loadMsgs(ChatDirection.reverse);
      }

      scrollStateTimer.current = window.setTimeout(() => {
        setScrollState(ScrollState.stopped);
      }, 200);
    },
    [
      fromBot,
      fromTop,
      isAllMsgsLoaded,
      isBotLoaded,
      isTopLoaded,
      lastScroll,
      loadMsgs
    ]
  );

  // Read messages
  useEffect(() => {
    if (scrollState === ScrollState.stopped) {
      readMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollState]);

  useEffect(() => {
    if (scrollState === ScrollState.initial && debouncedUnreadLen > 0) {
      readMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollState, debouncedUnreadLen]);

  // Scroll messages
  useEffect(() => {
    if (msgsContainerRef && !isScrolled && messages.length > 0) {
      msgsContainerRef.scrollTop = msgsContainerRef.scrollHeight;
      setScrolled(true);
    }
  }, [msgsContainerRef, isScrolled, messages.length]);

  useEffect(() => {
    if (msgsContainerRef && msgsScrollKey > 0) {
      msgsContainerRef.scrollTop = msgsContainerRef.scrollHeight;
    }
  }, [msgsContainerRef, msgsScrollKey]);

  return (
    <div
      className={cn(cls.root, 'hide-scrollbar', {
        [cls.root_smooth]: isScrolled
      })}
      onScroll={handleScroll}
      ref={(r) => {
        setRef(r);
        setListRef(r);
      }}
    >
      {isAreaLoading ? (
        <div className={cls.spinner}>
          <Spinner />
        </div>
      ) : (
        <div className={cls.messages}>
          {groupedMessages.map((group) => {
            const { date, messages: groupMessages } = group;

            return (
              <section key={date}>
                <div className={cls.date_divider}>
                  <p>{date}</p>
                </div>

                {groupMessages.map((msg) => (
                  <MessageWrap
                    key={msg.id}
                    unreadMsgId={unreadMsgId}
                    messages={messages}
                    msg={msg}
                  />
                ))}
              </section>
            );
          })}
        </div>
      )}
    </div>
  );
}
