import { useTranslation } from 'react-i18next';

import { Lottie } from '@/ui/Lottie';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { cn } from '@/utils/cn';

import carImg from './car.svg';
import { CloseIcon } from './icons';
import cls from './VinSuggest.module.scss';

type Props = {
  isOpen: boolean;
  close: () => void;
};

export function VinHelp({ isOpen, close }: Props) {
  const { t } = useTranslation();

  return (
    <AdaptiveModal
      containerClass={cn(cls.modal_container, cls.modal_container_imp)}
      name="find-vin-help"
      isOpen={isOpen}
      close={close}
    >
      <div className={cls.help_root}>
        <div className={cls.help_header}>
          <h2 className={cls.help_title}>{t('vin.help.where')}</h2>
          <button className={cls.close_btn} type="button" onClick={close}>
            <CloseIcon />
          </button>
        </div>

        <div className={cls.help_box}>
          <h2 className={cls.title}>{t('vin.help.docsTitle')}</h2>
          <p className={cls.text}>{t('vin.help.docsText')}</p>
          <div className={cls.img}>
            <Lottie src="vin" />
          </div>
        </div>

        <div className={cls.help_box}>
          <h2 className={cls.title}>{t('vin.help.carTitle')}</h2>
          <p className={cls.text}>{t('vin.help.carText')}</p>
          <div className={cls.img} style={{ width: 492 }}>
            <img src={carImg} alt="Car" />
          </div>
        </div>
      </div>
    </AdaptiveModal>
  );
}
