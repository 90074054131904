
import { Nullable } from '@/app/types';
import { Button } from '@/ui/Button/Button';
import { ButtonLink } from '@/ui/Button/ButtonLink';
import { ButtonProps } from '@/ui/Button/ButtonProps';
import { Lottie } from '@/ui/Lottie';
import { cn } from '@/utils/cn';

import cls from './Placeholder.module.scss';

type Props = {
  imgSrc?: string;
  lottieSrc?: string;
  title?: Nullable<string>;
  text?: Nullable<string>;
  compact?: boolean;
  to?: string;
  buttonText?: Nullable<string>;
  onButtonClick?: (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  buttonColor?: ButtonProps['color'];
  buttonVariant?: ButtonProps['variant'];
  buttonFullWidth?: ButtonProps['fullWidth'];
};

export const Placeholder: React.FC<Props> = (props) => {
  const {
    imgSrc,
    lottieSrc,
    title,
    text,
    compact,
    to,
    buttonText,
    onButtonClick,
    buttonColor,
    buttonVariant,
    buttonFullWidth
  } = props;

  const variant = buttonVariant
    ? buttonVariant
    : compact
    ? 'secondary'
    : 'primary';
  const color = buttonColor ? buttonColor : compact ? 'blue' : 'green';

  return (
    <div className={cn(cls.root, { [cls.root_compact]: compact })}>
      <div className={cls.row}>
        <>
          {imgSrc && <img src={imgSrc} alt="Illustration" />}
          {lottieSrc && (
            <div className={cls.lottie}>
              <Lottie src={lottieSrc} loop />
            </div>
          )}
        </>

        {(title || text) && (
          <div>
            {title && <h2 className={cn('h1', cls.title)}>{title}</h2>}
            {text && <p className={cls.text}>{text}</p>}
          </div>
        )}
      </div>

      {to && buttonText && (
        <ButtonLink
          onClick={onButtonClick}
          variant={variant}
          color={color}
          to={to}
          fullWidth={buttonFullWidth}
        >
          {buttonText}
        </ButtonLink>
      )}

      {!to && !!onButtonClick && buttonText && (
        <Button
          onClick={onButtonClick}
          variant={variant}
          color={color}
          fullWidth={buttonFullWidth}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};
