import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionI } from '@/app/types';
import { Chats } from '@/modules/chat/Detail/Profile/Chats/Chats';
import { useChatHasAttachments } from '@/modules/chat/hooks';
import { ChatCompanion } from '@/modules/chat/types';
import { Spinner } from '@/ui/Spinner/Spinner';
import { Tabs } from '@/ui/Tabs/Tabs';
import { cn } from '@/utils/cn';

import cls from './Attachments.module.scss';
import { Files } from './Files/Files';
import { Photos } from './Photos/Photos';
import { Videos } from './Videos/Videos';

const TABS = {
  photos: 'photos',
  videos: 'videos',
  files: 'files',
  chats: 'chats'
};

type Props = {
  chatId: number;
  isHeader?: boolean;
  companion: ChatCompanion;
};

export const Attachments: React.FC<Props> = ({
  chatId,
  isHeader,
  companion
}) => {
  const { t } = useTranslation();
  const [hasAttachments, loading] = useChatHasAttachments(chatId);

  const tabs = useMemo<OptionI[]>(
    () => [
      { id: TABS.photos, text: t('chat.tabs.photos') },
      { id: TABS.videos, text: t('chat.tabs.videos') },
      { id: TABS.files, text: t('chat.tabs.files') },
      { id: TABS.chats, text: t('chat.tabs.chats') }
    ],
    [t]
  );
  const [tab, setTab] = useState<OptionI>(tabs[0]);

  return (
    <div className={cls.root}>
      {loading && <Spinner centered color="var(--clr-blue)" size={36} />}

      {!loading && typeof hasAttachments === 'boolean' && (
        <>
          <div className={cls.box}>
            <div className={cn(cls.tabs, { [cls.tabs_header]: isHeader })}>
              <Tabs
                tabs={tabs}
                active={tab}
                onChange={setTab}
                variant="stroke"
              />
            </div>

            {tab.id === TABS.photos && <Photos chatId={chatId} />}
            {tab.id === TABS.videos && <Videos chatId={chatId} />}
            {tab.id === TABS.files && <Files chatId={chatId} />}
            {tab.id === TABS.chats && (
              <Chats companion={companion} chatId={chatId} />
            )}
          </div>
        </>
      )}
    </div>
  );
};
