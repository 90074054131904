import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { blockUserViaChat, unblockUserViaChat } from '@/modules/accounts/api';
import { AttachedList } from '@/modules/chat/Detail/Form/Attach/AttachedList/AttachedList';
import { mapUploadItems } from '@/modules/chat/Detail/Form/Attach/helpers';
import { useChatTextareaScrollHeight } from '@/modules/chat/Detail/Form/hooks';
import {
  MAX_CHAT_TEXT_LEN,
  MAX_MEDIA_CONTENT_LEN
} from '@/modules/chat/Detail/Messages/helpers';
import { useChatDetailStore } from '@/modules/chat/Detail/store';
import { ChatBlockStatusType } from '@/modules/chat/types';
import { useChatUtilsStore } from '@/modules/chat/utils-store';
import {
  handleImage,
  handleVideo,
  UploadItem
} from '@/modules/files/upload/helpers';
import { EmojiPicker } from '@/ui/EmojiPicker/EmojiPicker';
import { Spinner } from '@/ui/Spinner/Spinner';
import { TextareaFlat } from '@/ui/Textarea/TextareaFlat';
import { cn } from '@/utils/cn';
import { maxLength } from '@/utils/format';
import { showAlert } from '@/utils/network';

import { Attach } from './Attach/Attach';
import cls from './Form.module.scss';
import { FormMessage } from './FormMessage/FormMessage';
import { ArrowIcon } from './icons';

export function handleInputText(
  e: React.FormEvent<HTMLTextAreaElement>
): string {
  return maxLength(e.currentTarget.value, MAX_CHAT_TEXT_LEN);
}

type Props = {
  detailID?: number;
};

export function Form({ detailID }: Props) {
  const { t } = useTranslation();

  const allowScrollAfter = useChatTextareaScrollHeight();

  const initialChatText = useChatUtilsStore((s) => s.initialChatText);
  const isSearchOpen = useChatDetailStore((s) => s.isSearchOpen);

  // Chat status
  const isDetailLoading = useChatDetailStore((s) => s.isDetailLoading);
  const detail = useChatDetailStore((s) => s.detail);
  const blockStatus = useChatDetailStore((s) => s.detail?.block_status);
  const updateBlockStatus = useChatDetailStore((s) => s.updateBlockStatus);

  const [isStatusLoading, setStatusLoading] = useState(false);
  const onStatusClick = (status: Nullable<ChatBlockStatusType>) => {
    return () => {
      if (!detail || status !== ChatBlockStatusType.do_unblock) return;
      const advertisement_id = detail.ad?.id;
      const companionId = detail.companion?.user_id;
      if (!advertisement_id || !companionId) return;

      const promise =
        status === ChatBlockStatusType.do_unblock
          ? unblockUserViaChat(companionId, { advertisement_id })
          : blockUserViaChat(companionId, { advertisement_id });

      setStatusLoading(true);
      promise
        .then((r) => {
          if (r.data) {
            updateBlockStatus(r.data);
          }
        })
        .catch((error) => showAlert({ error }))
        .finally(() => setStatusLoading(false));
    };
  };

  // Attached
  const attachedFiles = useChatDetailStore((s) => s.attachedFiles);
  const removeAttachedFile = useChatDetailStore((s) => s.removeAttachedFile);
  const isUploading = useChatDetailStore((s) => s.isUploading);

  // Reply
  const isMsgReplyOpen = useChatDetailStore((s) => s.isMsgReplyOpen);
  const replyingMsg = useChatDetailStore((s) => s.replyingMsg);
  const replyMessage = useChatDetailStore((s) => s.replyMessage);
  const closeMsgReply = useChatDetailStore((s) => s.closeMsgReply);
  const replyingAttachedFiles = useChatDetailStore(
    (s) => s.replyingAttachedFiles
  );
  const removeReplyingAttachedFile = useChatDetailStore(
    (s) => s.removeReplyingAttachedFile
  );
  const removeReplyingAttachedFiles = useChatDetailStore(
    (s) => s.removeReplyingAttachedFiles
  );
  const [replyingText, setReplyingText] = useState('');

  const onReplySubmitClick = () => {
    if (replyingMsg) {
      const trimmed = replyingText.trim();
      const content = [...replyingAttachedFiles];
      replyMessage(trimmed, content);
      setReplyingText('');
      removeReplyingAttachedFiles();
    }
  };

  // Edit
  const isMsgEditOpen = useChatDetailStore((s) => s.isMsgEditOpen);
  const editingMsg = useChatDetailStore((s) => s.editingMsg);
  const editTextMessage = useChatDetailStore((s) => s.editTextMessage);
  const closeMsgEdit = useChatDetailStore((s) => s.closeMsgEdit);
  const [editingText, setEditingText] = useState('');

  useEffect(() => {
    if (editingMsg && editingMsg.text) setEditingText(editingMsg.text);
  }, [editingMsg]);

  const onEditSubmitClick = () => {
    if (editingMsg) {
      const trimmed = editingText.trim();
      editTextMessage(editingMsg.id, trimmed);
      setEditingText('');
    }
  };

  // Primary
  const sendPrimaryMessage = useChatDetailStore((s) => s.sendPrimaryMessage);
  const removeAttachedFiles = useChatDetailStore((s) => s.removeAttachedFiles);
  const isAreaLoading = useChatDetailStore((s) => s.isAreaLoading);
  const canSendMsgs = useChatDetailStore((s) => s.canSendMsgs);

  const textRef = useRef<HTMLDivElement>(null);
  const [text, setText] = useState('');
  const onSubmitClick = () => {
    if (!detailID) return;

    const trimmed = text.trim();
    const content = [...attachedFiles];
    sendPrimaryMessage(detailID, trimmed || null, content);
    removeAttachedFiles();
    setText('');

    if (textRef && textRef.current) {
      const field = textRef.current.querySelector('textarea');
      if (field) field.focus();
    }
  };
  const isPrimaryTextActive = !isMsgEditOpen && !isMsgReplyOpen;

  const isTextValid = useMemo(() => {
    if (isMsgEditOpen) {
      return !!editingText;
    }

    if (isMsgReplyOpen) {
      return !!replyingText || replyingAttachedFiles.length > 0;
    }

    return !!text || attachedFiles.length > 0;
  }, [
    isMsgEditOpen,
    isMsgReplyOpen,
    text,
    attachedFiles.length,
    editingText,
    replyingText,
    replyingAttachedFiles.length
  ]);

  useEffect(() => {
    if (initialChatText) {
      setText(initialChatText);
    }
  }, [initialChatText]);

  const isSubmitDisabled =
    !canSendMsgs || isUploading || !isTextValid || isAreaLoading;

  const showPrimaryAttachedList =
    isPrimaryTextActive && attachedFiles.length > 0;
  const showReplyingAttachedList =
    isMsgReplyOpen && replyingAttachedFiles.length > 0;
  const isAttachedListShown =
    showPrimaryAttachedList || showReplyingAttachedList;

  const handleSubmitClick = () => {
    if (isMsgEditOpen) {
      onEditSubmitClick();
    } else if (isMsgReplyOpen) {
      onReplySubmitClick();
    } else {
      onSubmitClick();
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const isOnlyEnter = e.key === 'Enter' && !e.shiftKey;

    if (isOnlyEnter) {
      e.preventDefault();
      if (isSubmitDisabled) return;
      handleSubmitClick();
    }
  };

  // Paste files
  const addReplyingAttachedFiles = useChatDetailStore(
    (s) => s.addReplyingAttachedFiles
  );
  const addAttachedFiles = useChatDetailStore((s) => s.addAttachedFiles);
  const [fileLoading, setFileLoading] = useState(false);

  const onMediaUpload = (uploaded: UploadItem[]) => {
    const content = mapUploadItems(uploaded);
    if (isMsgReplyOpen) {
      addReplyingAttachedFiles(content);
    } else {
      addAttachedFiles(content);
    }
  };

  const uploadMedia = async (fileList: File[]) => {
    setFileLoading(true);

    const files = fileList.slice(0, MAX_MEDIA_CONTENT_LEN);
    if (!files || files.length <= 0) {
      setFileLoading(false);
      return;
    }

    try {
      const resolvedImgs: UploadItem[] = [];
      const resolvedVideos: UploadItem[] = [];
      const imgFiles: File[] = [];
      const videoFiles: File[] = [];

      files.forEach((fl) => {
        const isImg = fl.type.startsWith('image');
        if (isImg) {
          imgFiles.push(fl);
        } else {
          videoFiles.push(fl);
        }
      });

      const videoPromises = videoFiles.map((fl) => handleVideo(fl, true));
      const videoResults = await Promise.allSettled(videoPromises);
      videoResults.forEach((v) => {
        if (v.status == 'fulfilled') {
          resolvedVideos.push(v.value);
        }
      });

      const imgPromises = imgFiles.map((fl) => handleImage({ file: fl }));
      const imgResults = await Promise.allSettled(imgPromises);
      imgResults.forEach((v) => {
        if (v.status == 'fulfilled') {
          resolvedImgs.push(v.value);
        }
      });

      if (onMediaUpload) onMediaUpload([...resolvedVideos, ...resolvedImgs]);

      setFileLoading(false);
    } catch (error) {
      showAlert({ error });
      setFileLoading(false);
    }
  };

  const onTextareaPaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const { files } = e.clipboardData;
    const mediaFiles = Array.from(files).filter(
      (f) => f.type.startsWith('image') || f.type.startsWith('video')
    );

    if (mediaFiles && mediaFiles.length > 0) {
      e.preventDefault();
      uploadMedia(mediaFiles);
    }
  };

  const QuickReply = (
    <EmojiPicker
      onSelect={(v) =>
        replyingMsg ? setReplyingText(replyingMsg + v) : setText(text + v)
      }
    />
  );

  return (
    <div className={cls.root}>
      {blockStatus && blockStatus.title ? (
        <button
          className={cn(cls.status, {
            [cls.status_blue]:
              blockStatus.title === ChatBlockStatusType.do_unblock,
            [cls.status_red]:
              blockStatus.title === ChatBlockStatusType.blocked_by_companion
          })}
          type="button"
          onClick={onStatusClick(blockStatus.title)}
        >
          {isStatusLoading || isDetailLoading ? (
            <Spinner color="var(--clr-blue" size={22} />
          ) : (
            blockStatus.name
          )}
        </button>
      ) : (
        <>
          {!isSearchOpen && isMsgEditOpen && editingMsg && (
            <FormMessage
              message={editingMsg}
              onCloseClick={() => {
                closeMsgEdit();
                setEditingText('');
              }}
            />
          )}

          {!isSearchOpen && isMsgReplyOpen && replyingMsg && (
            <FormMessage
              message={replyingMsg}
              onCloseClick={() => {
                closeMsgReply();
                setReplyingText('');
              }}
              isReply
            />
          )}

          <div className={cls.row}>
            {detailID && <Attach detailID={detailID} loading={fileLoading} />}

            <div
              className={cn(cls.textarea, {
                [cls.textarea_attach]: isAttachedListShown
              })}
              ref={textRef}
            >
              {showPrimaryAttachedList && (
                <div className={cls.attached_list}>
                  <AttachedList
                    list={attachedFiles}
                    onRemoveClick={removeAttachedFile}
                  />
                </div>
              )}

              {showReplyingAttachedList && (
                <div className={cls.attached_list}>
                  <AttachedList
                    list={replyingAttachedFiles}
                    onRemoveClick={removeReplyingAttachedFile}
                  />
                </div>
              )}

              {isMsgEditOpen && (
                <TextareaFlat
                  autoFocus
                  value={canSendMsgs ? editingText : ''}
                  onChange={(e) => setEditingText(handleInputText(e))}
                  placeholder={
                    canSendMsgs
                      ? t('common.edit')
                      : t('chat.messages.unavailable')
                  }
                  disabled={!canSendMsgs}
                  minRows={1}
                  onKeyDown={onKeyDown}
                />
              )}

              {isMsgReplyOpen && (
                <TextareaFlat
                  autoFocus
                  value={canSendMsgs ? replyingText : ''}
                  onChange={(e) => setReplyingText(handleInputText(e))}
                  placeholder={
                    canSendMsgs
                      ? t('chat.messages.reply')
                      : t('chat.messages.unavailable')
                  }
                  disabled={!canSendMsgs}
                  minRows={1}
                  onKeyDown={onKeyDown}
                  onPaste={onTextareaPaste}
                  suffix={QuickReply}
                  allowScrollAfter={allowScrollAfter}
                />
              )}

              {isPrimaryTextActive && (
                <TextareaFlat
                  autoFocus
                  value={canSendMsgs ? text : ''}
                  onChange={(e) => setText(handleInputText(e))}
                  placeholder={
                    canSendMsgs
                      ? t('chat.messages.placeholder')
                      : t('chat.messages.unavailable')
                  }
                  disabled={!canSendMsgs}
                  minRows={1}
                  onKeyDown={onKeyDown}
                  onPaste={onTextareaPaste}
                  suffix={QuickReply}
                  allowScrollAfter={allowScrollAfter}
                />
              )}
            </div>

            <button
              className={cls.send_btn}
              type="button"
              disabled={isSubmitDisabled}
              onClick={handleSubmitClick}
            >
              <ArrowIcon />
            </button>
          </div>
        </>
      )}
    </div>
  );
}
