import { useState, useCallback, useEffect, useMemo } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import {
  acceptImages,
  getImageUrlFromFile,
  handleImage,
  UploadItem
} from '@/modules/files/upload/helpers';
import { Props as UploadProps } from '@/modules/files/upload/UploadFiles';
import { Cropper } from '@/ui/Cropper/Cropper';
import { Spinner } from '@/ui/Spinner/Spinner';
import { cn } from '@/utils/cn';
import { mbToBytes } from '@/utils/format';
import { showAlert } from '@/utils/network';

import { CameraIcon, PictureIcon } from './icons';
import cls from './UploadImages.module.scss';

interface Props extends Pick<UploadProps, 'onUpload' | 'maxFilesLen'> {
  onLocalUpload?: (v: File[]) => void;
  square?: boolean;
  multiple?: boolean;
  maxFileSizeMb?: number;
  maxFileLenSlice?: number;
  secondaryStyle?: boolean;
  crop?: boolean;
  maxCropSize?: number;
  aspect?: number;
  emitOpenFn?: (openFn: () => void) => void;
  uploadBtn?: React.ReactNode;
  cropperModalHeader?: React.ReactNode;
  cropperCancelText?: string;
  cropperConfirmText?: string;
  onCropCancel?: () => void;
  onCropChange?: (croppedImgUrl: string) => void;
  resetKey?: number;
  accept?: Record<string, string[]>;
  handleImageUploaded?: () => void;
}

export const UploadImages: React.FC<Props> = ({
  onUpload,
  onLocalUpload,
  square,
  multiple = true,
  maxFileSizeMb,
  maxFilesLen,
  secondaryStyle,
  crop,
  maxCropSize,
  aspect,
  emitOpenFn,
  uploadBtn,
  cropperModalHeader,
  cropperCancelText,
  cropperConfirmText,
  onCropCancel,
  onCropChange,
  resetKey,
  accept: acceptProp,
  handleImageUploaded
}) => {
  const { t } = useTranslation();

  const [isUploading, setUploading] = useState(false);
  const [isCropOpen, setCropOpen] = useState(false);
  const [cropUrl, setCropUrl] = useState('');

  useEffect(() => {
    if (resetKey) {
      setUploading(false);
      setCropOpen(false);
      setCropUrl('');
    }
  }, [resetKey]);

  const onCrop = async (blob: Nullable<Blob>) => {
    if (blob) {
      setUploading(true);

      try {
        const file = new File([blob], 'cropped-uploaded', {
          type: blob.type
        });

        const response = await handleImage({
          file
        });
        if (onUpload) onUpload([response]);
        setCropOpen(false);
      } catch (error) {
        showAlert({ error });
        setCropOpen(false);
        setCropUrl('');
      } finally {
        setUploading(false);
        handleImageUploaded?.();
      }
    } else {
      setCropOpen(false);
      setCropUrl('');
      setUploading(false);
      close();
    }
  };

  const handleFiles = useCallback(
    async (filesProp: File[]) => {
      if (isUploading) return;
      setUploading(true);

      const files = filesProp;
      const f = files ? Array.from(files) : null;

      if (!f || f.length <= 0) {
        setUploading(false);
        return;
      }

      try {
        if (onLocalUpload) {
          onLocalUpload(f);
          setUploading(false);
          return;
        }

        if (crop && !multiple && files[0]) {
          setUploading(false);
          const url = await getImageUrlFromFile(files[0]);

          if (typeof url === 'string') {
            setCropUrl(url);
            setCropOpen(true);
          }
        } else {
          const promises = Array.from(f).map((fl) =>
            handleImage({
              file: fl
            })
          );
          const results = await Promise.allSettled(promises);
          const resolved: UploadItem[] = [];
          results.forEach((v) => {
            if (v.status == 'fulfilled') {
              resolved.push(v.value);
            }
          });
          if (onUpload) onUpload(resolved);
          setUploading(false);
        }
      } catch (error) {
        showAlert({ error });
        setUploading(false);
      } finally {
        handleImageUploaded?.();
      }
    },
    [isUploading, handleImageUploaded, onLocalUpload, crop, multiple, onUpload]
  );

  // Dropzone
  const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
    useDropzone({
      maxSize: maxFileSizeMb ? mbToBytes(maxFileSizeMb) : undefined,
      maxFiles: maxFilesLen,
      multiple,
      noClick: true,
      noKeyboard: true,
      onDrop: handleFiles,
      accept: acceptProp || acceptImages
    });

  const acceptFileTypes = useMemo(() => {
    const acceptTypes = acceptProp || acceptImages;
    const mimoTypes = Object.keys(acceptTypes)
      .map((type) => {
        const slashIndex = type.indexOf('/');
        if (slashIndex === -1) return type;

        return type.substring(slashIndex + 1, type.length);
      })
      .join(t('common.listSeparator') || '');

    return mimoTypes;
  }, [acceptProp, t]);

  const showFileRejectError = (fileRejection: FileRejection) => {
    const errorCode = fileRejection.errors[0].code as ErrorCode;

    switch (errorCode) {
      case ErrorCode.FileInvalidType:
        showAlert({
          type: 'error',
          text: t('upload.image.invalidType', { acceptFileTypes })
        });
        break;
      case ErrorCode.FileTooLarge:
        showAlert({
          type: 'error',
          text: t('fileTooLarge', { maxFileSize: maxFileSizeMb })
        });
        break;
      case ErrorCode.TooManyFiles:
        showAlert({
          type: 'error',
          text: t('upload.image.maxLength', { maxLength: maxFilesLen })
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (fileRejections.length === 0) return;

    showFileRejectError(fileRejections[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections]);

  useEffect(() => {
    if (emitOpenFn) {
      emitOpenFn(open);
    }
  }, [emitOpenFn, open]);

  return (
    <div
      className={cn(cls.root, {
        [cls.root_square]: square,
        [cls.root_drag]: isDragActive,
        [cls.root_secondary]: secondaryStyle
      })}
      {...getRootProps()}
    >
      {uploadBtn ? (
        <>{uploadBtn}</>
      ) : (
        <button
          className={cls.upload_btn}
          type="button"
          onClick={open}
          disabled={isUploading}
        >
          <span className={cls.upload_btn_inner}>
            {isUploading && !onLocalUpload ? (
              <Spinner color="var(--clr-blue)" size={square ? 36 : 60} />
            ) : (
              <>
                <span className={cls.icon}>
                  {square ? <CameraIcon /> : <PictureIcon />}
                </span>
                {!secondaryStyle && (
                  <span>
                    {t(square ? 'upload.image.add' : 'upload.image.label')}
                  </span>
                )}
              </>
            )}
          </span>
        </button>
      )}

      <input {...getInputProps()} />

      <Cropper
        name="upload-images-cropper"
        isOpen={isCropOpen}
        close={() => setCropOpen(false)}
        cropperData={{
          src: cropUrl,
          maxWidth: maxCropSize,
          maxHeight: maxCropSize,
          loading: isUploading,
          aspect
        }}
        onCrop={onCrop}
        modalHeader={cropperModalHeader}
        cancelText={cropperCancelText}
        confirmText={cropperConfirmText}
        onCancelClick={onCropCancel}
        onCropChange={onCropChange}
      />
    </div>
  );
};
