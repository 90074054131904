import imageCompression from 'browser-image-compression';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { Spinner } from '@/ui/Spinner/Spinner';
import { cn } from '@/utils/cn';
import { mbToBytes } from '@/utils/format';
import { showAlert } from '@/utils/network';

import { VideoIcon } from './icons';
import cls from './UploadVideo.module.scss';

import { uploadFile } from '../../api';
import { UploadRes } from '../../types';
import {
  acceptVideos,
  compressOptions,
  getImageFromVideo,
  handleUploadResponse,
  MAX_THUMB_SIZE,
  ThumbnailType
} from '../helpers';

interface Props {
  onUpload: (
    r: UploadRes,
    f: File,
    thumbnail: Nullable<ThumbnailType>,
    poster: Nullable<ThumbnailType>
  ) => void;
  onUploading?: (uploading: boolean) => void;
  maxFileSizeMb?: number;
  accept?: Record<string, string[]>;
}

export const UploadVideo: React.FC<Props> = ({
  onUpload,
  onUploading,
  maxFileSizeMb,
  accept: acceptProp
}) => {
  const { t } = useTranslation();

  const [isUploading, setUploading] = useState(false);
  useEffect(() => {
    onUploading?.(isUploading);
  }, [onUploading, isUploading]);

  const handleFiles = useCallback(
    async (filesProp: File[]) => {
      if (isUploading) return;
      setUploading(true);

      const files = filesProp;
      const f = files ? Array.from(files)[0] : null;

      if (!f) {
        setUploading(false);
        return;
      }

      try {
        let poster = null;
        const posterFile = await getImageFromVideo(f);

        if (posterFile) {
          const compressedPoster = await imageCompression(posterFile, {
            ...compressOptions,
            maxWidthOrHeight: MAX_THUMB_SIZE
          });
          const posterResponse = await uploadFile({ file: compressedPoster });
          poster = { r: posterResponse.data, f: compressedPoster };
        }

        // Video
        const { data } = await uploadFile({ file: f }, f.type);
        onUpload({ ...handleUploadResponse(data) }, f, null, poster);
      } catch (error) {
        showAlert({ error });
      } finally {
        setUploading(false);
      }
    },
    [isUploading, onUpload]
  );

  // Dropzone
  const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
    useDropzone({
      maxSize: maxFileSizeMb ? mbToBytes(maxFileSizeMb) : undefined,
      maxFiles: 1,
      multiple: false,
      noClick: true,
      noKeyboard: true,
      onDrop: handleFiles,
      accept: acceptProp || acceptVideos
    });

  const acceptFileTypes = useMemo(() => {
    const acceptTypes = acceptProp || acceptVideos;
    const mimoTypes = Object.keys(acceptTypes)
      .map((type) => {
        const slashIndex = type.indexOf('/');
        if (slashIndex === -1) return type;

        return type.substring(slashIndex + 1, type.length);
      })
      .join(t('common.listSeparator') || '');

    return mimoTypes;
  }, [acceptProp, t]);

  const showFileRejectError = (fileRejection: FileRejection) => {
    const errorCode = fileRejection.errors[0].code as ErrorCode;

    switch (errorCode) {
      case ErrorCode.FileInvalidType:
        showAlert({
          type: 'error',
          text: t('upload.image.invalidType', { acceptFileTypes })
        });
        break;
      case ErrorCode.FileTooLarge:
        showAlert({
          type: 'error',
          text: t('fileTooLarge', { maxFileSize: maxFileSizeMb })
        });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (fileRejections.length === 0) return;

    showFileRejectError(fileRejections[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections]);

  return (
    <div
      className={cn(cls.root, {
        [cls.root_drag]: isDragActive
      })}
      {...getRootProps()}
    >
      <button
        className={cls.upload_btn}
        type="button"
        onClick={open}
        disabled={isUploading}
      >
        <span className={cls.upload_btn_inner}>
          {isUploading ? (
            <Spinner color="var(--clr-blue)" size={60} />
          ) : (
            <span className={cls.icon}>{<VideoIcon />}</span>
          )}
        </span>
        <p>
          <Trans
            t={t}
            i18nKey={
              isUploading ? 'upload.video.uploading' : 'upload.video.select'
            }
          />
        </p>
      </button>

      <input {...getInputProps()} />
    </div>
  );
};
