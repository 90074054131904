import Tooltip from 'rc-tooltip';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, OptionI } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import { useCompetitors } from '@/modules/competitors/api';
import { CompetitorType } from '@/modules/competitors/types';
import { removeFromSale } from '@/modules/showroom/advert/my/api';
import { useReasonsOfRemove } from '@/modules/showroom/advert/my/hooks';
import { MyAdvert, ReasonOfRemove } from '@/modules/showroom/advert/my/types';
import { PromoList } from '@/modules/showroom/advert/my/UnpublishAdvert/PromoList/PromoList';
import { deleteAdvertisement } from '@/modules/showroom/advert/update/api';
import { Button } from '@/ui/Button/Button';
import { Checkbox } from '@/ui/Checkbox/Checkbox';
import { DateTimePicker } from '@/ui/DateTimePicker/DateTimePicker';
import { Input } from '@/ui/Input/Input';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { CommonModalProps } from '@/ui/modals/CommonModal/CommonModal';
import { Select } from '@/ui/Select/Select';
import { Skeleton } from '@/ui/Skeleton';
import { Textarea } from '@/ui/Textarea/Textarea';
import { cn } from '@/utils/cn';
import { ADV_CUR, MAX_PG_LIMIT, rcTooltipProps } from '@/utils/consts';
import { maxLength, numberWithSpaces, onlyNumbers } from '@/utils/format';
import { showAlert } from '@/utils/network';

import { ArrowIcon, BackIcon, CloseIcon, InfoIcon } from './icons';
import cls from './UnpublishAdvert.module.scss';

const SOLD_DEALER_REASON_ID = 2;
const COMPET_REASON_ID = 3;
const LATER_REASON_ID = 6;
const OTHER_REASON_ID = 7;
const FEW_BUYERS_REASON_ID = 5;
const CHANGE_MIND_REASON_ID = 4;
const SHOW_PRICE_REASON_IDS = [1, COMPET_REASON_ID];
const MAX_TEXT_LEN = 1000;
const req = { limit: MAX_PG_LIMIT };
const REASON_TEXT_MIN_ROWS = 4;

const skeletons = new Array(6).fill(0);
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
tomorrow.setHours(0, 0, 0, 0);

interface Props extends CommonModalProps {
  id: number;
  onSubmit: () => void;
  advert: MyAdvert;
}

export const UnpublishAdvert: React.FC<Props> = ({
  id,
  onSubmit,
  close,
  rootClass,
  backdropClass,
  containerClass,
  advert,
  ...props
}) => {
  const { t } = useTranslation();
  const [locale] = useLang();

  const { price: advertPrice, promotion_package: promotionPackage } = advert;

  const [needToDelete, setNeedToDelete] = useState(false);
  const handleClose = () => {
    close();
  };

  // Reason
  const [reason, setReason] = useState<Nullable<ReasonOfRemove>>(null);
  const [reasons, reasonsLoading] = useReasonsOfRemove(req);
  const reasonsOptions = useMemo<OptionI[]>(() => {
    return reasons
      ? reasons
          .filter((r) => r.id !== SOLD_DEALER_REASON_ID)
          .map((r) => ({
            id: r.id.toString(),
            text: r.name
          }))
      : [];
  }, [reasons]);

  const onReasonChange = (opt: OptionI) => {
    const rId = Number(opt.id);
    const r = reasons?.find((r) => r.id === rId) || null;
    setReason(r);
  };

  // Price
  const [price, setPrice] = useState<string>(
    advertPrice ? String(advertPrice) : ''
  );
  const showPrice = reason ? SHOW_PRICE_REASON_IDS.includes(reason.id) : false;
  useEffect(() => {
    if (!showPrice) {
      setPrice(advertPrice ? String(advertPrice) : '');
    }
  }, [advertPrice, showPrice]);

  // Reason text / Other reason
  const [reasonText, setReasonText] = useState<string>('');
  const showTextarea = reason?.id === OTHER_REASON_ID;
  const isReasonTextValid = showTextarea ? !!reasonText : true;

  // Competitor
  const [competitor, setCompetitor] = useState<CompetitorType>();
  const [competitors, competitorsLoading] = useCompetitors(req);
  const competitorsOptions = useMemo<OptionI[]>(() => {
    return competitors
      ? competitors.map((r) => ({
          id: r.id.toString(),
          text: r.name
        }))
      : [];
  }, [competitors]);
  const competitorOption = competitor
    ? competitorsOptions.find((opt) => opt.id === String(competitor.id))
    : undefined;
  const onCompetitorChange = (opt: OptionI) => {
    const rId = Number(opt.id);
    const r = competitors?.find((r) => r.id === rId);
    setCompetitor(r);
  };
  const showCompet = reason?.id === COMPET_REASON_ID;
  const isCompetValid = showCompet ? !!competitor : true;

  // Date
  const [date, setDate] = useState<Date>();

  const showDate = reason?.id === LATER_REASON_ID;
  const isDateValid = showDate ? !!date : true;

  // Submit
  const canSubmit =
    !!reason && isCompetValid && isDateValid && isReasonTextValid;
  const [isLoading, setLoading] = useState(false);
  const submit = async () => {
    const reasonId = reason?.id;
    if (!reasonId || isLoading) return;

    setLoading(true);

    try {
      await removeFromSale(id, {
        price: advertPrice || null,
        reason_of_removing_id: reasonId,
        competing_company_id: competitor?.id || null,
        custom_reason: reasonText,
        will_be_published_at: date ? date.getTime() / 1000 : null
      });

      if (needToDelete) await deleteAdvertisement(advert.id);

      showAlert({
        type: 'success',
        text: needToDelete ? t('publish.deleted') : t('publish.unpublished')
      });
      onSubmit();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  const onSubmitClick = () => {
    submit();
  };

  const deleteWithoutReason = async () => {
    setLoading(true);

    try {
      await deleteAdvertisement(advert.id);

      showAlert({
        type: 'success',
        text: t('publish.deleted')
      });
      onSubmit();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  // Promo
  const [promoActivateOpen, setPromoActivateOpen] = useState(false);
  const showPromoList = reason?.id === FEW_BUYERS_REASON_ID;

  const showWarningMessage = reason?.id === CHANGE_MIND_REASON_ID;

  return (
    <AdaptiveModal
      {...props}
      close={handleClose}
      rootClass={cn(rootClass, cls.modal_root, cls.modal_root_imp)}
      backdropClass={cn(
        backdropClass,
        cls.modal_backdrop,
        cls.modal_backdrop_imp
      )}
      containerClass={cn(
        containerClass,
        cls.modal_container,
        cls.modal_container_imp,
        {
          [cls.hidden]: promoActivateOpen
        }
      )}
    >
      <div className={cls.root}>
        <div className={cls.header}>
          <div className={cls.title_wrap}>
            {reason && (
              <>
                <button onClick={() => setReason(null)}>
                  <BackIcon />
                </button>
                <h2 className={cls.title}>{reason.name}</h2>
              </>
            )}

            {!reason && (
              <h2 className={cls.title}>
                {t('advertisements.my.removeFromSell')}
              </h2>
            )}
          </div>

          <button className={cls.close_btn} type="button" onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>

        {!reason && (
          <>
            <p className={cls.subtitle}>{t('unpublish.subtitle')}</p>
            {!reasonsLoading && reasonsOptions.length > 0 && (
              <ul className={cls.list}>
                {reasonsOptions.map((reason) => {
                  return (
                    <li key={reason.id}>
                      <button onClick={() => onReasonChange(reason)}>
                        {reason.text}
                        <ArrowIcon />
                      </button>
                    </li>
                  );
                })}
              </ul>
            )}

            {reasonsLoading && (
              <ul className={cls.list}>
                {skeletons.map((_, i) => {
                  return (
                    <li key={i}>
                      <Skeleton width="100%" height={56} borderRadius={16} />
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        )}

        {showPrice && (
          <div className={cls.field}>
            <Input
              value={price ? numberWithSpaces(Number(price), locale) : ''}
              label={t('advertisements.my.removePrice', {
                currency: ADV_CUR
              })}
              onChange={(e) => setPrice(onlyNumbers(e.currentTarget.value))}
              inputMode="numeric"
              bottomLabel={t('unpublish.helpUs')}
            />
          </div>
        )}

        {showCompet && (
          <div className={cls.field}>
            <Select
              name="unpublish-advert-competitor"
              value={competitorOption}
              options={competitorsOptions}
              disabled={competitorsLoading}
              onChange={onCompetitorChange}
              label={t('unpublish.where')}
              dropdownCls={cls.dropdown}
            />
          </div>
        )}

        {showDate && (
          <>
            <h2 className={cls.subtitle}>{t('publish.when')}</h2>
            <p className={cls.text}>{t('unpublish.later')}</p>
            <div className={cls.date}>
              <DateTimePicker
                label={t('publish.date')}
                date={date}
                onChange={(d) => {
                  if (d) setDate(d);
                }}
                minDate={tomorrow}
                showTimeInput
              />
            </div>
          </>
        )}

        {showTextarea && (
          <div className={cls.field}>
            <Textarea
              value={reasonText}
              onChange={(e) =>
                setReasonText(maxLength(e.currentTarget.value, MAX_TEXT_LEN))
              }
              minRows={REASON_TEXT_MIN_ROWS}
              placeholder={t('describeReason')}
            />
          </div>
        )}

        {showPromoList && (
          <div className={cls.field}>
            <PromoList
              advertId={advert.id}
              promotionPackage={promotionPackage}
              setPromoActivateOpen={setPromoActivateOpen}
              close={close}
            />
          </div>
        )}

        {showWarningMessage && (
          <div className={cls.field}>
            <p className={cls.warn_message}>{t('unpublish.warningMessage')}</p>
          </div>
        )}

        {reason && (
          <>
            <div className={cls.action}>
              <Button
                color={needToDelete ? 'red' : 'blue'}
                fullWidth
                onClick={onSubmitClick}
                disabled={!canSubmit}
                loading={isLoading}
              >
                {needToDelete
                  ? t('unpublish.unpublishAndDelete')
                  : t('unpublish.unpublish')}
              </Button>
            </div>

            <div className={cls.delete_wrap}>
              <div className={cls.delete_check}>
                <Checkbox
                  id={`need-to-delete`}
                  checked={needToDelete}
                  onChange={() => null}
                />
                <span>{t('unpublish.deleteAd')}</span>

                <button
                  type="button"
                  onClick={() => setNeedToDelete(!needToDelete)}
                />
              </div>

              <Tooltip
                placement="top"
                overlay={t('unpublish.deleteInfo')}
                overlayStyle={{ width: '260px' }}
                overlayInnerStyle={{ textAlign: 'center' }}
                {...rcTooltipProps}
              >
                <button type="button">
                  <InfoIcon />
                </button>
              </Tooltip>
            </div>
          </>
        )}

        {!reason && (
          <div className={cls.action}>
            <Button
              color="red"
              variant="secondary"
              fullWidth
              onClick={deleteWithoutReason}
              loading={isLoading}
            >
              {t('unpublish.deleteWithoutReason')}
            </Button>
          </div>
        )}
      </div>
    </AdaptiveModal>
  );
};
