import { nanoid } from 'nanoid';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, GroupedOptionI, OptionI } from '@/app/types';
import { Dropdown, DropdownItem } from '@/ui/Dropdown/Dropdown';
import { Input } from '@/ui/Input/Input';
import { cn } from '@/utils/cn';

import { ListIcon, RemoveIcon } from './icons';
import cls from './PrimarySelect.module.scss';

function isOptionActive(option: OptionI, value: OptionI[]): boolean {
  return value.map((v) => v.id).includes(option.id);
}

const EXTRA_PARAM = 'groupTitle';

type Props = {
  name: string;
  value?: OptionI[];
  groupedOptions: GroupedOptionI[];
  onChange?: (option: OptionI[]) => void;
  onOptionRemove?: (option: OptionI) => void;
  onOptionAdd?: (option: OptionI, removeOtherOptions?: boolean) => void;
  multiple?: boolean;
  dropdownTop?: boolean;
  dropdownFullWidth?: boolean;
  dropdownDesktop?: boolean;
  clearText?: Nullable<string>;
  isOpen: boolean;
  setOpen: (v: boolean) => void;
  onOpen?: () => void;
  onClearClick: () => void;
  showSearch?: boolean;
  searchFilterFn?: (searchText: string, id: OptionI['id']) => void;
  firstDropdownItem?: React.ReactNode;
  showScrollbar?: boolean;
  loading?: boolean;
};

export const PrimaryGroupedSelectDropdown: React.FC<Props> = ({
  name,
  value,
  groupedOptions,
  dropdownTop,
  dropdownFullWidth,
  dropdownDesktop,
  clearText,
  isOpen,
  setOpen,
  onOpen,
  onChange,
  onOptionRemove,
  onOptionAdd,
  multiple,
  onClearClick,
  showSearch,
  searchFilterFn,
  firstDropdownItem,
  showScrollbar,
  loading
}) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const searchLow = search.toLowerCase().trim();
  const mergedOptions = useMemo(() => {
    return groupedOptions.reduce((result, obj) => {
      result.push({
        id: nanoid(),
        text: obj.title,
        extra: EXTRA_PARAM
      });
      return result.concat(obj.options);
    }, [] as OptionI[]);
  }, [groupedOptions]);

  const uniqueOptions = useMemo(() => {
    const result: OptionI[] = [];
    const hashMap = new Map();
    mergedOptions.forEach((option) => {
      if (!option.extra || option.extra !== EXTRA_PARAM) {
        hashMap.set(option.id, option.text);
      }
    });

    hashMap.forEach((value, key) => {
      result.push({
        id: key,
        text: value
      });
    });

    return result;
  }, [mergedOptions]);

  const filteredOptions = useMemo(() => {
    if (showSearch && searchLow) {
      return uniqueOptions.filter((o) => {
        if (searchFilterFn) {
          return searchFilterFn(searchLow, o.id);
        }
        return o.text.toLowerCase().includes(searchLow);
      });
    }

    return mergedOptions;
  }, [showSearch, searchLow, mergedOptions, uniqueOptions, searchFilterFn]);

  const hasValue = !!value && value.length > 0;

  const onOptionClick = (option: OptionI) => {
    return () => {
      const prevState = value || [];
      const prevIds = prevState.map((v) => v.id);
      const hasThisOption = prevIds.includes(option.id);

      if (onChange) {
        if (hasThisOption) {
          onChange(prevState.filter((v) => v.id !== option.id));
          if (!multiple) setOpen(false);
        } else {
          const merged = [...prevState, option];

          if (multiple) {
            onChange(merged);
          } else {
            onChange([option]);
            setOpen(false);
          }
        }

        return;
      }

      if (multiple) {
        if (onOptionRemove && hasThisOption) {
          onOptionRemove(option);
          return;
        }

        if (onOptionAdd && !hasThisOption) {
          onOptionAdd(option);
          return;
        }
      } else {
        if (hasThisOption) {
          if (onOptionRemove) {
            onOptionRemove(option);
            setOpen(false);
          }
        } else {
          if (onOptionAdd) {
            onOptionAdd(option, true);
            setOpen(false);
          }
        }
      }
    };
  };

  return (
    <Dropdown
      name={name}
      isOpen={isOpen}
      close={() => setOpen(false)}
      className={cls.dropdown}
      modalClassName={cn({ [cls.dropdown_modal]: showSearch })}
      listTop={dropdownTop}
      fullWidth={dropdownFullWidth}
      isDesktop={dropdownDesktop}
      showScrollbar={showScrollbar}
      withModal
    >
      {firstDropdownItem && (
        <DropdownItem className={cls.li}>
          <div>{firstDropdownItem}</div>
        </DropdownItem>
      )}

      {showSearch && (
        <DropdownItem className={cls.li}>
          <div>
            <Input
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
              placeholder={t('common.search')}
              onClear={() => setSearch('')}
              onFocus={() => {
                if (onOpen) onOpen();
              }}
              small
              disabled={loading}
            />
          </div>
        </DropdownItem>
      )}

      {isOpen && clearText && (
        <DropdownItem className={cls.seperated_li}>
          <button className={cls.list_btn} type="button" onClick={onClearClick}>
            <span className={cls.clear_icon}>
              <RemoveIcon />
            </span>
            <span>{clearText}</span>
          </button>
        </DropdownItem>
      )}

      {isOpen &&
        filteredOptions.map((option, i) => {
          const isActive = hasValue && isOptionActive(option, value);
          const isGroupTitle = option.extra && option.extra === EXTRA_PARAM;

          return (
            <DropdownItem key={i} active={isActive}>
              <button
                className={cn({
                  [cls.list_btn]: !isGroupTitle,
                  [cls.group_title]: isGroupTitle
                })}
                type="button"
                onClick={!isGroupTitle ? onOptionClick(option) : () => null}
              >
                {!isGroupTitle && (
                  <span className={cls.list_icon}>
                    {isActive && <ListIcon />}
                  </span>
                )}

                <span>{option.text}</span>
              </button>
            </DropdownItem>
          );
        })}
    </Dropdown>
  );
};
