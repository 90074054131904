import qs from 'qs';
import Tooltip from 'rc-tooltip';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { useOnceTrue } from '@/hooks/useOnceTrue';
import i18n, { LANGS_MAP } from '@/modules/i18n';
import { usePermissionAccess } from '@/modules/roles/helpers';
import { useAdvertName } from '@/modules/showroom/advert/hooks';
import { CreateAdvertCopy } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Actions/CreateAdvertCopy';
import { useCreateAdvertCopy } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Actions/helpers';
import { useMyAdvertActions } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/helpers';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import { UnpublishAdvert } from '@/modules/showroom/advert/my/UnpublishAdvert/UnpublishAdvert';
import { CARS_CATEGORY_ID } from '@/modules/showroom/advert/search/consts';
import {
  defaultSearchReq,
  stringifySearchReq
} from '@/modules/showroom/advert/search/helpers';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { VRP_CATEGORY_ID } from '@/modules/showroom/advert/vrp/helpers';
import { Dropdown, DropdownItem } from '@/ui/Dropdown/Dropdown';
import { ConfirmModal } from '@/ui/modals/ConfirmModal/ConfirmModal';
import { ShareModal } from '@/ui/ShareModal/ShareModal';
import { Spinner } from '@/ui/Spinner/Spinner';
import { cn } from '@/utils/cn';
import { openInNewTab } from '@/utils/common';
import { ADV_CUR, APP_URL, rcTooltipProps } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';

import cls from './Actions.module.scss';
import {
  QrIcon,
  EditIcon,
  PauseIcon,
  MoreIcon,
  EyeIcon,
  DeleteIcon,
  SimilarIcon,
  CopyIcon,
  ShareIcon
} from './icons';

const englishT = i18n.getFixedT(LANGS_MAP.en);

type Props = {
  advert: MyAdvert;
  onPublishToggle?: () => void;
  dropdownTop?: boolean;
  mobile?: boolean;
};

export function Actions({
  advert,
  onPublishToggle,
  dropdownTop,
  mobile
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const [isMoreOpen, setMoreOpen] = useState(false);

  // Info
  const adWithoutYear = useMemo(
    () => ({ ...advert, year_of_issue: null }),
    [advert]
  );
  const name = useAdvertName(adWithoutYear);

  // Flags
  const isVrp = advert.category.id === VRP_CATEGORY_ID;
  const isPublished = advert.status.title === AdvertStatusType.published;
  const isRemoved = advert.status.title === AdvertStatusType.removed;
  const isDraft = advert.status.title === AdvertStatusType.draft;
  const isSold = advert.status.title === AdvertStatusType.sold;
  const isUnpaid = advert.status.title === AdvertStatusType.unpaid;

  const permissions = usePermissionAccess();
  const canControl = isVrp
    ? permissions.plateSale.control
    : permissions.carSale.control;

  const canDelete = canControl && !isPublished;
  const canCreateCopy =
    advert.category.id === CARS_CATEGORY_ID &&
    !isDraft &&
    !isPublished &&
    !isUnpaid;

  // Actions hook
  const fetchData = useCallback(() => {
    if (onPublishToggle) onPublishToggle();
  }, [onPublishToggle]);
  const params = useMemo(() => ({ advert, fetchData }), [advert, fetchData]);
  const actionsData = useMyAdvertActions(params);
  const {
    isLoading: loading,
    isUnpublishOpen,
    onPublishClick,
    onUnpublishClick,
    closeUnpublish,
    onUnpublishSubmit,
    onDeleteClick,
    onDeleteSubmit,
    isDeleteOpen,
    setDeleteOpen,
    onEditClick
  } = actionsData;

  const pdfUrl = useMemo(() => {
    const params = {
      adName: name,
      price: englishT(
        'priceCurrency',

        {
          price: numberWithSpaces(advert.price || 0, lang),
          currency: ADV_CUR
        }
      ),
      yearMileage: englishT(
        'common.slashSeparated',

        {
          first: advert.year_of_issue?.name || '',
          second: englishT('mileageKm', {
            mileage: numberWithSpaces(advert.mileage || 0, lang)
          })
        }
      ),
      engine: advert.modification?.engine?.name || '',
      driveTrain: advert.modification?.drive_unit?.name || '',
      transmission: advert.modification?.transmission?.name || '',
      qr: `${APP_URL}/${advert.url}`
    };

    const query = qs.stringify(params);
    return `${APP_URL}/app/api/dealers-ad-pdf/?${query}`;
  }, [advert, name, lang]);

  // Copy
  const [onCreateAdvertCopy, creatingCopy] = useCreateAdvertCopy(advert);

  // Similar
  const onSimilarClick = () => {
    setMoreOpen(false);

    const { brand, model, generation } = advert;
    if (!brand || !model) return;

    const url = stringifySearchReq({
      ...defaultSearchReq,
      auto_filter: [
        {
          brand_id: brand.id,
          model_id: model.id,
          generation_id: generation?.id || null
        }
      ]
    });
    openInNewTab(`${APP_URL}/sale/search?${url}&results=true`);
  };

  const [isShareOpen, setShareOpen] = useState(false);
  const shareOpenedOnce = useOnceTrue(isShareOpen);
  const onShareClick = () => {
    setShareOpen(true);
  };

  return (
    <>
      <ul className={cls.root}>
        {!mobile && (
          <li>
            <Tooltip
              placement="left"
              overlay={t('common.share')}
              {...rcTooltipProps}
            >
              <button
                className={cls.action_btn}
                type="button"
                onClick={onShareClick}
              >
                <ShareIcon />
              </button>
            </Tooltip>
          </li>
        )}

        {canControl && !isUnpaid && (
          <li>
            <Tooltip
              placement="left"
              overlay={t('common.edit')}
              {...rcTooltipProps}
            >
              <button
                className={cls.action_btn}
                type="button"
                onClick={onEditClick}
              >
                <EditIcon />
              </button>
            </Tooltip>
          </li>
        )}

        {canCreateCopy && !mobile && (
          <li>
            <Tooltip
              placement="left"
              overlay={t('advertisements.createCopy')}
              {...rcTooltipProps}
            >
              <button
                className={cls.action_btn}
                type="button"
                onClick={onCreateAdvertCopy}
                disabled={creatingCopy}
              >
                {creatingCopy ? (
                  <Spinner color="var(--clr-blue)" size={20} />
                ) : (
                  <CopyIcon />
                )}
              </button>
            </Tooltip>
          </li>
        )}

        {isSold && !mobile && (
          <li>
            <Tooltip
              placement="left"
              overlay={t('similar')}
              {...rcTooltipProps}
            >
              <button
                className={cls.action_btn}
                type="button"
                onClick={onSimilarClick}
              >
                <SimilarIcon />
              </button>
            </Tooltip>
          </li>
        )}

        {canControl && isPublished && !mobile && (
          <li>
            <Tooltip
              placement="left"
              overlay={t('unpublish.do')}
              {...rcTooltipProps}
            >
              <button
                className={cls.action_btn}
                type="button"
                onClick={onUnpublishClick}
                disabled={loading}
              >
                <PauseIcon />
              </button>
            </Tooltip>
          </li>
        )}

        {canControl && (isRemoved || isUnpaid) && !mobile && (
          <li>
            <Tooltip
              placement="left"
              overlay={t('publish.do')}
              {...rcTooltipProps}
            >
              <button
                className={cls.action_btn}
                type="button"
                onClick={onPublishClick}
                disabled={loading}
              >
                <EyeIcon />
              </button>
            </Tooltip>
          </li>
        )}

        {canDelete && !mobile && (
          <li>
            <Tooltip
              placement="left"
              overlay={t('advertisements.delete')}
              {...rcTooltipProps}
            >
              <button
                className={cn(cls.action_btn, cls.action_btn_red)}
                type="button"
                onClick={() => {
                  setMoreOpen(false);
                  onDeleteClick();
                }}
                disabled={loading}
              >
                <DeleteIcon />
              </button>
            </Tooltip>
          </li>
        )}

        {!isVrp && isPublished && (
          <li className={cls.more_wrap}>
            <button
              className={cls.action_btn}
              type="button"
              onClick={() => setMoreOpen((p) => !p)}
            >
              <MoreIcon />
            </button>

            <Dropdown
              name={`advert-${advert.id}-actions-dropdown`}
              isOpen={isMoreOpen}
              close={() => setMoreOpen(false)}
              listTop={dropdownTop}
            >
              {mobile && (
                <DropdownItem>
                  <button
                    className={cls.dropdown_btn}
                    onClick={onShareClick}
                    type="button"
                  >
                    {t('common.share')}
                    <ShareIcon />
                  </button>
                </DropdownItem>
              )}
              {!isVrp && isPublished && (
                <DropdownItem>
                  <button
                    className={cls.dropdown_btn}
                    onClick={() => openInNewTab(pdfUrl)}
                    type="button"
                  >
                    {t('downloadQrCode')}
                    <QrIcon />
                  </button>
                </DropdownItem>
              )}
              {canCreateCopy && (
                <DropdownItem>
                  <CreateAdvertCopy
                    advert={advert}
                    onClick={() => {
                      setMoreOpen(false);
                    }}
                  />
                </DropdownItem>
              )}
              {!isVrp && (
                <DropdownItem>
                  <button
                    className={cls.dropdown_btn}
                    onClick={onSimilarClick}
                    type="button"
                  >
                    {t('similar')}
                    <SimilarIcon />
                  </button>
                </DropdownItem>
              )}
              {canControl && isPublished && mobile && (
                <DropdownItem>
                  <button
                    className={cls.dropdown_btn}
                    onClick={onUnpublishClick}
                    type="button"
                  >
                    {t('unpublish.do')}
                    <PauseIcon />
                  </button>
                </DropdownItem>
              )}
              {canControl && (isRemoved || isUnpaid) && mobile && (
                <DropdownItem>
                  <button
                    className={cls.dropdown_btn}
                    onClick={onPublishClick}
                    type="button"
                  >
                    {t('publish.do')}
                    <EyeIcon />
                  </button>
                </DropdownItem>
              )}
              {canDelete && (
                <DropdownItem>
                  <button
                    className={cn(cls.dropdown_btn, cls.dropdown_btn_danger)}
                    onClick={() => {
                      setMoreOpen(false);
                      onDeleteClick();
                    }}
                    type="button"
                  >
                    {t('advertisements.delete')}
                    <DeleteIcon />
                  </button>
                </DropdownItem>
              )}
            </Dropdown>
          </li>
        )}
      </ul>

      {isUnpublishOpen && (
        <UnpublishAdvert
          name="my-unpublish"
          id={advert.id}
          isOpen={isUnpublishOpen}
          close={closeUnpublish}
          onSubmit={onUnpublishSubmit}
          advert={advert}
        />
      )}

      {canDelete && (
        <ConfirmModal
          name="my-delete-confirm"
          isOpen={isDeleteOpen}
          close={() => setDeleteOpen(false)}
          onButtonClick={(confirmed) => {
            if (confirmed) {
              onDeleteSubmit();
            } else {
              setDeleteOpen(false);
            }
          }}
          title={t('advertisements.deleteConfirm')}
          confirmText={t('common.delete')}
          cancelText={t('common.cancel')}
        />
      )}

      {shareOpenedOnce && (
        <ShareModal
          isOpen={isShareOpen}
          close={() => setShareOpen(false)}
          advert={advert}
        />
      )}
    </>
  );
}
