import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCategories, useRentCategories } from '@/modules/category/api';
import { usePermissionAccess } from '@/modules/roles/helpers';
import { useAdvertCreation } from '@/modules/showroom/advert/create/hooks';
import { VRP_CATEGORY_ID } from '@/modules/showroom/advert/vrp/helpers';
import { useTransportTypes } from '@/modules/transport/api';
import { Dropdown, DropdownItem } from '@/ui/Dropdown/Dropdown';
import { Spinner } from '@/ui/Spinner/Spinner';
import { SLUG_NEW } from '@/utils/consts';

import newCarImg from './car-new.png';
import rentCarImg from './car-rent.png';
import usedCarImg from './car-used.png';
import cls from './CategoryDropdown.module.scss';

type ListType = {
  id: number;
  name: string;
  icon: string;
  show: boolean;
  plate?: boolean;
  rent?: boolean;
};

type Props = {
  isOpen: boolean;
  close: () => void;
  dropdownCls?: string;
  listTop?: boolean;
  fullWidth?: boolean;
  hidePlate?: boolean;
};

export function CategoryDropdown({
  isOpen,
  close,
  dropdownCls,
  listTop,
  fullWidth,
  hidePlate
}: Props) {
  const { t } = useTranslation();
  const permissions = usePermissionAccess();

  const [categories] = useCategories();
  const [rentCategories] = useRentCategories();
  const [types] = useTransportTypes();

  const list = useMemo<ListType[]>(() => {
    if (categories && categories.length > 0 && types && types.length > 0) {
      const result: ListType[] = [];
      types.forEach((c) => {
        const icon = c.slug === SLUG_NEW ? newCarImg : usedCarImg;
        const name = c.slug === SLUG_NEW ? t('typeNew') : t('typeUsed');
        result.push({
          id: c.id,
          name,
          icon,
          show: permissions.carSale.control
        });
      });

      if (!hidePlate)
        categories
          .filter((c) => c.id === VRP_CATEGORY_ID)
          .forEach((c) => {
            result.push({
              id: c.id,
              name: c.name,
              icon: c.icon_url || '',
              plate: true,
              show: permissions.plateSale.control
            });
          });

      if (rentCategories) {
        rentCategories.forEach((c) => {
          result.push({
            id: c.id,
            name: c.name,
            icon: rentCarImg,
            rent: true,
            show: permissions.rent.ads.control
          });
        });
      }

      return result;
    }

    return [];
  }, [
    categories,
    types,
    hidePlate,
    rentCategories,
    t,
    permissions.carSale.control,
    permissions.plateSale.control,
    permissions.rent.ads.control
  ]);

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const {
    plateCreating,
    createPlate: onPlateClick,
    carCreating,
    createCar: onCarClick,
    rentCarCreating,
    createRentCar: onRentCarClick
  } = useAdvertCreation(handleClose);

  return (
    <Dropdown
      name="advert-category-choose"
      isOpen={isOpen}
      close={close}
      className={dropdownCls}
      listTop={listTop}
      fullWidth={fullWidth}
      modalClassName={cls.modal_container}
      withModal
      alwaysOnTop
    >
      {list
        .filter((v) => v.show)
        .map((v, i) => {
          const isPlate = !!v.plate;
          const isRent = !!v.rent;

          if (isPlate) {
            return (
              <DropdownItem key={i}>
                <button
                  className={cls.btn}
                  type="button"
                  onClick={onPlateClick}
                  disabled={plateCreating}
                >
                  {plateCreating ? (
                    <Spinner size={32} color="var(--clr-blue)" />
                  ) : (
                    <img src={v.icon} alt="" />
                  )}
                  {v.name}
                </button>
              </DropdownItem>
            );
          }

          return (
            <Fragment key={i}>
              {!isPlate && !isRent && i === 0 && (
                <li className={cls.li_first} key="sale-label">
                  <p className={cls.label}>{t('forSale')}</p>
                </li>
              )}

              {i > 0 && (
                <li key="hr">
                  <div className={cls.hr} />
                </li>
              )}

              {isRent && (
                <li key="rent-label">
                  <p className={cls.label}>{t('forRent')}</p>
                </li>
              )}

              <DropdownItem key={i}>
                <button
                  className={cls.btn}
                  type="button"
                  onClick={() => {
                    if (isRent) {
                      onRentCarClick(v.id);
                    } else {
                      onCarClick(v.id);
                    }
                  }}
                  disabled={isRent ? rentCarCreating : carCreating}
                >
                  {rentCarCreating ? (
                    <Spinner size={32} color="var(--clr-blue)" />
                  ) : (
                    <img src={v.icon} alt="" />
                  )}

                  {v.name}
                </button>
              </DropdownItem>
            </Fragment>
          );
        })}
    </Dropdown>
  );
}
