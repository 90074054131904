import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { useCurrencyRate } from '@/modules/currency/hooks';
import { useDiscountTypes } from '@/modules/discount/api';
import {
  isDiscountsValid,
  isPriceValid,
  MAX_PRICE_LEN,
  MIN_PRICE,
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { CreateAdvertSlice } from '@/modules/showroom/advert/create/types';
import { Button } from '@/ui/Button/Button';
import { Checkbox } from '@/ui/Checkbox/Checkbox';
import { Input } from '@/ui/Input/Input';
import { Skeleton } from '@/ui/Skeleton';
import { ADV_CUR, CURRENCIES, CURRENCY_DISPLAY } from '@/utils/consts';
import { maxLength, numberWithSpaces, onlyNumbers } from '@/utils/format';

import cls from './Price.module.scss';

type Props = {
  price: CreateAdvertSlice['price'];
  setPrice: CreateAdvertSlice['setPrice'];
  isPriceFilled: CreateAdvertSlice['isPriceFilled'];
  setPriceFilled: CreateAdvertSlice['setPriceFilled'];
  discount: CreateAdvertSlice['discount'];
  setDiscount: CreateAdvertSlice['setDiscount'];
  discountTypes: CreateAdvertSlice['discountTypes'];
  setDiscountTypes: CreateAdvertSlice['setDiscountTypes'];
  hideDiscount?: boolean;
  minPrice?: number;
  maxPriceLen?: number;
  validatePrice?: (price: number) => boolean;
};

export function Price({
  price,
  setPrice,
  isPriceFilled,
  setPriceFilled,
  discount,
  setDiscount,
  discountTypes,
  setDiscountTypes,
  hideDiscount,
  minPrice,
  maxPriceLen,
  validatePrice
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  // Price
  const [priceTouched, setPriceTouched] = useState(false);
  const checkPriceValidity = validatePrice || isPriceValid;
  const priceValid = checkPriceValidity(price);
  const showPriceError = priceTouched && !priceValid;

  // Discount
  const [allDiscTypes, typesLoading] = useDiscountTypes();
  const [showDiscounts, setShowDiscounts] = useState(
    !(!discount && discountTypes.length <= 0)
  );

  const [discountTouched, setDiscountTouched] = useState(false);
  const discountsValid = isDiscountsValid(price, discount, discountTypes);
  const showDiscountError = discountTouched && discount >= price;

  const onRemoveDiscountClick = () => {
    setDiscount(0);
    setDiscountTypes([]);
    setShowDiscounts(false);
  };

  const onAddDiscountClick = () => {
    setShowDiscounts(true);
  };

  const toggleType = (id: number) => {
    return () => {
      if (discountTypes.includes(id)) {
        setDiscountTypes(discountTypes.filter((dt) => dt !== id));
      } else {
        setDiscountTypes([...discountTypes, id]);
      }
    };
  };

  // Currency
  const euroRate = useCurrencyRate(CURRENCIES.euro, CURRENCIES.aed);
  const euroPrice = euroRate ? Number.parseInt(String(price / euroRate)) : 0;
  const usdRate = useCurrencyRate(CURRENCIES.usd, CURRENCIES.aed);
  const usdPrice = usdRate ? Number.parseInt(String(price / usdRate)) : 0;

  // Continue
  const onContinueClick = () => {
    setPriceFilled(true);
    const isVrp = !!validatePrice;
    scrollToSection(isVrp ? SCROLLTO.promotion : SCROLLTO.stickers, 100);
  };

  return (
    <div className={cls.root} id={SCROLLTO.price}>
      <div className="box">
        <h1 className={cls.title}>{t('price')}</h1>
        <div className={cls.field}>
          <Input
            value={price ? numberWithSpaces(price, lang) : ''}
            onChange={(e) => {
              setPrice(
                Number(
                  maxLength(
                    onlyNumbers(e.currentTarget.value),
                    maxPriceLen || MAX_PRICE_LEN
                  )
                )
              );
            }}
            onBlur={() => setPriceTouched(true)}
            errorText={
              showPriceError
                ? t('priceMin', {
                    minPrice: minPrice || MIN_PRICE,
                    currency: ADV_CUR
                  })
                : ''
            }
            label={t('pricePlaceholder', { currency: ADV_CUR })}
            inputMode="numeric"
          />

          {!!price && !showPriceError && (!!usdPrice || !!euroPrice) && (
            <p className={cls.currency}>
              ~ {CURRENCY_DISPLAY.usd}
              {numberWithSpaces(usdPrice, lang)} {t('common.dot')}{' '}
              {CURRENCY_DISPLAY.euro}
              {numberWithSpaces(euroPrice, lang)}
            </p>
          )}
        </div>

        {!hideDiscount && showDiscounts && (
          <div className={cls.discounts}>
            <h2 className={cls.subtitle}>{t('discount.title')}</h2>
            <p className={cls.text}>{t('discount.desc')}</p>
            <p className={cls.label}>{t('discount.selectTypes')}</p>

            {allDiscTypes && !typesLoading && (
              <ul className={cls.types}>
                {allDiscTypes.map((dt) => (
                  <li key={dt.id}>
                    <Checkbox
                      id={`price-disc-type-${dt.id}`}
                      checked={discountTypes.includes(dt.id)}
                      onChange={toggleType(dt.id)}
                    >
                      {dt.name}
                    </Checkbox>
                  </li>
                ))}
              </ul>
            )}

            {typesLoading && (
              <div className={cls.types}>
                <Skeleton width={88} height={24} />
                <Skeleton width={88} height={24} />
                <Skeleton width={88} height={24} />
              </div>
            )}

            <div className={cls.field}>
              <Input
                value={discount ? numberWithSpaces(discount, lang) : ''}
                onChange={(e) => {
                  setDiscount(Number(onlyNumbers(e.currentTarget.value)));
                }}
                onBlur={() => setDiscountTouched(true)}
                errorText={showDiscountError ? t('discount.amountError') : ''}
                label={t('common.commaSeparated', {
                  first: t('discount.amount'),
                  second: CURRENCY_DISPLAY.aed
                })}
                inputMode="numeric"
              />
              {discount < price && !!price && !!discount && (
                <span className={cls.tip}>
                  {t('discount.price', {
                    amount: numberWithSpaces(price - discount, lang),
                    currency: CURRENCY_DISPLAY.aed
                  })}
                </span>
              )}
            </div>
          </div>
        )}

        <div className={cls.buttons}>
          {!hideDiscount && (
            <>
              {showDiscounts ? (
                <Button
                  onClick={onRemoveDiscountClick}
                  variant="secondary"
                  color="black"
                  fullWidth
                >
                  {t('discount.remove')}
                </Button>
              ) : (
                <Button
                  onClick={onAddDiscountClick}
                  variant="secondary"
                  color="black"
                  fullWidth
                >
                  {t('discount.add')}
                </Button>
              )}
            </>
          )}

          {!isPriceFilled && (
            <Button
              onClick={onContinueClick}
              disabled={!priceValid || !discountsValid}
              color="blue"
              fullWidth
            >
              {t('common.continue')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
