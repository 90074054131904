import isEqual from 'lodash/isEqual';

import { Nullable, PhotoType } from '@/app/types';
import {
  CreateAdvertSlice,
  SpecsSlice
} from '@/modules/showroom/advert/create/types';
import { AdvertAuthorDetail } from '@/modules/showroom/advert/detail/types';
import {
  cleanPhotosObjects,
  isReelVideoPlaylist
} from '@/modules/showroom/advert/update/helpers';
import { UpdateAdvertReq } from '@/modules/showroom/advert/update/types';
import { CreateVrpStore } from '@/modules/showroom/advert/vrp/CreateVrp/store';
import { replaceBreaksWithSpaces } from '@/utils/format';

export function isPhotosSame(
  photos1: Nullable<PhotoType[]>,
  photos2: Nullable<PhotoType[]>
): boolean {
  if (typeof photos1 !== typeof photos2) return false;
  if (photos1?.length !== photos2?.length) return false;

  const urls1 = photos1?.map((p) => p.photo_url).join('') || '';
  const urls2 = photos2?.map((p) => p.photo_url).join('') || '';
  return urls1 === urls2;
}

export function isArraySame(
  arr1: Nullable<number[]>,
  arr2: Nullable<number[]>,
  asJson?: boolean
): boolean {
  if (typeof arr1 !== typeof arr2) return false;
  if (arr1?.length !== arr2?.length) return false;

  const str1 = asJson ? JSON.stringify(arr1) : arr1?.join('') || '';
  const str2 = asJson ? JSON.stringify(arr1) : arr2?.join('') || '';
  return str1 === str2;
}

export function isMultilineTextSame(
  str1: Nullable<string>,
  str2: Nullable<string>
): boolean {
  if (typeof str1 !== typeof str2) return false;
  if (str1?.length !== str2?.length) return false;

  return (
    replaceBreaksWithSpaces(str1 || '') === replaceBreaksWithSpaces(str2 || '')
  );
}

export function _isEqual(v1: unknown, v2: unknown): boolean {
  return isEqual(v1, v2);
}

export function optimizeReq(
  store: CreateAdvertSlice & SpecsSlice,
  detail: AdvertAuthorDetail
): Omit<UpdateAdvertReq, 'is_published'> {
  const brandSame = store?.brand?.id === detail.brand?.id;
  const modelSame = store?.model?.id === detail.model?.id;
  const carChanged = !brandSame || !modelSame;

  const generationSame = carChanged
    ? false
    : store?.generation?.id === detail.generation?.id;
  const yearSame = carChanged
    ? false
    : store?.year?.id === detail.year_of_issue?.id;
  const bodySame = carChanged ? false : store?.body?.id === detail.body?.id;
  const modificationSame = carChanged
    ? false
    : store?.modification?.id === detail.modification?.id;
  const engineSame = carChanged
    ? false
    : store?.engine?.id === detail.engine?.id;
  const driveSame = carChanged
    ? false
    : store?.drive?.id === detail.drive_unit?.id;
  const transmissionSame = carChanged
    ? false
    : store?.transmission?.id === detail.transmission?.id;
  const colorSame = carChanged ? false : store?.color?.id === detail.colour?.id;
  const ownerSame = carChanged ? false : store?.owner?.id === detail.owner?.id;
  const mileageSame = carChanged ? false : store?.mileage === detail.mileage;
  const brokenSame = carChanged ? false : store?.broken === detail.is_broken;

  const photos = cleanPhotosObjects(store.photos);
  const photosSame = isPhotosSame(photos, detail.photos);
  const descrSame = isMultilineTextSame(store.description, detail.description);

  const videoUrl = store.videoUrl;
  const isVideoPlaylist = isReelVideoPlaylist(videoUrl);
  const videoSame = isVideoPlaylist
    ? videoUrl === detail.reels?.playlist_url
    : videoUrl === detail.reels?.source_url;
  const reels_video_url =
    videoSame || isVideoPlaylist ? undefined : store.videoUrl || '';

  const isChatAvailableSame = !store.disableChat === detail.available_chat;
  const isPhoneAvailableSame = !store.chatOnly === detail.available_phone;

  const tradeSame = store?.canExchange === detail.available_trade;
  const priceSame = store?.price === detail.price;
  const discountSame =
    store?.discount === (detail.discount?.discount_amount || 0);
  const discountTypesSame = isArraySame(
    store.discountTypes,
    detail.discount?.discounts?.map((d) => d.id) || []
  );

  const vinSame = store?.vin === detail.vin;
  const regSpecsSame = store?.regSpecs?.id === detail.regional_spec?.id;

  const storeComplectationId = store.isCustomComplectation
    ? null
    : store.complectationId;
  const complectationIdSame = storeComplectationId === detail.complectation?.id;

  const storeOptionsIds =
    store.complectationId && !store.isCustomComplectation
      ? null
      : store.complectationOptionsItemsIds;
  const detailComplectationOptionsItemsIds = detail.complectation
    ? detail.complectation?.complectation_options.reduce<number[]>(
        (acc, cur) => {
          return [
            ...acc,
            ...cur.options.reduce<number[]>((optAcc, optCur) => {
              return [...optAcc, ...optCur.items.map((item) => item.id)];
            }, [])
          ];
        },
        []
      )
    : null;
  const complectationOptionsItemsIdsSame = isArraySame(
    storeOptionsIds,
    detailComplectationOptionsItemsIds
  );

  const storeContacts =
    store.contacts && store.contacts?.length > 0 ? store.contacts : null;

  const addressIdSame = store.addressId === detail.address_id;
  const storeWarranty = store.warranty
    ? {
        include: true,
        expired_at: store.warranty
      }
    : null;
  const warrantySame =
    storeWarranty?.expired_at === detail.car_warranty?.expired_at &&
    storeWarranty?.include === detail.car_warranty?.include;

  return {
    brand_id: brandSame ? undefined : store.brand?.id || null,
    model_id: modelSame ? undefined : store.model?.id || null,
    generation_id: generationSame ? undefined : store.generation?.id || null,
    year_of_issue_id: yearSame ? undefined : store.year?.id || null,
    body_id: bodySame ? undefined : store.body?.id || null,
    modification_id: modificationSame
      ? undefined
      : store.modification?.id || null,
    engine_id: engineSame ? undefined : store.engine?.id || null,
    drive_unit_id: driveSame ? undefined : store.drive?.id || null,
    transmission_id: transmissionSame
      ? undefined
      : store.transmission?.id || null,
    colour_id: colorSame ? undefined : store.color?.id || null,
    owner_id: ownerSame ? undefined : store.owner?.id || null,
    mileage: mileageSame ? undefined : store.mileage,
    is_broken: brokenSame
      ? undefined
      : store.broken === null
      ? undefined
      : store.broken,
    photos: photosSame ? undefined : photos,
    reels_video_url,
    description: descrSame ? undefined : store.description,
    available_chat: isChatAvailableSame ? undefined : !store.disableChat,
    available_phone: isPhoneAvailableSame ? undefined : !store.chatOnly,
    available_trade: tradeSame ? undefined : store.canExchange,
    price: priceSame ? undefined : store.price || null,
    discount:
      discountSame && discountTypesSame
        ? undefined
        : {
            discount_amount: store.discount,
            discount_ids: store.discountTypes
          },
    vin: vinSame ? undefined : store.vin || null,
    regional_spec_id: regSpecsSame ? undefined : store.regSpecs?.id || null,
    complectation_id: complectationIdSame ? undefined : storeComplectationId,
    options_items_list_ids: complectationOptionsItemsIdsSame
      ? undefined
      : storeOptionsIds,
    contacts: storeContacts,
    address_id: addressIdSame ? undefined : store.addressId,
    warranty: warrantySame ? undefined : storeWarranty
  };
}

export function optimizeReqForVrp(
  store: CreateVrpStore,
  detail: AdvertAuthorDetail
): Omit<UpdateAdvertReq, 'is_published'> {
  const typeIdSame = store.typeId === detail.vrp_type?.id;
  const numberSame = store?.plateNumber === detail.vrp_plate_number;
  const plateImgSame = store?.imageUrl === detail.vrp_image_url;
  const codeIdSame = store.codeId === detail.vrp_plate_code?.id;
  const emirateIdSame = store.emirateId === detail.vrp_plate_emirate?.id;

  const descrSame = isMultilineTextSame(store.description, detail.description);
  const isChatAvailableSame = !store.disableChat === detail.available_chat;
  const isPhoneAvailableSame = !store.chatOnly === detail.available_phone;

  const priceSame = store?.price === detail.price;
  const discountSame =
    store?.discount === (detail.discount?.discount_amount || 0);
  const discountTypesSame = isArraySame(
    store.discountTypes,
    detail.discount?.discounts?.map((d) => d.id) || []
  );

  const storeContacts =
    store.contacts && store.contacts?.length > 0 ? store.contacts : null;
  const addressIdSame = store.addressId === detail.address_id;

  return {
    vrp_type_id: typeIdSame ? undefined : store.typeId,
    vrp_plate_number: numberSame ? undefined : store.plateNumber || null,
    vrp_image_url: plateImgSame ? undefined : store.imageUrl,
    vrp_plate_code_id: codeIdSame ? undefined : store.codeId,
    vrp_plate_emirate_id: emirateIdSame ? undefined : store.emirateId,
    description: descrSame ? undefined : store.description,
    available_chat: isChatAvailableSame ? undefined : !store.disableChat,
    available_phone: isPhoneAvailableSame ? undefined : !store.chatOnly,
    contacts: storeContacts,
    address_id: addressIdSame ? undefined : store.addressId,
    price: priceSame ? undefined : store.price || null,
    discount:
      discountSame && discountTypesSame
        ? undefined
        : {
            discount_amount: store.discount,
            discount_ids: store.discountTypes
          }
  };
}
