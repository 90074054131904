import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { ChatAdName } from '@/modules/chat/ChatList/ChatCard/ChatAdName';
import { useAdvertInfo } from '@/modules/chat/hooks';
import { ChatAdvert } from '@/modules/chat/types';
import { Avatar } from '@/ui/Avatar/Avatar';
import { BackLink } from '@/ui/BackLink/BackLink';
import { TimeAgo } from '@/ui/TimeAgo/TimeAgo';
import { cn } from '@/utils/cn';

import cls from './Header.module.scss';

type Props = {
  ad?: Nullable<ChatAdvert>;
  chatId?: number;
  avatarUrl?: string;
  name?: string;
  lastSeen: Nullable<number>;
  online: boolean;
  loading?: boolean;
  openProfile?: () => void;
  companionId?: number;
};

export function Header({
  ad,
  chatId,
  avatarUrl,
  name,
  lastSeen,
  online,
  loading,
  openProfile,
  companionId
}: Props) {
  const { t } = useTranslation();
  const advertInfo = useAdvertInfo(ad);

  const date = useMemo(
    () => (lastSeen ? new Date(lastSeen * 1000) : null),
    [lastSeen]
  );

  return (
    <header className={cn(cls.root, { [cls.root_nochat]: !chatId })}>
      {openProfile && (
        <button
          className={cls.profile_btn}
          type="button"
          onClick={openProfile}
        />
      )}

      {chatId && (
        <>
          <div className={cls.back}>
            <BackLink text={t('common.back')} to="/chat" />
          </div>
        </>
      )}

      <div className={cls.avatar}>
        {companionId && name ? (
          <Avatar user={{ id: companionId, name }} src={avatarUrl} size={36} />
        ) : (
          <Avatar src={avatarUrl} size={chatId ? 36 : 44} />
        )}
      </div>

      <div className={cls.text}>
        <b className={cls.name}>{name || t('client')}</b>

        <span
          className={cn(cls.status, {
            [cls.status_online]: online && !loading
          })}
        >
          {loading ? (
            t('chat.loading')
          ) : (
            <>
              {online && t('chat.online')}
              {!online && date && (
                <Trans
                  t={t}
                  i18nKey="chat.lastSeen"
                  components={{ timeValue: <TimeAgo date={date} /> }}
                />
              )}
            </>
          )}
        </span>
      </div>

      {ad && (
        <div className={cls.ad}>
          <p className={cls.ad_title}>
            <ChatAdName advert={ad} />
          </p>
          <ul className={cls.ad_stats}>
            {advertInfo.map((row, i) => (
              <li key={i}>{row}</li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
}
