import { cn } from '@/utils/cn';

import cls from './CheckBtn.module.scss';

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
  outlined?: boolean;
};

export const CheckBtn: React.FC<Props> = ({
  children,
  onClick,
  active,
  disabled,
  outlined = true
}) => {
  return (
    <button
      className={cn(cls.root, {
        [cls.root_active]: active,
        [cls.root_outlined]: outlined
      })}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
