import { EXPENSE_CATEGORY_COLORS_MAP } from '@/modules/wallet/expense-helpers';
import {
  StatisticAdvert,
  ExpensesDetailStatisticsRes,
  StatisticsDay
} from '@/modules/wallet/types';

export type ExpensesAdvertType = {
  advert: StatisticAdvert;
  categories: {
    id: number;
    name: string;
    count: number;
    total: number;
    color: string;
  }[];
  promos: {
    id: number;
    name: string;
    count: number;
    total: number;
    logo: string;
  }[];
  total: number;
};

const PROMOTION_CAT_IDS = [2, 3, 4];

function mapExpensesAdvert(
  advertExpenses: StatisticsDay[],
  allCategories: ExpensesDetailStatisticsRes['categories'],
  allPromos: ExpensesDetailStatisticsRes['promotion']
): ExpensesAdvertType {
  const categoriesMap: Record<number, StatisticsDay[]> = {};
  const promoMap: Record<number, StatisticsDay[]> = {};

  advertExpenses.forEach((expense) => {
    const catId = expense.category_id;

    if (!PROMOTION_CAT_IDS.includes(catId))
      if (catId in categoriesMap) {
        categoriesMap[catId] = [...categoriesMap[catId], expense];
      } else {
        categoriesMap[catId] = [expense];
      }

    const pId = expense.activated_promotion_package_id;
    if (typeof pId === 'number') {
      if (pId in promoMap) {
        promoMap[pId] = [...promoMap[pId], expense];
      } else {
        promoMap[pId] = [expense];
      }
    }
  });

  const categories = Object.keys(categoriesMap).map((c) => {
    const catId = Number(c);
    const cat = allCategories.find((c) => c.id === catId);

    return {
      id: catId,
      name: cat?.name || '',
      count: categoriesMap[catId].length,
      total: categoriesMap[catId].reduce((acc, c) => acc + c.amount, 0),
      color: EXPENSE_CATEGORY_COLORS_MAP[catId]
    };
  });

  const promos = Object.keys(promoMap).map((p) => {
    const pId = Number(p);
    const promo = allPromos.find((p) => p.id === pId);

    return {
      id: pId,
      name: promo?.name || '',
      count: promoMap[pId].length,
      total: promoMap[pId].reduce((acc, c) => acc + c.amount, 0),
      logo: promo?.logo_url || ''
    };
  });

  return {
    categories,
    promos,
    total:
      categories.reduce((acc, c) => acc + c.total, 0) +
      promos.reduce((acc, p) => acc + p.total, 0),
    advert: advertExpenses[0].advertisement as StatisticAdvert
  };
}

export function mapExpensesAdverts(
  expensesData: ExpensesDetailStatisticsRes
): ExpensesAdvertType[] {
  const advertsMap: Record<number, StatisticsDay[]> = {};

  expensesData.expenses.forEach((expense) => {
    if (!expense.advertisement) return;

    const advertId = expense.advertisement.id;
    if (advertId in advertsMap) {
      advertsMap[advertId] = [...advertsMap[advertId], expense];
    } else {
      advertsMap[advertId] = [expense];
    }
  });

  return Object.keys(advertsMap)
    .map((advertId) =>
      mapExpensesAdvert(
        advertsMap[Number(advertId)],
        // expensesData.categories.filter((c) => c.id !== PROMOTION_CAT_ID),
        expensesData.categories.filter(
          (c) => !PROMOTION_CAT_IDS.includes(c.id)
        ),
        expensesData.promotion
      )
    )
    .reverse();
}
