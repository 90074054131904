import { useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { useDarkmode } from '@/modules/theme/useTheme';
import {
  ExpenseCategory,
  getCategoriesWithTotal
} from '@/modules/wallet/expense-helpers';
import { mapGraphData } from '@/modules/wallet/helpers';
import { ExpensesDetailStatisticsRes } from '@/modules/wallet/types';
import { getApexOptions } from '@/modules/wallet/WalletGraph/helpers';
import { Checkbox } from '@/ui/Checkbox/Checkbox';
import { cn } from '@/utils/cn';
import { ADV_CUR } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';

import cls from './WalletGraph.module.scss';

type Props = {
  from: Date;
  to: Date;
  data: ExpensesDetailStatisticsRes;
};

export function WalletGraph({ from, to, data }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();
  const { isDark } = useDarkmode();

  const [excludedCategories, setExcludedCategories] = useState<number[]>([]);
  const toggleCategory = (id: number) => {
    return () => {
      setExcludedCategories((prev) => {
        if (prev.includes(id)) return prev.filter((c) => c !== id);
        return [...prev, id];
      });
    };
  };
  const filteredData = useMemo(() => {
    if (excludedCategories.length <= 0) return data;
    return {
      ...data,
      categories: data.categories
        .filter((e) => !excludedCategories.includes(e.id))
        .sort((a, b) => a.id - b.id),
      expenses: data.expenses.filter(
        (e) => !excludedCategories.includes(e.category_id)
      )
    };
  }, [data, excludedCategories]);

  const mappedData = useMemo(
    () => mapGraphData(filteredData, from, to),
    [filteredData, from, to]
  );
  const series = mappedData.mappedData;

  const options = useMemo(
    () =>
      getApexOptions(
        mappedData.mappedDates,
        filteredData.categories,
        lang,
        isDark
      ),
    [filteredData.categories, isDark, lang, mappedData.mappedDates]
  );

  const listData = useMemo<ExpenseCategory[]>(() => {
    return getCategoriesWithTotal(data);
  }, [data]);

  const total = listData
    .filter((c) => !excludedCategories.includes(c.id))
    .reduce((acc, c) => acc + c.total, 0);

  return (
    <div className={cls.graph}>
      <h2 className={cls.title}>
        {t('priceCurrency', {
          price: numberWithSpaces(total, lang),
          currency: ADV_CUR
        })}
      </h2>
      <span className={cls.subtitle}>{t('wallet.expensesTotal2')}</span>

      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={228}
      />

      <div className={cn(cls.categories_wrap, 'hide-scrollbar')}>
        <ul className={cls.categories}>
          {listData.map((c) => {
            const excluded = excludedCategories.includes(c.id);

            return (
              <li key={c.id}>
                <button
                  className={cn(cls.category_btn, {
                    [cls.category_btn_excluded]: excluded
                  })}
                  type="button"
                  onClick={toggleCategory(c.id)}
                >
                  <Checkbox
                    id={`check-category-${c.id}`}
                    checked={!excluded}
                    iconStyle={{ color: c.color }}
                    small
                  />
                  <div className={cls.category_name}>
                    <span>{c.name}</span>
                    <p>
                      {t('priceCurrency', {
                        price: numberWithSpaces(c.total, lang),
                        currency: ADV_CUR
                      })}
                    </p>
                  </div>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
