
import { Nullable } from '@/app/types';
import { ChatCardSkeleton } from '@/modules/chat/ChatList/ChatCard/ChatCardSkeleton';
import { ChatSimple } from '@/modules/chat/types';
import { cn } from '@/utils/cn';

import { ChatCard } from './ChatCard/ChatCard';
import cls from './ChatList.module.scss';

const skeletons = new Array(5).fill(0);

type Props = {
  chats: ChatSimple[];
  loading: boolean;
  selectedChat: Nullable<ChatSimple>;
  chatLink: boolean;
  onChatClick?: (chat: ChatSimple) => void;
};

export function ChatList({
  chats,
  loading,
  selectedChat,
  chatLink,
  onChatClick
}: Props) {
  const handleClick = (c: ChatSimple) => {
    if (chatLink) return;
    return () => {
      if (onChatClick) onChatClick(c);
    };
  };

  if (!loading && chats.length <= 0) return <></>;

  return (
    <div className={cn(cls.root, 'hide-scrollbar')}>
      {loading && (
        <ul className={cls.list}>
          {skeletons.map((_, i) => (
            <li key={i}>
              <ChatCardSkeleton />
            </li>
          ))}
        </ul>
      )}

      {!loading && (
        <ul className={cls.list}>
          {chats.map((c) => (
            <li
              key={c.id}
              className={cn({ [cls.active]: selectedChat?.id === c.id })}
            >
              <ChatCard
                chat={c}
                link={chatLink}
                onClick={handleClick(c)}
                active={selectedChat?.id === c.id}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
