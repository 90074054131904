import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axios } from '@/app/api';
import { Nullable, PaginationReq } from '@/app/types';
import { useInterval } from '@/hooks/useInterval';
import { useLang } from '@/hooks/useLang';
import { PeriodCount } from '@/modules/accounts/types';
import { useAuthed } from '@/modules/auth/hooks';
import { CHAT_API_ROUTES } from '@/modules/chat/api';
import {
  GetUnreadCountRes,
  ChatSimple,
  GetMyChatsRes,
  PullMyChatsRes,
  SearchChatMessagesIdsRes,
  SearchChatMessagesReq,
  GetAllChatPhotosVideosReq,
  GetAllChatPhotosVideosRes,
  GetAllChatFilesReq,
  GetAllChatFilesRes,
  GetMyChatsReq,
  ChatAdvert,
  ChatSettingsRes
} from '@/modules/chat/types';
import { useChatUtilsStore } from '@/modules/chat/utils-store';
import { LeadAd } from '@/modules/leads/types';
import { useDarkmode } from '@/modules/theme/useTheme';
import { ADV_CUR, NETWORK_ERR_CODE, TIMEOUT_ERR_CODE } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';
import { showAlert } from '@/utils/network';

import bgDark from './background-dark.png';
import bgLight from './background.png';

export function useUnreadCount(): [number, boolean] {
  const [isAuthed] = useAuthed();
  const unreadCount = useChatUtilsStore((s) => s.unreadCount);
  const setUnreadCount = useChatUtilsStore((s) => s.setUnreadCount);
  const unreadCountUpdateKey = useChatUtilsStore((s) => s.unreadCountUpdateKey);

  const [loading, setLoading] = useState<boolean>(false);
  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<GetUnreadCountRes>(
        CHAT_API_ROUTES.getUnreadCount
      );
      setUnreadCount(r.data.count);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [setUnreadCount]);

  useInterval(load, isAuthed ? 15000 : null);

  useEffect(() => {
    if (isAuthed) load();
  }, [load, isAuthed, unreadCountUpdateKey]);

  return [unreadCount, loading];
}

export function useMyChats(
  req: GetMyChatsReq
): [ChatSimple[] | undefined, boolean, boolean] {
  const [authed] = useAuthed();
  const chatResetKey = useChatUtilsStore((s) => s.chatResetKey);

  const [abortController, setAbortController] = useState<
    Nullable<AbortController>
  >(new window.AbortController());
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isPulling, setPulling] = useState<boolean>(false);
  const [data, setData] = useState<ChatSimple[]>();
  const sorted = useMemo(
    () =>
      data
        ? [...data].sort((a, b) => {
            const bCreatedAt = b.last_message ? b.last_message.created_at : 0;
            const aCreatedAt = a.last_message ? a.last_message.created_at : 0;
            return bCreatedAt - aCreatedAt;
          })
        : data,
    [data]
  );

  const load = useCallback(async () => {
    setLoading(true);

    try {
      // todo: chats pagination
      const r = await axios.get<GetMyChatsRes>(CHAT_API_ROUTES.getMy, {
        params: req
      });
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 150);
    }
  }, [req]);

  const pull = useCallback(async (abort: AbortController) => {
    setPulling(true);

    try {
      const r = await axios.get<PullMyChatsRes>(CHAT_API_ROUTES.pullMy, {
        signal: abort.signal
      });
      const responseChats = r.data;

      if (responseChats && responseChats.length > 0) {
        setData((p) => {
          const prevState = p || [];
          const currentIds = prevState.map((c) => c.id);
          const newChats = responseChats.filter(
            (c) => !currentIds.includes(c.id)
          );
          const updatedChats = responseChats.filter((c) =>
            currentIds.includes(c.id)
          );
          const updatedIds = updatedChats.map((c) => c.id);

          const merged = prevState.map((c) => {
            if (updatedIds.includes(c.id)) {
              const updatedChat = updatedChats.find((v) => v.id === c.id);
              if (updatedChat) return { ...c, ...updatedChat };
            }

            return c;
          });

          return [...newChats, ...merged];
        });
      }

      setPulling(false);
      pull(abort);
    } catch (error) {
      // @ts-ignore
      const isCanceled = !!error && error.message === 'canceled';

      if (!isCanceled) {
        if (
          // @ts-ignore
          (error && error.response?.status === TIMEOUT_ERR_CODE) ||
          // @ts-ignore
          (error && error.code === NETWORK_ERR_CODE)
        ) {
          pull(abort);
        } else {
          showAlert({ error });
        }
      }

      setPulling(false);
    }
  }, []);

  useEffect(() => {
    if (authed) load();
  }, [authed, load, chatResetKey]);

  useEffect(() => {
    if (authed) {
      if (abortController) abortController.abort();
      const ab = new AbortController();
      setAbortController(ab);
      pull(ab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authed]);

  useEffect(() => {
    return () => {
      if (abortController) abortController.abort();
    };
  }, [abortController]);

  return [sorted, isLoading, isPulling];
}

export function useSearchChatMessagesIds(
  chatId: number,
  search: string
): [SearchChatMessagesIdsRes, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<SearchChatMessagesIdsRes>([]);

  const load = useCallback(async () => {
    if (!search) {
      setData([]);
      return;
    }

    setLoading(true);
    try {
      const req: SearchChatMessagesReq = { ids_only: true, value: search };
      const r = await axios.get<SearchChatMessagesIdsRes>(
        CHAT_API_ROUTES.searchChatMessages(chatId),
        { params: req }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [chatId, search]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}

export function useChatHasAttachments(
  chatId: number
): [boolean | null, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [hasAttachments, setHasAttachments] = useState<boolean | null>(null);

  const load = useCallback(async () => {
    const pg: PaginationReq = { skip: 0, limit: 1 };
    setLoading(true);

    try {
      const mediaReq: GetAllChatPhotosVideosReq = pg;
      const mediaRes = await axios.get<GetAllChatPhotosVideosRes>(
        CHAT_API_ROUTES.getAllChatPhotosVideos(chatId),
        { params: mediaReq }
      );

      const filesReq: GetAllChatFilesReq = pg;
      const filesRes = await axios.get<GetAllChatFilesRes>(
        CHAT_API_ROUTES.getAllChatFiles(chatId),
        { params: filesReq }
      );

      const has = mediaRes.data.length > 0 || filesRes.data.length > 0;
      setHasAttachments(has);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [chatId]);

  useEffect(() => {
    load();
  }, [load]);

  return [hasAttachments, isLoading];
}

export function useCreatedChatsCount(
  period_start: number,
  period_end: number
): [PeriodCount | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState<PeriodCount>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await axios.get<PeriodCount>(
        CHAT_API_ROUTES.getCreatedChatsCount,
        {
          params: { period_start, period_end }
        }
      );
      setCount(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [period_end, period_start]);

  useEffect(() => {
    load();
  }, [load]);

  return [count, isLoading];
}

export function useChatBgImage() {
  const { isDark } = useDarkmode();
  return isDark ? bgDark : bgLight;
}

export function useAdvertInfo(advert?: Nullable<ChatAdvert | LeadAd>) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const price = useMemo(
    () =>
      advert?.price
        ? t('priceCurrency', {
            price: numberWithSpaces(advert.price || 0, lang),
            currency: ADV_CUR
          })
        : '',
    [advert, lang, t]
  );

  const plateNumber = useMemo(() => {
    if (!advert || !advert.vrp_plate_code || !advert.vrp_plate_number)
      return '';

    return `${advert.vrp_plate_code?.name}${t('common.spaceSeparator')}${
      advert.vrp_plate_number
    }`;
  }, [advert, t]);

  const engine = useMemo(() => {
    if (!advert?.modification) return '';

    const modification = advert.modification;
    return `${modification.volume?.short_name} ${modification.transmission.short_name}`;
  }, [advert]);

  const horsePower = useMemo(() => {
    if (!advert?.modification) return '';

    const modification = advert.modification;
    return t('common.commaSeparated', {
      first: t('horsepower', { value: modification.horse_power?.short_name }),
      second: modification.engine.short_name
    });
  }, [advert, t]);

  const driveUnit = useMemo(() => {
    if (!advert?.modification) return '';

    const modification = advert.modification;
    return modification.drive_unit.short_name;
  }, [advert]);

  return [price, plateNumber, engine, horsePower, driveUnit].filter(
    (row) => !!row
  );
}

export function useUserChatSettings(
  userId: Nullable<number>
): [ChatSettingsRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ChatSettingsRes>();

  const load = useCallback(async (userId: number) => {
    setLoading(true);

    try {
      const r = await axios.get<ChatSettingsRes>(
        CHAT_API_ROUTES.getChatSettings,
        { params: { account_id: userId } }
      );
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (userId) load(userId);
  }, [load, userId]);

  return [data, isLoading];
}
