import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtraCommonObj, Nullable } from '@/app/types';
import { ChatStatus } from '@/modules/chat/types';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { cn } from '@/utils/cn';

import cls from './Header.module.scss';

type Props = {
  chatStatus: ChatStatus;
  setChatStatus: (v: ChatStatus) => void;
};

export const ChatStatusFilter: React.FC<Props> = ({
  chatStatus,
  setChatStatus
}) => {
  const { t } = useTranslation();

  const [isChatStatusOpen, setChatStatusOpen] = useState(false);
  const [selectedChatStatusId, setSelectedChatStatusId] =
    useState<Nullable<number>>(null);

  const chatStatusesOptions = useMemo<ExtraCommonObj[]>(
    () =>
      Object.values(ChatStatus).map((adCategory, i) => ({
        id: i,
        name: t(`chat.chatStatuses.${adCategory}`),
        extra: adCategory
      })),
    [t]
  );

  const onChatStatusChange = (v: ExtraCommonObj) => {
    setSelectedChatStatusId(v.id);
  };

  useEffect(() => {
    const defaultChatStatus = chatStatusesOptions.find(
      (chatStatus) => chatStatus.extra === ChatStatus.all
    );
    setSelectedChatStatusId(defaultChatStatus?.id || null);
  }, [chatStatusesOptions, setChatStatus]);

  useEffect(() => {
    const newChatStatus = chatStatusesOptions.find(
      (adCategory) => adCategory.id === selectedChatStatusId
    );

    newChatStatus && setChatStatus(newChatStatus.extra as ChatStatus);
  }, [chatStatusesOptions, selectedChatStatusId, setChatStatus]);

  return (
    <>
      <FilterBtn
        text={t('chat.chatStatus')}
        onClick={() => setChatStatusOpen(true)}
        active={isChatStatusOpen}
        value={
          selectedChatStatusId
            ? (t(`chat.chatStatuses.${chatStatus}`) as string)
            : undefined
        }
        solidBorder
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_select)}
        name="chat-status"
        isOpen={isChatStatusOpen}
        close={() => setChatStatusOpen(false)}
        toLeft
      >
        <li className="hide-scrollbar">
          <FiltersList
            activeId={selectedChatStatusId}
            list={chatStatusesOptions}
            onClick={onChatStatusChange}
            search=""
            selectStyle
          />
        </li>
      </Dropdown>
    </>
  );
};
