import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useOnceTrue } from '@/hooks/useOnceTrue';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { FilterType } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/helpers';
import { useSearchStore } from '@/modules/showroom/advert/search/store';
import { useTransportTypes } from '@/modules/transport/api';
import { GetTransportTypesReq, TransportType } from '@/modules/transport/types';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { cn } from '@/utils/cn';
import { MAX_PG_LIMIT_REQ } from '@/utils/consts';

import cls from './Filters.module.scss';

export function TypeFilter() {
  const { t } = useTranslation();

  const [isTypeOpen, setTypeOpen] = useState(false);
  const onceOpened = useOnceTrue(isTypeOpen);

  const typeId = useSearchStore((s) => s.typeId);
  const setTypeId = useSearchStore((s) => s.setTypeId);

  const typesReq = useMemo<Nullable<GetTransportTypesReq>>(() => {
    if (!onceOpened && !typeId) return null;
    return MAX_PG_LIMIT_REQ;
  }, [onceOpened, typeId]);
  const [allTypes, typesLoading] = useTransportTypes(typesReq);
  const type = allTypes?.find((tp) => tp.id === typeId);

  const onTypeChange = (v: FilterType) => {
    const tp = v as TransportType;
    setTypeId(tp.id);
    setTypeOpen(false);
  };

  const onTypeReset = () => {
    setTypeOpen(false);
    setTypeId(null);
  };

  return (
    <>
      <FilterBtn
        text={t('condition')}
        onClick={() => setTypeOpen(true)}
        active={isTypeOpen}
        onResetClick={onTypeReset}
        value={type?.name}
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_select)}
        name="search-filters-condition"
        isOpen={isTypeOpen}
        close={() => setTypeOpen(false)}
        toLeft
        withModal
      >
        <li className="hide-scrollbar">
          <FiltersList
            activeId={typeId}
            list={allTypes || []}
            loading={typesLoading}
            onClick={onTypeChange}
            search=""
            allButtonText={t('common.anyN')}
            onAllClick={onTypeReset}
            selectStyle
          />
        </li>
      </Dropdown>
    </>
  );
}
