import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtraCommonObj, Nullable } from '@/app/types';
import { ChatAdCategory } from '@/modules/chat/types';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { cn } from '@/utils/cn';

import cls from './Header.module.scss';

type Props = {
  adCategory: ChatAdCategory;
  setAdCategory: (v: ChatAdCategory) => void;
};

export const AdCategoryFilter: React.FC<Props> = ({
  adCategory,
  setAdCategory
}) => {
  const { t } = useTranslation();

  const [isAdCategoryOpen, setAdCategoryOpen] = useState(false);
  const [selectedAdCategoryId, setSelectedAdCategoryId] =
    useState<Nullable<number>>(null);

  const adCategoriesOptions = useMemo<ExtraCommonObj[]>(
    () =>
      Object.values(ChatAdCategory).map((adCategory, i) => ({
        id: i,
        name: t(`chat.adCategories.${adCategory}`),
        extra: adCategory
      })),
    [t]
  );

  const onAdCategoryChange = (v: ExtraCommonObj) => {
    setSelectedAdCategoryId(v.id);
  };

  useEffect(() => {
    const defaultAdCategory = adCategoriesOptions.find(
      (adCategory) => adCategory.extra === ChatAdCategory.all
    );
    setSelectedAdCategoryId(defaultAdCategory?.id || null);
  }, [adCategoriesOptions, setAdCategory]);

  useEffect(() => {
    const newAdCategory = adCategoriesOptions.find(
      (adCategory) => adCategory.id === selectedAdCategoryId
    );

    newAdCategory && setAdCategory(newAdCategory.extra as ChatAdCategory);
  }, [adCategoriesOptions, selectedAdCategoryId, setAdCategory]);

  return (
    <>
      <FilterBtn
        text={t('chat.adCategory')}
        onClick={() => setAdCategoryOpen(true)}
        active={isAdCategoryOpen}
        value={
          selectedAdCategoryId
            ? (t(`chat.adCategories.${adCategory}`) as string)
            : undefined
        }
        solidBorder
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_select)}
        name="chat-ad-category"
        isOpen={isAdCategoryOpen}
        close={() => setAdCategoryOpen(false)}
        toLeft
      >
        <li className="hide-scrollbar">
          <FiltersList
            activeId={selectedAdCategoryId}
            list={adCategoriesOptions}
            onClick={onAdCategoryChange}
            search=""
            selectStyle
          />
        </li>
      </Dropdown>
    </>
  );
};
