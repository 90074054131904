import { useMemo } from 'react';

import {
  isPhoneValid,
  MAX_DESCR_LEN
} from '@/modules/showroom/advert/create/helpers';
import {
  CreateAdvertSlice,
  SpecsSlice
} from '@/modules/showroom/advert/create/types';
import {
  isAgeValid,
  isTariffsValid
} from '@/modules/showroom/advert/rent/update/helpers';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';
import { RentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store-type';
import { AdvertStatusType } from '@/modules/showroom/advert/types';

type StoreType = CreateAdvertSlice & SpecsSlice & RentUpdateAdvertStore;

const disableCoreChangesFn = (store: StoreType) => {
  const status = store.detail?.status.title;
  const statuses = [
    AdvertStatusType.moderation,
    AdvertStatusType.rejected,
    AdvertStatusType.published,
    AdvertStatusType.sold
  ];
  if (!store.brand) return false;
  return status ? statuses.includes(status) : false;
};

const isPublishedFn = (store: StoreType) => {
  const status = store.detail?.status.title;
  return status === AdvertStatusType.published;
};

const canPublishFn = (store: StoreType) => {
  const state = store;
  const photos = state.photos;
  const modification = state.modification;

  const description = state.description;
  const descrValid = description.length <= MAX_DESCR_LEN;

  const addressId = state.addressId;
  const contacts = state.contacts;
  const phoneValid = isPhoneValid(contacts);

  const tariffs = state.tariffs;
  const tariffsValid = isTariffsValid(tariffs);

  const age = state.age;
  const driveExp = state.driveExp;
  const ageValid = isAgeValid(age, driveExp);

  const canPublish =
    !!modification &&
    photos.length > 0 &&
    descrValid &&
    !!addressId &&
    phoneValid &&
    tariffsValid &&
    ageValid;

  return canPublish;
};

const showPromoFn = (store: StoreType) => {
  const status = store.detail?.status.title;
  const showPromo = ![
    AdvertStatusType.published,
    AdvertStatusType.moderation,
    AdvertStatusType.rejected
  ].includes(status as AdvertStatusType);
  return showPromo;
};

export function useRentAdvertUpdateFlags() {
  const store = useRentUpdateAdvertStore((s) => s);
  const disableCoreChanges = disableCoreChangesFn(store);
  const isPublished = isPublishedFn(store);
  const canPublish = canPublishFn(store);
  const showPromo = showPromoFn(store);

  return useMemo(
    () => ({ disableCoreChanges, isPublished, canPublish, showPromo }),
    [disableCoreChanges, canPublish, isPublished, showPromo]
  );
}
