import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

import { ChatCard } from '@/modules/chat/ChatList/ChatCard/ChatCard';
import chatListCls from '@/modules/chat/ChatList/ChatList.module.scss';
import { useChatDetailStore } from '@/modules/chat/Detail/store';
import { ChatCompanion, ChatSimple } from '@/modules/chat/types';
import { Spinner } from '@/ui/Spinner/Spinner';
import { cn } from '@/utils/cn';

import cls from './Chats.module.scss';
import { useChatsWithCompanion } from './helpers';

type Props = {
  companion: ChatCompanion;
  chatId: number;
};

export function Chats({ companion, chatId }: Props) {
  const { t } = useTranslation();

  const { isAllLoaded, isLoading, data, load } = useChatsWithCompanion();

  const navigate = useNavigate();
  const setProfileOpen = useChatDetailStore((s) => s.setProfileOpen);

  const fetchData = () => {
    if (isAllLoaded || isLoading) return;
    load(companion.user_id, data ? data.length : 0);
  };

  const onChatClick = (c: ChatSimple) => {
    setProfileOpen(false);
    navigate(`/chat/${c.id}`);
  };

  const filteredData = useMemo(
    () => (data ? data.filter((chat) => chat.id !== chatId) : []),
    [data, chatId]
  );

  const showEmpty = !isLoading && filteredData && filteredData.length <= 0;

  return (
    <div className={cn(cls.root, { [cls.root_empty]: showEmpty })}>
      {filteredData && filteredData.length > 0 && (
        <ul className={chatListCls.list}>
          {filteredData.map((c) => (
            <li key={c.id}>
              <ChatCard chat={c} onClick={() => onChatClick(c)} />
            </li>
          ))}
        </ul>
      )}

      {showEmpty && (
        <div className={cls.empty}>
          <b>{t('chat.empty.chats.title')}</b>
          <p>{t('chat.empty.chats.text')}</p>
        </div>
      )}

      {isLoading && <Spinner centered color="var(--clr-blue)" />}

      {!isAllLoaded && (
        <InView
          as="div"
          onChange={(visible) => {
            if (visible && !isLoading) {
              fetchData();
            }
          }}
        >
          <div className={cls.observer} />
        </InView>
      )}
    </div>
  );
}
