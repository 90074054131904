import { cn } from '@/utils/cn';

import cls from './Message.module.scss';

type Props = {
  active: boolean;
  top: number;
  height: number;
};

export function MessageHighlight({ active, top, height }: Props) {
  return (
    <div
      className={cn(cls.message_highlight, {
        [cls.message_highlight_active]: active
      })}
      style={{ top: `${top}px`, height: `${height}px` }}
    />
  );
}
