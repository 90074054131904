import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { EmailCodeConfirm } from '@/ui/PhoneConfirm/EmailCodeConfirm/EmailCodeConfirm';
import { cn } from '@/utils/cn';
import { showAlert } from '@/utils/network';
import { MAX_EMAIL_LENGTH, validateEmail } from '@/utils/validate';

import cls from './PhoneConfirm.module.scss';

type Props = {
  onConfirm: (email: string) => void;
  confirmEmailSendCode: (email: string) => Promise<unknown>;
  confirmEmail: (email: string, code: string) => Promise<unknown>;
};

export function EmailConfirm({
  onConfirm,
  confirmEmailSendCode,
  confirmEmail
}: Props) {
  const { t } = useTranslation();

  const [isEmailConfirmOpen, setEmailConfirmOpen] = useState(false);
  const [isEmailConfirmed, setEmailConfirmed] = useState(false);

  // Email
  const [emailTouched, setEmailTouched] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const isEmailVaild = !!email && validateEmail(email);

  const [isCodeSending, setCodeSending] = useState(false);

  const sendCode = async () => {
    setCodeSending(true);

    try {
      await confirmEmailSendCode(email);
      setEmailConfirmOpen(true);
    } catch (error) {
      showAlert({ error });
    } finally {
      setCodeSending(false);
    }
  };

  const handleCofirmModalClose = () => {
    if (isEmailConfirmed) onConfirm(email);

    setEmailConfirmOpen(false);
  };

  return (
    <>
      <div className={cls.root}>
        <div className={cls.singlefield}>
          <Input
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value.trim())}
            onBlur={() => setEmailTouched(true)}
            label={t('auth.email')}
            type="email"
            errorText={
              !isEmailVaild && emailTouched ? t('auth.invalidEmail') : ''
            }
            maxLength={MAX_EMAIL_LENGTH}
          />
        </div>

        {isEmailVaild && (
          <div className={cls.singlefield}>
            <Button
              onClick={sendCode}
              disabled={isCodeSending}
              loading={isCodeSending}
              color="blue"
              variant="tertiary"
              fullWidth
            >
              {t('sendEmailCode')}
            </Button>
          </div>
        )}
      </div>

      <AdaptiveModal
        name="email-confirm"
        isOpen={isEmailConfirmOpen}
        close={handleCofirmModalClose}
        containerClass={cn(cls.modal_container, cls.modal_container_imp)}
      >
        <EmailCodeConfirm
          email={email}
          confirmEmail={confirmEmail}
          confirmEmailSendCode={confirmEmailSendCode}
          close={handleCofirmModalClose}
          isEmailConfirmed={isEmailConfirmed}
          setEmailConfirmed={setEmailConfirmed}
        />
      </AdaptiveModal>
    </>
  );
}
