import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useDarkmode } from '@/modules/theme/useTheme';
import { TransportBrand } from '@/modules/transport/types';
import { Input } from '@/ui/Input/Input';
import { cn } from '@/utils/cn';

import cls from './Brand.module.scss';
import { ChevronIcon } from './icons';

const SHORT_COUNT = 11;

type Props = {
  brands: TransportBrand[];
  brand: Nullable<TransportBrand>;
  onChange: (b: Nullable<TransportBrand>) => void;
  disabled?: boolean;
};

export function Brand({ brands, brand, onChange, disabled }: Props) {
  const { t } = useTranslation();
  const { isDark } = useDarkmode();

  const [showList, setShowList] = useState(!brand);
  const [isShort, setShort] = useState(true);
  const [search, setSearch] = useState(brand?.name || '');

  const sortedBrands = useMemo(
    () => [
      ...brands.filter((brand) => brand?.is_popular),
      ...brands.filter((brand) => !brand?.is_popular)
    ],
    [brands]
  );

  const list = useMemo(() => {
    if (isShort) return sortedBrands.slice(0, SHORT_COUNT);
    if (!search) return sortedBrands;
    const lowSearch = search.toLowerCase().trim();
    return sortedBrands.filter((b) => {
      const name = b.name.toLowerCase();
      const keywords = b.keywords_string?.toLowerCase() || '';
      return (
        name.trim().includes(lowSearch) || keywords.trim().includes(lowSearch)
      );
    });
  }, [sortedBrands, isShort, search]);

  const toggleShort = () => {
    if (!isShort) setSearch('');
    setShort((p) => !p);
  };

  useEffect(() => {
    if (search && isShort) {
      setShort(false);
    }
  }, [search, isShort]);

  useEffect(() => {
    if (showList && brand) {
      setShowList(false);
      setSearch(brand.name);
    } else if (!showList && brand && search !== brand.name) {
      setSearch(brand.name);
    }
  }, [brand, search, showList]);

  const onSearchChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const v = e.currentTarget.value;
      setSearch(v);
      setShowList(true);
      if (brand) onChange(null);
      if (!v && !isShort) {
        setShort(true);
      }
    },
    [brand, isShort, onChange]
  );

  const onBrandClick = (b: TransportBrand) => {
    return () => {
      onChange(b);
      setShowList(false);
      setSearch(b.name);
    };
  };

  return (
    <div className={cls.root}>
      <div>
        <Input
          value={search}
          onChange={onSearchChange}
          label={t('brand')}
          disabled={disabled}
          onClear={search && !brand ? () => setSearch('') : undefined}
        />
        {!brand && search && list.length <= 0 && (
          <span className={cls.input_text}>{t('brandNotFound')}</span>
        )}
      </div>

      {showList && (
        <div className={cls.list_wrap}>
          <ul className={cls.list}>
            {list.map((b) => (
              <li key={b.id}>
                <button
                  className={cls.brand_btn}
                  type="button"
                  onClick={onBrandClick(b)}
                  disabled={disabled}
                >
                  <img
                    src={isDark ? b.dark_mode_photo_url : b.photo_url}
                    alt={b.name}
                  />
                  <span>{b.name}</span>
                </button>
              </li>
            ))}

            {!search && brands.length > SHORT_COUNT && (
              <li
                className={cn(cls.toggler, { [cls.toggler_opened]: !isShort })}
              >
                <button
                  className={cls.brand_btn}
                  type="button"
                  onClick={toggleShort}
                >
                  <span>{t(isShort ? 'allBrands' : 'common.fold')}</span>
                  <ChevronIcon />
                </button>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
