import Tooltip from 'rc-tooltip';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionI } from '@/app/types';
import {
  CheckIcon,
  EditIcon
} from '@/modules/showroom/advert/create/Address/icons';
import { UpdateAdvertContact } from '@/modules/showroom/advert/update/types';
import { ShowroomContact } from '@/modules/showroom/contact/types';
import { Button } from '@/ui/Button/Button';
import { Checkbox } from '@/ui/Checkbox/Checkbox';
import { Select } from '@/ui/Select/Select';
import { SelectRange } from '@/ui/Select/SelectRange';
import { cn } from '@/utils/cn';
import { rcTooltipProps } from '@/utils/consts';
import { minutesToHHMM } from '@/utils/date';

import { defaultCommunicationMethod, hours } from './helpers';
import { RemoveIcon, InfoIcon } from './icons';
import cls from './Phone.module.scss';

export interface ContactType extends ShowroomContact {
  communication_method: UpdateAdvertContact['communication_method'];
}

type Props = {
  list: ContactType[];
  contact: ContactType;
  onRemoveClick: (id: number) => void;
  onContactReplace: (oldId: number, newId: number) => void;
  onContactChange: (contact: ContactType) => void;
  onEditClick: (c: ContactType) => void;
  loading?: boolean;
};

export function PhoneContact({
  list,
  contact,
  onRemoveClick,
  onContactReplace,
  onContactChange,
  onEditClick,
  loading
}: Props) {
  const { t } = useTranslation();

  // List
  const allOptions = useMemo<OptionI[]>(
    () =>
      list.map((c) => ({
        id: String(c.id),
        text: t('common.slashSeparated', { first: c.name, second: c.phone })
      })),
    [list, t]
  );
  const contactsOptions = useMemo<OptionI[]>(
    () => [
      ...list.map((c) => ({
        id: String(c.id),
        text: t('common.slashSeparated', { first: c.name, second: c.phone })
      }))
    ],
    [list, t]
  );
  const contactOption = allOptions.find((opt) => Number(opt.id) === contact.id);
  const onContactOptionChange = useCallback(
    (opt: OptionI) => {
      onContactReplace(contact.id, Number(opt.id));
    },
    [contact.id, onContactReplace]
  );

  // Custom option render
  const selectOptionRender = useCallback(
    (opt: OptionI, closeDropdown: () => void) => {
      const isActive = contactOption?.id === opt.id;

      return (
        <div
          className={cn(cls.select_btn, {
            [cls.select_btn_active]: isActive
          })}
        >
          <button
            className={cls.select_btn_inner}
            type="button"
            onClick={() => {
              closeDropdown();
              onContactOptionChange(opt);
            }}
          />

          <div className={cls.select_btn_icon}>{isActive && <CheckIcon />}</div>
          <span className={cls.select_btn_text}>
            <span>{opt.text}</span>
          </span>
          <button
            type="button"
            disabled={loading}
            onClick={() => {
              closeDropdown();
              const contact = list?.find((c) => c.id === Number(opt.id));
              if (contact) onEditClick(contact);
            }}
          >
            <EditIcon />
          </button>
        </div>
      );
    },
    [contactOption?.id, list, onContactOptionChange, onEditClick, loading]
  );

  // Hours
  const hoursOptions = useMemo<OptionI[]>(
    () =>
      hours.map((h) => ({
        id: String(h),
        text: minutesToHHMM(h * 60)
      })),
    []
  );

  const [after, setAfter] = useState<OptionI>();
  const [before, setBefore] = useState<OptionI>();

  const afterOptions = useMemo(() => {
    const withFormattedText = hoursOptions.map((opt) => ({
      ...opt,
      text: t('common.fromTime', { time: opt.text })
    }));

    if (before) {
      const beforeH = Number(before.id);
      return withFormattedText.filter((o) => Number(o.id) < beforeH);
    }

    return withFormattedText;
  }, [before, hoursOptions, t]);

  const beforeOptions = useMemo(() => {
    const withFormattedText = hoursOptions.map((opt) => ({
      ...opt,
      text: t('common.toTime', { time: opt.text })
    }));

    if (after) {
      const afterH = Number(after.id);
      return withFormattedText.filter((o) => Number(o.id) > afterH);
    }

    return withFormattedText;
  }, [after, hoursOptions, t]);

  const handleAfter = (v: OptionI) => {
    const h = Number(v.id);
    const call_after = h * 60;
    const { communication_method: comm } = contact;

    const newContact: ContactType = {
      ...contact,
      communication_method: {
        ...comm,
        phone: {
          ...comm.phone,
          call_before: contact.call_before,
          call_after
        } as UpdateAdvertContact['communication_method']['phone']
      }
    };

    if (!before) {
      setAfter(v);
      onContactChange(newContact);
    } else if (before) {
      const beforeH = Number(before.id);

      if (h < beforeH) {
        setAfter(v);
        onContactChange(newContact);
      }
    }
  };

  const handleBefore = (v: OptionI) => {
    const h = Number(v.id);
    const call_before = h * 60;
    const { communication_method: comm } = contact;

    const newContact: ContactType = {
      ...contact,
      communication_method: {
        ...comm,
        phone: {
          ...comm.phone,
          call_after: contact.call_after,
          call_before
        } as UpdateAdvertContact['communication_method']['phone']
      }
    };

    if (!after) {
      setBefore(v);
      onContactChange(newContact);
    } else if (after) {
      const afterH = Number(after.id);

      if (h > afterH) {
        setBefore(v);
        onContactChange(newContact);
      }
    }
  };

  useEffect(() => {
    if (!after) {
      const contactMinutes =
        contact.communication_method.phone?.call_after || contact.call_after;
      const opt = afterOptions.find((o) => {
        const currentHourMinutes = Number(o.id) * 60;
        return currentHourMinutes === contactMinutes;
      });
      setAfter(opt);
    }
  }, [after, afterOptions, contact]);

  useEffect(() => {
    if (!before) {
      const contactMinutes =
        contact.communication_method.phone?.call_before || contact.call_before;
      const opt = beforeOptions.find((o) => {
        const currentHourMinutes = Number(o.id) * 60;
        return currentHourMinutes === contactMinutes;
      });
      setBefore(opt);
    }
  }, [before, beforeOptions, contact]);

  // Flags
  const callEnabled = !!contact.communication_method.phone?.available;
  const toggleCheckbox = (value: boolean, key: 'phone' | 'whatsapp') => {
    const r = {
      ...contact,
      communication_method: {
        ...contact.communication_method,
        [key]: contact.communication_method[key]
          ? { ...contact.communication_method[key], available: value }
          : { ...defaultCommunicationMethod[key], available: value }
      }
    };
    onContactChange(r);
  };

  return (
    <div>
      <div className={cls.contact}>
        <div
          className={cls.phone}
          style={{ maxWidth: callEnabled ? 404 : 'unset' }}
        >
          <Select
            name={`${contact.id}-advert-contacts`}
            value={contactOption}
            onChange={onContactOptionChange}
            options={contactsOptions}
            label={t('showroom.contact.title')}
            disabled={contactsOptions.length <= 0}
            renderOption={selectOptionRender}
            dropdownCls={cls.contact_dropdown}
            dropdownFullWidth
            dropdownToLeft
          />
        </div>

        {callEnabled && (
          <div className={cls.time}>
            <SelectRange
              name={`${contact.id}-call-time`}
              from={after}
              onFromChange={handleAfter}
              fromOptions={afterOptions}
              to={before}
              onToChange={handleBefore}
              toOptions={beforeOptions}
              label={t('contacts.calls.time')}
            />
          </div>
        )}

        <div className={cls.remove}>
          <Button
            onClick={() => onRemoveClick(contact.id)}
            variant="secondary"
            color="black"
          >
            <RemoveIcon />
          </Button>
        </div>
      </div>

      <div className={cls.flags}>
        <div className={cls.flag}>
          <Checkbox
            id={`contact-call-${contact.id}`}
            checked={callEnabled}
            onChange={(e) => toggleCheckbox(e.currentTarget.checked, 'phone')}
          >
            {t('contacts.calls.title')}
          </Checkbox>

          <Tooltip
            placement="top"
            overlay={t('contacts.calls.text')}
            overlayClassName={cls.tooltip_overlay}
            {...rcTooltipProps}
          >
            <div className={cls.info}>
              <InfoIcon />
            </div>
          </Tooltip>
        </div>
        <div className={cls.flag}>
          <Checkbox
            id={`contact-whatsapp-${contact.id}`}
            checked={!!contact.communication_method.whatsapp?.available}
            onChange={(e) =>
              toggleCheckbox(e.currentTarget.checked, 'whatsapp')
            }
          >
            {t('contacts.whatsapp.title')}
          </Checkbox>

          <Tooltip
            placement="top"
            overlay={t('contacts.whatsapp.text')}
            overlayClassName={cls.tooltip_overlay}
            {...rcTooltipProps}
          >
            <div className={cls.info}>
              <InfoIcon />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
