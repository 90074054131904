import { useMemo } from 'react';

import {
  isDiscountsValid,
  isPhoneValid,
  isPriceValid,
  isVinValid,
  MAX_DESCR_LEN
} from '@/modules/showroom/advert/create/helpers';
import {
  CreateAdvertSlice,
  SpecsSlice
} from '@/modules/showroom/advert/create/types';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import {
  useUpdateAdvertStore,
  UpdateAdvertStore
} from '@/modules/showroom/advert/update/store';
import { SLUG_NEW } from '@/utils/consts';

type StoreType = CreateAdvertSlice & SpecsSlice & UpdateAdvertStore;

const disableCoreChangesFn = (store: StoreType) => {
  const status = store.detail?.status.title;
  const statuses = [
    AdvertStatusType.moderation,
    AdvertStatusType.rejected,
    AdvertStatusType.published,
    AdvertStatusType.sold
  ];
  if (!store.brand) return false;
  return status ? statuses.includes(status) : false;
};

const isPublishedFn = (store: StoreType) => {
  const status = store.detail?.status.title;
  return status === AdvertStatusType.published;
};

const canPublishFn = (store: StoreType) => {
  const state = store;
  const photos = state.photos;
  const modification = state.modification;

  const type = state.type;
  const isNewCar = type?.slug === SLUG_NEW;
  const owner = state.owner;
  const filledOwner = isNewCar ? true : !!owner;

  const vin = state.vin;
  const addressId = state.addressId;

  const contacts = state.contacts;
  const phoneValid = isPhoneValid(contacts);

  const price = state.price;
  const priceValid = isPriceValid(price);

  const discountAmount = state.discount;
  const discountTypes = state.discountTypes;
  const discountValid = isDiscountsValid(price, discountAmount, discountTypes);

  const description = state.description;
  const descrValid = description.length <= MAX_DESCR_LEN;

  const vinValid = isVinValid(vin);

  const canPublish =
    !!modification &&
    photos.length > 0 &&
    filledOwner &&
    descrValid &&
    vinValid &&
    !!addressId &&
    phoneValid &&
    priceValid &&
    discountValid;

  return canPublish;
};

const showPromoFn = (store: StoreType) => {
  const status = store.detail?.status.title;
  const showPromo = ![
    AdvertStatusType.published,
    AdvertStatusType.moderation,
    AdvertStatusType.rejected
  ].includes(status as AdvertStatusType);
  return showPromo;
};

export function useAdvertUpdateFlags() {
  const store = useUpdateAdvertStore((s) => s);
  const disableCoreChanges = disableCoreChangesFn(store);
  const isPublished = isPublishedFn(store);
  const canPublish = canPublishFn(store);
  const showPromo = showPromoFn(store);

  return useMemo(
    () => ({ disableCoreChanges, isPublished, canPublish, showPromo }),
    [disableCoreChanges, canPublish, isPublished, showPromo]
  );
}
