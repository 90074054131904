import { useState, useCallback, useMemo, useEffect } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import {
  acceptImages,
  getImageUrlFromFile,
  handleImage
} from '@/modules/files/upload/helpers';
import { Props as UploadProps } from '@/modules/files/upload/UploadFiles';
import { Avatar } from '@/ui/Avatar/Avatar';
import { Cropper } from '@/ui/Cropper/Cropper';
import { cn } from '@/utils/cn';
import { mbToBytes } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './UploadAvatar.module.scss';

const MAX_FILE_SIZE_MB = 2;

interface Props extends Pick<UploadProps, 'onUpload'> {
  label?: Nullable<string>;
  crop?: boolean;
  avatarSrc?: string;
  avatarSize?: number;
  imgSize?: number;
  maxFileSizeMb?: number;
  setAvatarUploading?: (v: boolean) => void;
}

export const UploadAvatar: React.FC<Props> = ({
  label,
  onUpload,
  crop,
  avatarSrc,
  avatarSize = 100,
  imgSize,
  maxFileSizeMb = MAX_FILE_SIZE_MB,
  setAvatarUploading
}) => {
  const { t } = useTranslation();

  const [isUploading, setUploading] = useState(false);
  const [isCropOpen, setCropOpen] = useState(false);
  const [cropUrl, setCropUrl] = useState('');

  const upload = useCallback(
    async (file: File) => {
      try {
        const files = [file];
        const promises = files.map((fl) => handleImage({ file: fl }));
        const result = await Promise.all(promises);
        if (onUpload) onUpload(result);
      } catch (error) {
        showAlert({ error });
      } finally {
        setUploading(false);
        setCropOpen(false);
        setCropUrl('');
      }
    },
    [onUpload]
  );

  const onCrop = (blob: Nullable<Blob>) => {
    if (blob) {
      const file = new File([blob], 'cropped-uploaded', {
        type: blob.type
      });
      upload(file);
    } else {
      setCropOpen(false);
      setCropUrl('');
      setUploading(false);
    }
  };

  const handleFiles = useCallback(
    async (files: File[]) => {
      if (isUploading) return;
      setUploading(true);

      const f = files ? Array.from(files) : null;
      if (!f || f.length <= 0) {
        setUploading(false);
        return;
      }

      if (crop) {
        const url = await getImageUrlFromFile(f[0]);

        if (typeof url === 'string') {
          setCropUrl(url);
          setCropOpen(true);
          setUploading(false);
        }
      } else {
        upload(f[0]);
      }
    },
    [isUploading, crop, upload]
  );

  // Dropzone
  const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
    useDropzone({
      maxFiles: 1,
      maxSize: maxFileSizeMb ? mbToBytes(maxFileSizeMb) : undefined,
      multiple: false,
      noClick: true,
      noKeyboard: true,
      onDrop: handleFiles,
      accept: acceptImages
    });

  const acceptFileTypes = useMemo(() => {
    const acceptTypes = acceptImages;
    const mimoTypes = Object.keys(acceptTypes)
      .map((type) => {
        const slashIndex = type.indexOf('/');
        if (slashIndex === -1) return type;

        return type.substring(slashIndex + 1, type.length);
      })
      .join(t('common.listSeparator') || '');

    return mimoTypes;
  }, [t]);

  const showFileRejectError = (fileRejection: FileRejection) => {
    const errorCode = fileRejection.errors[0].code as ErrorCode;

    switch (errorCode) {
      case ErrorCode.FileInvalidType:
        showAlert({
          type: 'error',
          text: t('upload.image.invalidType', { acceptFileTypes })
        });
        break;
      case ErrorCode.FileTooLarge:
        showAlert({
          type: 'error',
          text: t('fileTooLarge', { maxFileSize: maxFileSizeMb })
        });
        break;
      case ErrorCode.TooManyFiles:
        showAlert({
          type: 'error',
          text: t('upload.image.maxLength', { maxLength: 1 })
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (fileRejections.length === 0) return;
    showFileRejectError(fileRejections[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections]);

  useEffect(() => {
    setAvatarUploading && setAvatarUploading(isUploading);
  }, [isUploading, setAvatarUploading]);

  return (
    <div
      className={cn(cls.root, {
        [cls.root_drag]: isDragActive
      })}
      {...getRootProps()}
    >
      <Avatar
        src={avatarSrc || '/img/camera-circle.svg'}
        onClick={open}
        disabled={isUploading}
        size={avatarSize}
        loading={isUploading}
      />
      {label && (
        <button
          className={cls.label}
          type="button"
          onClick={open}
          disabled={isUploading}
        >
          {label}
        </button>
      )}
      <input {...getInputProps()} />

      {crop && (
        <Cropper
          name="upload-avatar-cropper"
          isOpen={isCropOpen}
          close={() => setCropOpen(false)}
          cropperData={{
            src: cropUrl,
            maxWidth: imgSize,
            maxHeight: imgSize,
            loading: isUploading,
            aspect: 1
          }}
          onCrop={onCrop}
        />
      )}
    </div>
  );
};
