import { useTranslation } from 'react-i18next';

import { RentAdvert } from '@/modules/showroom/advert/rent/my/types';
import { cn } from '@/utils/cn';

import cls from './Features.module.scss';
import {
  SeatIcon,
  TransmissionIcon,
  SpeedIcon,
  EngineIcon,
  FuelIcon
} from './icons';

type Props = {
  advert: RentAdvert;
  hovered?: boolean;
};

export function Features({ advert, hovered }: Props) {
  const { t } = useTranslation();

  return (
    <ul className={cn(cls.root, { [cls.root_hovered]: hovered })}>
      {!!advert.count_seats && (
        <li>
          <div className={cls.feature}>
            <SeatIcon />
            <span>{t('countSeatsVal', { value: advert.count_seats })}</span>
          </div>
        </li>
      )}

      {!!advert.modification?.transmission && (
        <li>
          <div className={cls.feature}>
            <TransmissionIcon />
            <span>{advert.modification?.transmission.short_name}</span>
          </div>
        </li>
      )}

      {!!advert.time_to_100 && (
        <li>
          <div className={cls.feature}>
            <SpeedIcon />
            <span>{t('common.seconds', { value: advert.time_to_100 })}</span>
          </div>
        </li>
      )}

      {!!advert.modification?.horse_power && (
        <li>
          <div className={cls.feature}>
            <EngineIcon />
            <span>
              {t('horsepower', {
                value: advert.modification.horse_power?.short_name
              })}
            </span>
          </div>
        </li>
      )}

      {!!advert.fuel_rate && (
        <li>
          <div className={cls.feature}>
            <FuelIcon />
            <span>{t('fuelRate', { value: advert.fuel_rate })}</span>
          </div>
        </li>
      )}
    </ul>
  );
}
