import { useCallback, useEffect, useState } from 'react';

import { axios } from '@/app/api';
import {
  CreateShowroomContactReq,
  GetShowroomContactLangsRes,
  GetShowroomContactsRes,
  PhoneSendCodeChannel,
  UpdateShowroomContactReq
} from '@/modules/showroom/contact/types';
import { showAlert } from '@/utils/network';

const PATH = '/dealers/showrooms/contact';

// Langs
function getShowroomContactLangs(lang: string) {
  return axios.get<GetShowroomContactLangsRes>(
    '/dealers/showrooms/contact-langs/list',
    { headers: { lang } }
  );
}

export function useShowroomContactLangs(
  lang: string
): [GetShowroomContactLangsRes | undefined, boolean, () => Promise<void>] {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetShowroomContactLangsRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getShowroomContactLangs(lang);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [lang]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, loading, load];
}

// Contact
function getShowroomContacts() {
  return axios.get<GetShowroomContactsRes>(PATH);
}

export function useShowroomContacts(
  skip?: boolean
): [GetShowroomContactsRes | undefined, boolean, () => Promise<void>] {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetShowroomContactsRes>();

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const r = await getShowroomContacts();
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!skip) load();
  }, [load, skip]);

  return [data, loading, load];
}

export function createShowroomContact(req: CreateShowroomContactReq) {
  return axios.post<unknown>(PATH, req);
}

export function updateShowroomContact(req: UpdateShowroomContactReq) {
  return axios.patch<unknown>(PATH, req);
}

export function deleteShowroomContact(contact_id: number) {
  return axios.delete<unknown>(PATH, { params: { contact_id } });
}

// Phone / Email confirm
export function confirmMainContactPhoneSendCode(
  phone_number: string,
  channel?: PhoneSendCodeChannel
) {
  return axios.post<unknown>(
    `${PATH}/main/phone/send-code`,
    { phone_number },
    { params: { channel } }
  );
}

export function confirmMainContactPhone(phone_number: string, code: string) {
  return axios.post<unknown>(`${PATH}/main/phone/confirm-phone`, {
    phone_number,
    code
  });
}

export function confirmMainContactEmailSendCode(email: string) {
  return axios.post<unknown>(`${PATH}/main/confirm-email/send-code`, { email });
}

export function confirmMainContactEmail(email: string, code: string) {
  return axios.post<unknown>(`${PATH}/main/confirm-email/do`, { email, code });
}

export function confirmContactPhoneSendCode(
  phone_number: string,
  channel?: PhoneSendCodeChannel
) {
  return axios.post<unknown>(
    `${PATH}/phone/send-code`,
    { phone_number },
    { params: { channel } }
  );
}

export function confirmContactPhone(phone_number: string, code: string) {
  return axios.post<unknown>(`${PATH}/phone/confirm-phone`, {
    phone_number,
    code
  });
}

export function confirmContactEmailSendCode(email: string) {
  return axios.post<unknown>(`${PATH}/confirm-email/send-code`, { email });
}

export function confirmContactEmail(email: string, code: string) {
  return axios.post<unknown>(`${PATH}/confirm-email/do`, { email, code });
}
