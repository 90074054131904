import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import emptyImg from '@/modules/showroom/address/AddressPage/empty.png';
import {
  createShowroomContact,
  deleteShowroomContact,
  updateShowroomContact,
  useShowroomContactLangs,
  useShowroomContacts
} from '@/modules/showroom/contact/api';
import {
  ContactForm,
  ContactFormValues
} from '@/modules/showroom/contact/ContactForm/ContactForm';
import { ContactsList } from '@/modules/showroom/contact/ContactsList/ContactsList';
import { ContactsListSkeleton } from '@/modules/showroom/contact/ContactsList/ContactsListSkeleton';
import { AddIcon } from '@/modules/showroom/contact/icons';
import { ShowroomContact } from '@/modules/showroom/contact/types';
import { Button } from '@/ui/Button/Button';
import { InputSearch } from '@/ui/InputSearch/InputSearch';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { useAvailablePathByRole } from '@/ui/layouts/SidebarLayout/Sidebar/Nav/helpers';
import { Message } from '@/ui/Message/Message';
import { ConfirmModal } from '@/ui/modals/ConfirmModal/ConfirmModal';
import { PageHead } from '@/ui/PageHead/PageHead';
import { Placeholder } from '@/ui/Placeholder/Placeholder';
import { showAlert } from '@/utils/network';

import cls from './ContactsPage.module.scss';
import emptySearchImg from './emptySearch.png';

export function ContactsPage() {
  const { t } = useTranslation();
  const [appLang] = useLang();
  const availablePath = useAvailablePathByRole();

  const [langs] = useShowroomContactLangs(appLang);
  const [list, loading, fetchContacts] = useShowroomContacts();

  // Add
  const [isAddLoading, setAddLoading] = useState(false);
  const [isAddOpen, setAddOpen] = useState(false);
  const onAddClick = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (e) {
      e.preventDefault();
    }

    setAddOpen(true);
  };
  const onAddSubmit = async (values: ContactFormValues) => {
    setAddLoading(true);

    try {
      await createShowroomContact({
        name: values.name,
        avatar_url: values.avatar,
        phone: values.phone,
        email: values.email,
        lang_ids: values.langIds,
        call_after: values.call_after,
        call_before: values.call_before
      });
      fetchContacts();
      setAddOpen(false);
    } catch (error) {
      showAlert({ error });
    } finally {
      setAddLoading(false);
    }
  };

  // Edit
  const [isEditLoading, setEditLoading] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const [editingContact, setEditingContact] =
    useState<Nullable<ShowroomContact>>(null);
  const onEditClick = (contact: ShowroomContact) => {
    setEditingContact(contact);
    setEditOpen(true);
  };
  const onEditSubmit = async (values: ContactFormValues) => {
    if (!editingContact) return;
    setEditLoading(true);

    try {
      const phone =
        values.phone === editingContact.phone ? undefined : values.phone;
      const email =
        values.email === editingContact.email ? undefined : values.email;

      await updateShowroomContact({
        phone,
        email,
        contact_id: editingContact.id,
        name: values.name,
        lang_ids: values.langIds,
        avatar_url: values.avatar,
        call_after: values.call_after,
        call_before: values.call_before
      });
      fetchContacts();
      setEditOpen(false);
      setEditingContact(null);
    } catch (error) {
      showAlert({ error });
    } finally {
      setEditLoading(false);
    }
  };

  // Delete
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deletingContact, setDeletingContact] =
    useState<Nullable<ShowroomContact>>(null);
  const onDeleteClick = (contact: ShowroomContact) => {
    setDeletingContact(contact);
    setDeleteConfirmOpen(true);
  };
  const onDeleteConfirm = async (confirmed: boolean) => {
    if (!deletingContact) return;
    if (!confirmed) {
      setDeleteConfirmOpen(false);
      setDeletingContact(null);
      return;
    }

    setDeleteLoading(true);

    try {
      await deleteShowroomContact(deletingContact.id);
      fetchContacts();
      setDeleteConfirmOpen(false);
      setDeletingContact(null);
    } catch (error) {
      showAlert({ error });
    } finally {
      setDeleteLoading(false);
    }
  };
  const onFormDeleteClick = () => {
    if (editingContact) {
      setEditOpen(false);
      setEditingContact(null);
      onDeleteClick(editingContact);
    }
  };

  // Search
  const [search, setSearch] = useState('');

  const filteredList = useMemo(() => {
    if (!search || !list) return list;
    const searchLow = search.toLowerCase().trim();

    return list.filter((v) => {
      const byName = v.name?.toLowerCase().includes(searchLow);
      const byPhone = v.phone?.toLowerCase().includes(searchLow);
      const byEmail = v.email?.toLowerCase().includes(searchLow);
      const byLangs = v.contact_langs.some((lang) => {
        return (
          lang.name.toLowerCase().includes(searchLow) ||
          lang.title.toLowerCase().includes(searchLow)
        );
      });
      return byName || byPhone || byEmail || byLangs;
    });
  }, [list, search]);

  return (
    <div className={cls.root}>
      <LayoutHeader title={t('contacts.title')} />
      <PageHead title={t('pageTitle', { title: t('contacts.title') })} />

      <div className={cls.msg}>
        <Message
          text={[t('showroom.contacts.info')]}
          closeCookieName="managers-msg"
        />
      </div>

      <div className={cls.search}>
        <InputSearch
          value={search}
          onChange={(value) => setSearch(value)}
          placeholder={t('common.search')}
          compact
          fullWidth
        />

        <Button
          onClick={onAddClick}
          size="compact"
          variant="secondary"
          color="black"
        >
          <AddIcon />
          {t('showroom.contacts.addManager')}
        </Button>
      </div>

      {!list && loading && <ContactsListSkeleton />}

      {list && list.length <= 0 && !loading && (
        <div className={cls.placeholder}>
          <Placeholder
            imgSrc={emptyImg}
            text={t('showroom.contacts.empty')}
            onButtonClick={onAddClick}
            buttonText={t('common.add')}
            to={availablePath}
            buttonColor="blue"
            buttonVariant="secondary"
          />
        </div>
      )}

      {list &&
        list.length > 0 &&
        (!filteredList || filteredList.length === 0) && (
          <div className={cls.placeholder}>
            <Placeholder
              imgSrc={emptySearchImg}
              title={t('showroom.contacts.emptySearchTitle')}
              text={t('showroom.contacts.emptySearchText', { search })}
            />
          </div>
        )}

      {filteredList && filteredList.length > 0 && !loading && (
        <ContactsList
          list={filteredList}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
        />
      )}

      <ContactForm
        langsList={langs || []}
        isOpen={isAddOpen}
        close={() => setAddOpen(false)}
        title={t('showroom.contacts.new')}
        onSubmit={onAddSubmit}
        loading={isAddLoading}
      />

      {editingContact && (
        <ContactForm
          key={editingContact.id}
          isEdit
          langsList={langs || []}
          isOpen={isEditOpen}
          close={() => setEditOpen(false)}
          title={t('showroom.contacts.manager')}
          onSubmit={onEditSubmit}
          onDeleteClick={onFormDeleteClick}
          loading={isEditLoading || isDeleteLoading}
          values={{
            name: editingContact.name,
            phone: editingContact.phone,
            email: editingContact.email || '',
            avatar: editingContact.avatar_url || '',
            langIds: editingContact.contact_langs.map((l) => l.id),
            call_after: editingContact.call_after,
            call_before: editingContact.call_before
          }}
        />
      )}

      {deletingContact && (
        <ConfirmModal
          name="contact-delete"
          isOpen={isDeleteConfirmOpen}
          close={() => setDeleteConfirmOpen(false)}
          title={t('showroom.contacts.deleteConfirm', {
            name: deletingContact.name
          })}
          confirmText={t('common.confirm')}
          cancelText={t('common.cancel')}
          onButtonClick={onDeleteConfirm}
          loading={isDeleteLoading}
        />
      )}
    </div>
  );
}
