import { create } from 'zustand';

import { ClientCommunicationRes } from '@/modules/client-comm/types';
import { CountryCodeType } from '@/modules/country-codes/types';
import { RefreshWeekDay } from '@/modules/refresh/types';
import { BREAKPOINT, MOB_BREAKPOINT } from '@/utils/consts';
import { getCookie, setCookie } from '@/utils/cookie';

const sidebarMinimizedName = 'sidebar-minimized';
const getFromCookie = (name: string): boolean => {
  const fromCookie = getCookie(name);
  return fromCookie ? (JSON.parse(fromCookie) as boolean) : false;
};

interface CommonStore {
  // Sidebar
  isSidebarMinimized: boolean;
  setSidebarMinimized: (isSidebarMinimized: boolean, save?: boolean) => void;
  // Window width
  windowWidth: number;
  setWindowWidth: (windowWidth: number) => void;
  // Callback count
  callbackCountKey: number;
  setCallbackCountKey: (callbackCountKey: number) => void;
  // Country codes
  countryCodes: CountryCodeType[];
  setCountryCodes: (countryCodes: CountryCodeType[]) => void;
  // Week days
  weekDays: RefreshWeekDay[];
  setWeekDays: (weekDays: RefreshWeekDay[]) => void;
  // Refresh price
  refreshPrice: number;
  setRefreshPrice: (refreshPrice: number) => void;
  // Client communication
  clientCommunication: ClientCommunicationRes | undefined;
  setClientCommunication: (
    clientCommunication: ClientCommunicationRes | undefined
  ) => void;
  // Ad pubish
  isAdPublishing: boolean;
  setAdPublishing: (isAdPublishing: boolean) => void;
}

export const useCommonStore = create<CommonStore>((set) => ({
  // Sidebar
  isSidebarMinimized: getFromCookie(sidebarMinimizedName),
  setSidebarMinimized: (isSidebarMinimized: boolean, save?: boolean) => {
    set({ isSidebarMinimized });
    const shouldSave = typeof save === 'boolean' ? save : true;
    if (shouldSave)
      setCookie(sidebarMinimizedName, JSON.stringify(isSidebarMinimized));
  },
  // Window width
  windowWidth: window.innerWidth,
  setWindowWidth: (windowWidth: number) => set({ windowWidth }),
  // Callback count
  callbackCountKey: 0,
  setCallbackCountKey: (callbackCountKey: number) => set({ callbackCountKey }),
  // Country codes
  countryCodes: [],
  setCountryCodes: (countryCodes: CountryCodeType[]) => set({ countryCodes }),
  // Week days
  weekDays: [],
  setWeekDays: (weekDays) => set({ weekDays }),
  // Refresh price
  refreshPrice: 0,
  setRefreshPrice: (refreshPrice) => set({ refreshPrice }),
  // Client communication
  clientCommunication: undefined,
  setClientCommunication: (
    clientCommunication: ClientCommunicationRes | undefined
  ) => set({ clientCommunication }),
  // Ad pubish
  isAdPublishing: false,
  setAdPublishing: (isAdPublishing: boolean) => set({ isAdPublishing })
}));

export function useDesktop(bp?: number): boolean {
  const windowWidth = useCommonStore((state) => state.windowWidth);
  return windowWidth > (bp || BREAKPOINT);
}

export function useMobile(bp?: number): boolean {
  const windowWidth = useCommonStore((state) => state.windowWidth);
  return windowWidth <= (bp || MOB_BREAKPOINT);
}
