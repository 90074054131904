import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import { usePromotionPackages } from '@/modules/promotion/hooks';
import { PromotionPackage } from '@/modules/promotion/types';
import {
  NO_PROMO_ID,
  SCROLLTO
} from '@/modules/showroom/advert/create/helpers';
import { CreateAdvertSlice } from '@/modules/showroom/advert/create/types';
import { Spinner } from '@/ui/Spinner/Spinner';
import { Switch } from '@/ui/Switch/Switch';
import { cn } from '@/utils/cn';
import { ADV_CUR } from '@/utils/consts';
import { numberWithSpaces, pluralKey } from '@/utils/format';

import {
  UncheckIcon,
  CheckIcon,
  OptionIcon,
  CloseIcon,
  ArrowIcon
} from './icons';
import cls from './Promotion.module.scss';

type Props = {
  activePackageId?: Nullable<number>;
  activeLeft?: string;
  promotionPackageId: Nullable<number>;
  setPromotionPackageId: (id: Nullable<number>) => void;
  autoExtension: CreateAdvertSlice['autoExtension'];
  setAutoExtension: CreateAdvertSlice['setAutoExtension'];
  contentOnly?: boolean;
  promotionsList?: PromotionPackage[];
  ignoreAutoextEffect?: boolean;
};

export function Promotion({
  activePackageId,
  activeLeft,
  promotionPackageId,
  setPromotionPackageId,
  autoExtension,
  setAutoExtension,
  contentOnly,
  promotionsList,
  ignoreAutoextEffect
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const skipLoading = !!promotionsList && promotionsList.length > 0;
  const [listData, loading] = usePromotionPackages(skipLoading);
  const list = skipLoading ? promotionsList : listData;
  const [promoPackage, setPackage] = useState<Nullable<PromotionPackage>>(null);

  useEffect(() => {
    if (!ignoreAutoextEffect) setAutoExtension(!!promoPackage);
  }, [ignoreAutoextEffect, promoPackage, setAutoExtension]);

  useEffect(() => {
    if (list && list.length > 0 && !promotionPackageId) {
      setPromotionPackageId(list[0].id);
    }
  }, [list, promotionPackageId, setPromotionPackageId]);

  useEffect(() => {
    if (list && list.length > 0) {
      const p = list.find((p) => p.id === promotionPackageId);
      setPackage(p || null);
    }
  }, [list, promotionPackageId, promoPackage]);

  const freePromo = list?.find((p) => p.id === NO_PROMO_ID);

  return (
    <div className={contentOnly ? '' : cls.root} id={SCROLLTO.promotion}>
      <div className={contentOnly ? '' : 'box'}>
        {!contentOnly && (
          <>
            <h1 className={cls.title}>{t('advertisements.boost.title')}</h1>
            <p className={cls.text}>{t('advertisements.boost.text')}</p>
          </>
        )}

        {loading && <Spinner centered />}

        {list && !loading && (
          <>
            <div className={cn(cls.list_wrap, 'hide-scrollbar')}>
              <ul className={cls.list}>
                {list
                  .filter((p) => p.id !== NO_PROMO_ID)
                  .map((p) => {
                    const isActive = activePackageId === p.id;
                    const isSelected = promotionPackageId === p.id;

                    return (
                      <li key={p.id}>
                        <div
                          className={cn(cls.package, {
                            [cls.package_selected]: isSelected,
                            [cls.package_active]: isActive
                          })}
                        >
                          {isSelected && (
                            <span className={cls.arrow}>
                              <ArrowIcon />
                            </span>
                          )}
                          <button
                            className={cls.package_btn}
                            type="button"
                            onClick={() => setPromotionPackageId(p.id)}
                          />

                          <div className={cls.header}>
                            {p.logo_url && (
                              <img
                                className={cls.icon}
                                src={p.logo_url}
                                alt=""
                              />
                            )}

                            <div>
                              <b className={cls.name}>{p.name}</b>
                              {p.valid_days && (
                                <small className={cls.duration}>
                                  {pluralKey(
                                    p.valid_days,
                                    t('advertisements.boost.daysPlural.one', {
                                      days: p.valid_days
                                    }),
                                    t('advertisements.boost.daysPlural.few', {
                                      days: p.valid_days
                                    }),
                                    t('advertisements.boost.daysPlural.many', {
                                      days: p.valid_days
                                    })
                                  )}
                                </small>
                              )}
                            </div>
                          </div>

                          {p.options && (
                            <ul className={cls.options}>
                              {p.options.map((opt) => (
                                <li key={opt.id}>
                                  <p
                                    className={cn(cls.option, {
                                      [cls.option_included]: opt.include
                                    })}
                                  >
                                    {opt.name}
                                    {opt.include ? (
                                      <OptionIcon />
                                    ) : (
                                      <CloseIcon />
                                    )}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          )}

                          <div className={cls.price_wrap}>
                            <b
                              className={cls.price}
                              style={{
                                color: isActive
                                  ? 'var(--clr-green)'
                                  : 'var(--thm-black)'
                              }}
                            >
                              {isActive
                                ? t('active')
                                : t('priceCurrency', {
                                    price: numberWithSpaces(p.price, lang),
                                    currency: ADV_CUR
                                  })}
                            </b>

                            {isActive && activeLeft ? (
                              <span
                                className={cls.old_price}
                                style={{ textDecoration: 'unset' }}
                              >
                                {activeLeft}
                              </span>
                            ) : (
                              <>
                                {typeof p.old_price === 'number' && (
                                  <span className={cls.old_price}>
                                    {t('priceCurrency', {
                                      price: numberWithSpaces(
                                        p.old_price,
                                        lang
                                      ),
                                      currency: ADV_CUR
                                    })}
                                  </span>
                                )}
                              </>
                            )}

                            {isActive ? (
                              <CheckIcon color="var(--clr-green)" />
                            ) : (
                              <>
                                {isSelected ? <CheckIcon /> : <UncheckIcon />}
                              </>
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>

            {promotionPackageId !== NO_PROMO_ID && (
              <div className={cls.autoext}>
                <div className={cls.autoext_text}>
                  <p>{t('publish.autoext.title')}</p>
                  <small>{t('publish.autoext.subtitle')}</small>
                </div>
                <Switch
                  id="publish-autoext"
                  checked={autoExtension}
                  green
                  readOnly
                />
                <button
                  onClick={() => setAutoExtension(!autoExtension)}
                  type="button"
                />
              </div>
            )}

            {freePromo && (
              <button
                className={cn(cls.free_btn, {
                  [cls.free_btn_selected]: promotionPackageId === freePromo.id
                })}
                type="button"
                onClick={() => setPromotionPackageId(freePromo.id)}
              >
                <span>{freePromo.name}</span>
                <span className={cls.free_btn_row}>
                  <b className={cls.price}>
                    {t('priceCurrency', {
                      price: freePromo.price,
                      currency: ADV_CUR
                    })}
                  </b>
                  {promotionPackageId === freePromo.id ? (
                    <CheckIcon />
                  ) : (
                    <UncheckIcon />
                  )}
                </span>
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
