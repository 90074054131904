import Hls from 'hls.js';
import { useCallback, useEffect, useState } from 'react';

import { Nullable } from '@/app/types';
import { useInterval } from '@/hooks/useInterval';
import { cn } from '@/utils/cn';

import { PlayIcon, PauseIcon, VolumeIcon, MutedIcon } from './icons';
import cls from './ReelPlayer.module.scss';

type Props = {
  reel: { playlist_url: string };
  initial?: boolean;
  muted?: boolean;
  onMuteChange?: React.Dispatch<React.SetStateAction<boolean>>;
  hovered?: boolean;
  active?: boolean;
  autoPlay?: boolean;
  onPlay?: VoidFunction;
};

export function ReelPlayer({
  reel,
  initial,
  muted: mutedProp,
  onMuteChange,
  hovered,
  active,
  autoPlay,
  onPlay
}: Props) {
  // Video
  const [video, setVideo] = useState<Nullable<HTMLMediaElement>>(null);

  // Seek, duration
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const onLoadedMetadata = (
    e: React.SyntheticEvent<HTMLVideoElement, Event>
  ) => {
    setCurrentTime(e.currentTarget.currentTime);
    setDuration(e.currentTarget.duration);
    setPaused(e.currentTarget.paused);
  };

  const handleSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = parseFloat(event.target.value);
    if (video) {
      video.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const handleTimeUpdate = useCallback(() => {
    if (video) {
      setCurrentTime(video.currentTime);
    }
  }, [video]);

  useInterval(handleTimeUpdate, 30);

  // Pause
  const [paused, setPaused] = useState<boolean>(false);
  const togglePause = () => {
    const el = video;
    if (!el) return;
    return paused ? el.play() : el.pause();
  };

  // Mute
  const [muted, setMuted] = useState<boolean>(
    typeof mutedProp === 'boolean' ? mutedProp : !!initial
  );
  const toggleMute = () => {
    const el = video;
    if (!el) return;
    const isMuted = !el.muted;
    el.muted = isMuted;
    setMuted(isMuted);
    onMuteChange?.(isMuted);
  };

  // Init hls
  useEffect(() => {
    if (video && Hls.isSupported()) {
      const hls = new Hls();

      hls.loadSource(reel.playlist_url);
      hls.attachMedia(video);

      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        if (!initial) {
          const m = typeof mutedProp === 'boolean' ? mutedProp : false;
          setMuted(m);
          video.muted = m;
          if (autoPlay) video.play();
        }
      });

      return () => {
        hls.destroy();
      };
    } else if (video && video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = reel.playlist_url;
      if (autoPlay) video.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reel.playlist_url, video]);

  useEffect(() => {
    if (video) {
      video.currentTime = 0;
      if (active) {
        video.play();
        onPlay?.();
      } else {
        video.pause();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, video]);

  return (
    <div
      className={cn(cls.root, {
        [cls.root_hovered]: hovered,
        [cls.root_onhover]: !hovered
      })}
    >
      <video
        id={`reel-video`}
        className={cls.video}
        ref={setVideo}
        onLoadedMetadata={onLoadedMetadata}
        onPlay={() => setPaused(false)}
        onPause={() => setPaused(true)}
        muted={muted}
        controls={false}
        playsInline
        loop
      />

      <button className={cls.cover_btn} type="button" onClick={togglePause} />

      <div className={cls.controls}>
        <button className={cls.control_btn} type="button" onClick={togglePause}>
          {!paused ? <PauseIcon /> : <PlayIcon />}
        </button>

        <button className={cls.control_btn} type="button" onClick={toggleMute}>
          {muted ? <MutedIcon /> : <VolumeIcon />}
        </button>
      </div>

      <div className={cls.duration_wrap}>
        <div className={cls.duration}>
          <div
            className={cls.duration_progress}
            style={{ width: `${(currentTime / duration) * 100}%` }}
          />
          <input
            type="range"
            min={0}
            max={duration}
            value={currentTime}
            step="0.1"
            onChange={handleSeek}
          />
        </div>
      </div>
    </div>
  );
}
