import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionI } from '@/app/types';
import {
  CheckIcon,
  EditIcon
} from '@/modules/showroom/advert/create/Address/icons';
import { ShowroomContact } from '@/modules/showroom/contact/types';
import { Select } from '@/ui/Select/Select';
import { cn } from '@/utils/cn';

import cls from './Phone.module.scss';

type Props = {
  list: ShowroomContact[];
  onAdd: (contactId: number) => void;
  onEditClick: (c: ShowroomContact) => void;
  loading?: boolean;
};

export function PhoneContactAdd({ list, onAdd, onEditClick, loading }: Props) {
  const { t } = useTranslation();

  // List
  const contactsOptions = useMemo<OptionI[]>(
    () => [
      ...list.map((c) => ({
        id: String(c.id),
        text: t('common.slashSeparated', { first: c.name, second: c.phone })
      }))
    ],
    [list, t]
  );
  const onContactOptionChange = useCallback(
    (opt: OptionI) => {
      onAdd(Number(opt.id));
    },
    [onAdd]
  );

  // Custom option render
  const selectOptionRender = useCallback(
    (opt: OptionI, closeDropdown: () => void) => {
      const isActive = false;

      return (
        <div
          className={cn(cls.select_btn, {
            [cls.select_btn_active]: isActive
          })}
        >
          <button
            className={cls.select_btn_inner}
            type="button"
            onClick={() => {
              closeDropdown();
              onContactOptionChange(opt);
            }}
          />

          <div className={cls.select_btn_icon}>{isActive && <CheckIcon />}</div>
          <span className={cls.select_btn_text}>
            <span>{opt.text}</span>
          </span>
          <button
            type="button"
            disabled={loading}
            onClick={() => {
              closeDropdown();
              const contact = list?.find((c) => c.id === Number(opt.id));
              if (contact) onEditClick(contact);
            }}
          >
            <EditIcon />
          </button>
        </div>
      );
    },
    [list, loading, onContactOptionChange, onEditClick]
  );

  return (
    <div className={cn(cls.contact, cls.contact_add)}>
      <div className={cls.phone}>
        <Select
          name={`add-advert-contacts`}
          onChange={onContactOptionChange}
          options={contactsOptions}
          label={t('showroom.contact.choose')}
          renderOption={selectOptionRender}
          dropdownCls={cls.contact_dropdown}
          dropdownFullWidth
          dropdownToLeft
        />
      </div>
    </div>
  );
}
