import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  isPhoneValid,
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { useVrpAdvertUpdateFlags } from '@/modules/showroom/advert/vrp/CreateVrp/hooks';
import { isVrpPriceValid } from '@/modules/showroom/advert/vrp/helpers';
import { useUpdateVrpStore } from '@/modules/showroom/advert/vrp/UpdateVrp/store';
import { EditorCheckList } from '@/ui/layouts/EditorLayout/EditorCheckList/EditorCheckList';

type Props = {
  numberWasSaved: boolean;
};

export function CheckList({ numberWasSaved }: Props) {
  const { t } = useTranslation();
  const { disableCoreChanges } = useVrpAdvertUpdateFlags();

  // Flags
  const isNumberSaved = useUpdateVrpStore((s) => s.isNumberSaved);
  const description = useUpdateVrpStore((s) => s.description);
  const addressId = useUpdateVrpStore((s) => s.addressId);
  const contacts = useUpdateVrpStore((s) => s.contacts);
  const phoneValid = isPhoneValid(contacts);

  const price = useUpdateVrpStore((s) => s.price);
  const priceValid = isVrpPriceValid(price);

  const isDescriptionFilled = useUpdateVrpStore((s) => s.isDescriptionFilled);
  const isPhoneFilled = useUpdateVrpStore((s) => s.isPhoneFilled);
  const isAddressFilled = useUpdateVrpStore((s) => s.isAddressFilled);
  const resetAll = useUpdateVrpStore((s) => s.resetAll);

  const list = useMemo(
    () => [
      {
        id: SCROLLTO.plate,
        text: t('number'),
        checked: isNumberSaved,
        disabled: false
      },
      {
        id: SCROLLTO.descr,
        text: t('advertisements.description.title'),
        checked: !!description,
        disabled: !numberWasSaved
      },
      {
        id: SCROLLTO.showroom,
        text: t('showroom.label'),
        checked: !!addressId,
        disabled: !isDescriptionFilled
      },
      {
        id: SCROLLTO.phone,
        text: t('showroom.contact.multiple'),
        checked: phoneValid,
        disabled: !isAddressFilled
      },
      {
        id: SCROLLTO.price,
        text: t('price'),
        checked: priceValid,
        disabled: !isPhoneFilled
      }
    ],
    [
      addressId,
      description,
      isAddressFilled,
      isDescriptionFilled,
      isNumberSaved,
      isPhoneFilled,
      numberWasSaved,
      phoneValid,
      priceValid,
      t
    ]
  );

  return (
    <EditorCheckList
      list={list}
      onClick={scrollToSection}
      onResetClick={disableCoreChanges ? undefined : () => resetAll()}
    />
  );
}
