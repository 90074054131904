import { useMemo } from 'react';

import { useLang } from '@/hooks/useLang';
import { LANGS_MAP } from '@/modules/i18n';

export const useRTL = () => {
  const [lang] = useLang();

  const isRTL = useMemo(() => lang === LANGS_MAP.ar, [lang]);

  return { isRTL };
};
