import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonObj } from '@/app/types';
import { useAdvertsEffectivenessList } from '@/modules/showroom/advert/search/hooks';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { FilterType } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/helpers';
import { useSearchStore } from '@/modules/showroom/advert/search/store';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { cn } from '@/utils/cn';

import cls from './Filters.module.scss';

export function EffectivenessFilter() {
  const { t } = useTranslation();

  const [isListOpen, setListOpen] = useState(false);

  const adEffectivenessId = useSearchStore((s) => s.adEffectivenessId);
  const setAdEffectivenessId = useSearchStore((s) => s.setAdEffectivenessId);

  const [adEffectivenessIds, adEffectivenessIdsLoadinging] =
    useAdvertsEffectivenessList();
  const effectivenessId = adEffectivenessIds?.find(
    (tp) => tp.id === adEffectivenessId
  );

  const onEffectivenessIdChange = (v: FilterType) => {
    const tp = v as CommonObj;
    setAdEffectivenessId(tp.id);
    setListOpen(false);
  };

  const onEffectivenessIdReset = () => {
    setListOpen(false);
    setAdEffectivenessId(null);
  };

  return (
    <>
      <FilterBtn
        text={t('effectiveness')}
        onClick={() => setListOpen(true)}
        active={isListOpen}
        onResetClick={onEffectivenessIdReset}
        value={effectivenessId?.name}
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_select)}
        name="search-filters-effectiveness"
        isOpen={isListOpen}
        close={() => setListOpen(false)}
        toLeft
        withModal
      >
        <li className="hide-scrollbar">
          <FiltersList
            activeId={adEffectivenessId}
            list={adEffectivenessIds || []}
            loading={adEffectivenessIdsLoadinging}
            onClick={onEffectivenessIdChange}
            search=""
            allButtonText={t('common.anyN')}
            onAllClick={onEffectivenessIdReset}
            selectStyle
          />
        </li>
      </Dropdown>
    </>
  );
}
