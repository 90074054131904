import { useState, useCallback, useEffect } from 'react';

import { axios } from '@/app/api';
import { Nullable } from '@/app/types';
import { ShowroomTariffRes } from '@/modules/showroom/tariff/types';
import { showAlert } from '@/utils/network';

function getTariff(category_id: number) {
  return axios.get('/dealers/showrooms/tariff', {
    params: { category_id }
  });
}

export function useTariff(
  category_id: Nullable<number>
): [ShowroomTariffRes | undefined, boolean] {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ShowroomTariffRes | undefined>();

  const load = useCallback(async () => {
    if (!category_id) {
      setData(undefined);
      return;
    }

    setLoading(true);
    try {
      const r = await getTariff(category_id);
      setData(r.data);
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  }, [category_id]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, isLoading];
}
