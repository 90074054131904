import { useMemo } from 'react';
import ReactTimeAgo from 'react-timeago';
// @ts-ignore
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
// @ts-ignore
import enStrings from 'react-timeago/lib/language-strings/en';
// @ts-ignore
import ruStrings from 'react-timeago/lib/language-strings/ru';

import { useLang } from '@/hooks/useLang';
import { LANGS_MAP } from '@/modules/i18n';

// i18nADD
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const map: Record<string, any> = {
  [LANGS_MAP.ru]: ruStrings,
  [LANGS_MAP.en]: enStrings,
  [LANGS_MAP.ar]: enStrings
};

type Props = {
  date: Date;
};

export function TimeAgo({ date }: Props) {
  const [lang] = useLang();
  const formatter = useMemo(() => buildFormatter(map[lang]), [lang]);
  return <ReactTimeAgo date={date} formatter={formatter} />;
}
