import { cn } from '@/utils/cn';

import cls from './ToggleButton.module.scss';

export interface ToggleButtonProps {
  value: number;
  isSelected?: boolean;
  onToggle?: (value: number) => void;
  children: React.ReactNode;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  value,
  isSelected = false,
  onToggle,
  children
}) => {
  return (
    <button
      className={cn(cls.button, { [cls.button_selected]: isSelected })}
      onClick={() => onToggle?.(value)}
    >
      <p>{children}</p>
    </button>
  );
};
