import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCommonStore } from '@/app/common-store';
import { Nullable } from '@/app/types';
import { usePromotionPackages } from '@/modules/promotion/hooks';
import { PromotionPackage } from '@/modules/promotion/types';
import { NO_PROMO_ID } from '@/modules/showroom/advert/create/helpers';
import { PublishResult } from '@/modules/showroom/advert/create/PublishResult/PublishResult';
import { RENT_ADVERTS_URL } from '@/modules/showroom/advert/my/helpers';
import {
  getRentAuthorDetail,
  updateRentAdvertisement
} from '@/modules/showroom/advert/rent/api';
import { useRentAdvertPromoUpdate } from '@/modules/showroom/advert/rent/hooks';
import { RentAdvertStatusId } from '@/modules/showroom/advert/rent/my/types';
import { createRentTariff } from '@/modules/showroom/advert/rent/tariffs/api';
import {
  makeRentUpdateAdvertReq,
  sortTariffs
} from '@/modules/showroom/advert/rent/update/helpers';
import { useRentAdvertUpdateFlags } from '@/modules/showroom/advert/rent/update/hooks';
import { useRentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store';
import { UpdateRentAdvertReq } from '@/modules/showroom/advert/rent/update/types';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { _isEqual } from '@/modules/showroom/advert/update/compare-req';
import { showAlert } from '@/utils/network';

type Props = {
  buttonsOnly?: boolean;
  hideDraft?: boolean;
  hidePublish?: boolean;
  disablePublish?: boolean;
};

export function PublishResultWrap({
  buttonsOnly,
  hideDraft,
  hidePublish,
  disablePublish
}: Props) {
  const { t } = useTranslation();

  // Promo
  const store = useRentUpdateAdvertStore((s) => s);
  const {
    promotionPackageId: promoId,
    stickers,
    detail,
    isDescrGenerating,
    autoExtension,
    setLastSectionVisible,
    setShowPageLeaveWarn
  } = store;

  const detailStickersIds =
    detail && detail.stickers ? detail.stickers.map((s) => s.id) : [];
  const newStickersIds = stickers.filter(
    (sId) => !detailStickersIds.includes(sId)
  );

  const { canPublish } = useRentAdvertUpdateFlags();

  const { updateAutoExtension, updatePromoPackage, updatePromoStickers } =
    useRentAdvertPromoUpdate();

  const [list] = usePromotionPackages();
  const [promoPackage, setPackage] = useState<Nullable<PromotionPackage>>(null);

  useEffect(() => {
    if (list && list.length > 0) {
      const p = list.find((p) => p.id === promoId);
      setPackage(p || null);
    }
  }, [list, promoId, promoPackage]);

  // Submit
  const noPromo = !!hideDraft;
  const navigate = useNavigate();

  const loading = useCommonStore((s) => s.isAdPublishing);
  const setLoading = useCommonStore((s) => s.setAdPublishing);
  const onSubmitClick = async (shouldPublish: boolean) => {
    if (!detail) return;
    setLoading(true);
    setShowPageLeaveWarn(false);

    try {
      const r = await getRentAuthorDetail(detail.id);
      const req: UpdateRentAdvertReq = {
        status_id: shouldPublish
          ? RentAdvertStatusId.published
          : RentAdvertStatusId.draft,
        ...makeRentUpdateAdvertReq(store, r.data)
      };

      const tariffsSame = _isEqual(
        sortTariffs(detail.tariffs || []),
        sortTariffs(store.tariffs)
      );
      let tariff_ids = undefined;
      if (!tariffsSame) {
        const promises = store.tariffs.map((tf) => createRentTariff(tf));
        const results = await Promise.all(promises);
        tariff_ids = results.map((r) => r.data).map((tf) => tf.id);
      }
      const updateRes = await updateRentAdvertisement(detail.id, {
        ...req,
        tariff_ids
      });

      if (shouldPublish && !noPromo) {
        await updateAutoExtension(detail.id, autoExtension);

        if (promoPackage?.id)
          await updatePromoPackage(detail.id, promoPackage?.id);

        if (stickers.length > 0)
          await updatePromoStickers(detail.id, {
            stickers_ids: stickers
          });
      }

      const s = updateRes.data.status.title;
      const isModeration = s === AdvertStatusType.moderation;
      const isDraft = s === AdvertStatusType.draft;
      if (isModeration)
        showAlert({ type: 'success', text: t('publish.onModeration') });
      if (isDraft) showAlert({ type: 'success', text: t('publish.drafted') });

      store.resetAll(true);
      navigate(RENT_ADVERTS_URL);
    } catch (error) {
      showAlert({ error });
      setShowPageLeaveWarn(true);
    } finally {
      setLoading(false);
    }
  };

  const saveAd = async (redirect?: boolean) => {
    if (!detail) return;
    setLoading(true);
    try {
      const r = await getRentAuthorDetail(detail.id);
      const req: UpdateRentAdvertReq = {
        ...makeRentUpdateAdvertReq(store, r.data)
      };

      const tariffsSame = _isEqual(
        sortTariffs(detail.tariffs || []),
        sortTariffs(store.tariffs)
      );
      let tariff_ids = undefined;
      if (!tariffsSame) {
        const promises = store.tariffs.map((tf) => createRentTariff(tf));
        const results = await Promise.all(promises);
        tariff_ids = results.map((r) => r.data).map((tf) => tf.id);
      }
      await updateRentAdvertisement(detail.id, {
        ...req,
        tariff_ids
      });
      if (redirect) {
        showAlert({ type: 'success', text: t('common.saved') });
        navigate(RENT_ADVERTS_URL);
      }
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PublishResult
      categoryId={null}
      promoPackageName={promoPackage?.name || t('advertisements.boost.without')}
      promoPackagePrice={promoPackage?.price || 0}
      promoPackageId={promoPackage?.id || NO_PROMO_ID}
      stickersCount={newStickersIds?.length || 0}
      onClick={() => onSubmitClick(true)}
      saveAd={saveAd}
      onSaveClick={hideDraft ? undefined : () => onSubmitClick(false)}
      loading={loading}
      disabled={loading || isDescrGenerating}
      buttonsOnly={buttonsOnly}
      hidePublish={hidePublish}
      disablePublish={disablePublish || !canPublish}
      publishText={noPromo ? (t('publish.do') as string) : ''}
      setLastSectionVisible={setLastSectionVisible}
    />
  );
}
