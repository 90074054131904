import { useTranslation } from 'react-i18next';

import { ShowroomAddress } from '@/modules/showroom/address/types';
import { useDarkmode } from '@/modules/theme/useTheme';
import { Button } from '@/ui/Button/Button';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { useFormatPhone } from '@/ui/PhoneInput/helpers';
import { cn } from '@/utils/cn';
import { minutesToHHMM } from '@/utils/date';
import { removeSpaces } from '@/utils/format';

import cls from './AddressCard.module.scss';
import { DeleteIcon, PinIcon } from './icons';

type Props = {
  address: ShowroomAddress;
  onEditClick: (address: ShowroomAddress) => void;
  onDeleteClick: (address: ShowroomAddress) => void;
};

export function AddressCard({ address, onEditClick, onDeleteClick }: Props) {
  const { t } = useTranslation();
  const { isDark } = useDarkmode();
  const snapshot =
    isDark && address.snapshot_url_dark
      ? address.snapshot_url_dark
      : address.snapshot_url;
  const formattedPhone = useFormatPhone(removeSpaces(address.phone || ''));

  return (
    <div className={cls.root}>
      <div className={cls.img}>
        <ImgSkeleton
          className={cls.img_snap}
          src={snapshot}
          alt=""
          imgProxyWidth={200}
          imgProxyHeight={150}
        />
        <PinIcon />
      </div>

      <div className={cls.info}>
        <div className={cls.header}>
          <div className={cls.title}>
            <h2>
              <b>{address.name}</b>
            </h2>
            <p>{address.address}</p>
          </div>

          <div className={cn(cls.actions, cls.actions_desktop)}>
            <Button
              size="compact"
              variant="secondary"
              color="black"
              onClick={() => onEditClick(address)}
            >
              {t('common.change')}
            </Button>
            <div className={cls.delete}>
              <Button
                size="compact"
                variant="secondary"
                color="black"
                onClick={() => onDeleteClick(address)}
              >
                <DeleteIcon />
              </Button>
            </div>
          </div>
        </div>

        <div className={cls.footer}>
          {formattedPhone && (
            <div className={cls.phone}>
              <p>{t('phone')}</p>
              <b>{formattedPhone}</b>
            </div>
          )}

          <div className={cls.work_time}>
            <p>{t('workingHours')}</p>
            <b>
              {t('common.fromTime', {
                time: minutesToHHMM(address.working_hours_from)
              })}
              {t('common.toTime', {
                time: minutesToHHMM(address.working_hours_up_to)
              })}
            </b>
          </div>
        </div>
      </div>

      <div className={cn(cls.actions, cls.actions_mobile)}>
        <Button
          size="compact"
          variant="secondary"
          color="black"
          onClick={() => onEditClick(address)}
          fullWidth
        >
          {t('common.change')}
        </Button>
        <Button
          size="compact"
          variant="secondary"
          color="black"
          onClick={() => onDeleteClick(address)}
          fullWidth
        >
          {t('common.delete')}
        </Button>
      </div>
    </div>
  );
}
