import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AdEffectivenessColorMap } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/helpers';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { cn } from '@/utils/cn';

import cls from './AdEffectiveness.module.scss';

type Props = {
  adEffectiveness: MyAdvert['ad_effectiveness'];
  showInfoDropdown?: boolean;
};

export const AdEffectiveness: React.FC<Props> = ({
  adEffectiveness,
  showInfoDropdown
}) => {
  const { t } = useTranslation();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);

  const effectiveness = useMemo(
    () => [
      {
        effectivenessId: 1,
        level: 1,
        bgColor: 'var(--clr-red-15)',
        primaryColor: AdEffectivenessColorMap[1],
        infoTitle: t('performance.badPerformanceTitle'),
        infoText: (
          <Trans
            t={t}
            i18nKey={'performance.badPerformanceText'}
            components={{ b: <b /> }}
          />
        )
      },
      {
        effectivenessId: 2,
        level: 2,
        bgColor: 'var(--clr-orange-15)',
        primaryColor: AdEffectivenessColorMap[2],
        infoTitle: t('performance.normalPerformanceTitle'),
        infoText: (
          <Trans
            t={t}
            i18nKey={'performance.normalPerformanceText'}
            components={{ b: <b /> }}
          />
        )
      },
      {
        effectivenessId: 3,
        level: 3,
        bgColor: 'var(--clr-green-15)',
        primaryColor: AdEffectivenessColorMap[3],
        infoTitle: t('performance.goodPerformanceTitle'),
        infoText: (
          <Trans
            t={t}
            i18nKey={'performance.goodPerformanceText'}
            components={{ b: <b /> }}
          />
        )
      }
    ],
    [t]
  );

  const currentEffectivenessLevel = useMemo(() => {
    return effectiveness.find(
      (effectivenessLevel) =>
        effectivenessLevel.effectivenessId === adEffectiveness?.id
    );
  }, [adEffectiveness, effectiveness]);

  const handleMouseEnter = () => {
    const timeout = setTimeout(() => {
      setDropdownOpen(true);
    }, 1000);

    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
    }
    setDropdownOpen(false);
  };

  useEffect(() => {
    return () => {
      if (hoverTimeout) {
        clearTimeout(hoverTimeout);
      }
    };
  }, [hoverTimeout]);

  if (!currentEffectivenessLevel) return <></>;

  return (
    <div className={cls.wrap}>
      <div
        style={
          {
            '--level-bg': currentEffectivenessLevel.bgColor,
            '--level-primary': currentEffectivenessLevel.primaryColor
          } as CSSProperties
        }
        className={cls.root}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <p>{adEffectiveness?.name}</p>
        <div className={cls.levels_container}>
          {effectiveness.map((effectivenessLevel) => (
            <div
              key={effectivenessLevel.effectivenessId}
              className={cn(cls.level, {
                [cls.active]:
                  currentEffectivenessLevel.level >= effectivenessLevel.level
              })}
            />
          ))}
        </div>
      </div>

      {showInfoDropdown && (
        <Dropdown
          name="performance-modal"
          isOpen={isDropdownOpen}
          close={() => setDropdownOpen(false)}
          className={cls.dropdown}
          anchorClassName={cls.dropdown_anchor}
          position="top-center"
          verticalOffset={24}
        >
          <div className={cls.info}>
            <AdEffectiveness adEffectiveness={adEffectiveness} />
            <h3>{currentEffectivenessLevel.infoTitle}</h3>
            <p>{currentEffectivenessLevel.infoText}</p>
          </div>
        </Dropdown>
      )}
    </div>
  );
};
