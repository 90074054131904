/* eslint-disable import/no-duplicates */

import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDesktop } from '@/app/common-store';
import { Nullable } from '@/app/types';
import { useDocumentClick } from '@/hooks/useDocumentClick';
import { DateTimePicker } from '@/ui/DateTimePicker/DateTimePicker';
import dropdownCls from '@/ui/Dropdown/Dropdown.module.scss';
import { BottomModal } from '@/ui/modals/BottomModal/BottomModal';
import { cn } from '@/utils/cn';
import { escapePlus } from '@/utils/format';

import cls from './DropdownRangePicker.module.scss';
import { getDatesByType, RangeType } from './helpers';

type Props = {
  isOpen: boolean;
  close: () => void;
  from: Nullable<Date>;
  to: Nullable<Date>;
  onDatesChange: (from: Nullable<Date>, to: Nullable<Date>) => void;
  parentCls?: string;
};

export function DropdownRangePicker({
  from,
  to,
  onDatesChange,
  isOpen,
  close,
  parentCls
}: Props) {
  const { t } = useTranslation();

  const isDesktop = useDesktop();
  const rootRef = useRef<HTMLDivElement>(null);

  useDocumentClick((e) => {
    if (!isDesktop) return;

    const el = e.target as HTMLElement | null;
    const parentEl = parentCls
      ? el?.closest(`.${escapePlus(parentCls)}`)
      : null;

    const clickedRootInParent = parentEl
      ? parentEl.querySelector(`.${escapePlus(cls.root)}`)
      : null;
    const clickedRangeRoot = parentEl
      ? parentEl.querySelector(`.${escapePlus(dropdownCls.root)}`)
      : null;
    const clickedRoot = clickedRootInParent || clickedRangeRoot;
    const thisRoot = rootRef?.current;

    if ((!!clickedRoot && clickedRoot !== thisRoot) || !parentEl) {
      close();
    }
  });

  const handleDatesChange = (dates: Array<Nullable<Date>>) => {
    const [start, end] = dates;
    onDatesChange(start, end);
  };

  const onButtonClick = (type: RangeType) => {
    return () => {
      const dates = getDatesByType(type, from);
      if (dates) {
        handleDatesChange(dates);
      }
    };
  };

  const jsx = (
    <div
      ref={rootRef}
      className={cn(cls.root, {
        [cls.root_open]: isOpen,
        [cls.root_modal]: !isDesktop
      })}
    >
      <div className={cls.inner}>
        <div className={cls.buttons_wrap}>
          <ul className={cls.buttons}>
            <li>
              <button type="button" onClick={onButtonClick(RangeType.today)}>
                {t('dates.today')}
              </button>
            </li>
            <li>
              <button type="button" onClick={onButtonClick(RangeType.week)}>
                {t('dates.week')}
              </button>
            </li>
            <li>
              <button type="button" onClick={onButtonClick(RangeType.twoWeeks)}>
                {t('dates.twoWeeks')}
              </button>
            </li>
            <li>
              <button type="button" onClick={onButtonClick(RangeType.month)}>
                {t('dates.month')}
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={onButtonClick(RangeType.threeMonths)}
              >
                {t('dates.threeMonths')}
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={onButtonClick(RangeType.monthToDate)}
              >
                {t('dates.monthToDate')}
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={onButtonClick(RangeType.yearToDate)}
              >
                {t('dates.yearToDate')}
              </button>
            </li>
          </ul>
        </div>

        <div className={cls.picker}>
          <DateTimePicker
            date={from}
            endDate={to}
            onDatesChange={handleDatesChange}
            inline
          />
        </div>
      </div>
    </div>
  );

  if (!isDesktop)
    return (
      <BottomModal name="datetime-picker" isOpen={isOpen} close={close}>
        {jsx}
      </BottomModal>
    );

  return jsx;
}
