import { useContext, useEffect } from 'react';
import { InView } from 'react-intersection-observer';

import { useAccountStore } from '@/modules/accounts/store';
import { MyAdvertsContext } from '@/modules/showroom/advert/my/MyAdverts/my-adverts-ctx';
import { useRentSearchAdvertsPg } from '@/modules/showroom/advert/rent/my/hooks';
import {
  MyRentAdvertsList,
  MyRentAdvertsListSkeleton
} from '@/modules/showroom/advert/rent/my/MyRentAdvertsList/MyRentAdvertsList';
import {
  GetRentAdvertsReq,
  RentAdvertExistedStatus
} from '@/modules/showroom/advert/rent/my/types';
import cls from '@/modules/showroom/advert/search/SearchAdverts/SearchResult/SearchResult.module.scss';
import { SearchResultEmpty } from '@/modules/showroom/advert/search/SearchAdverts/SearchResult/SearchResultEmpty';
import { AdvertStatusType } from '@/modules/showroom/advert/types';

type Props = {
  status: RentAdvertExistedStatus;
  req: GetRentAdvertsReq;
  categoryId: number;
  onResetClick: () => void;
  selectedParamsCount: number;
  searchQuery?: string;
};

export function RentSearchResult({
  status,
  req,
  categoryId,
  onResetClick,
  selectedParamsCount,
  searchQuery
}: Props) {
  const { refresh, setNotFoundAds } = useContext(MyAdvertsContext);

  const { isAllLoaded, isLoading, isLoadingRef, ads, load } =
    useRentSearchAdvertsPg(req, status, searchQuery);
  const noAds = !isLoading && !!ads && ads.length <= 0;

  useEffect(() => {
    setNotFoundAds(noAds);
  }, [noAds, setNotFoundAds]);

  useEffect(() => {
    if (!isLoadingRef.current) {
      load(0);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPaginatedData = () => {
    if (isAllLoaded || isLoading) return;
    load(ads ? ads.length : 0);
  };

  const fetchBalance = useAccountStore((s) => s.fetchBalance);
  const onPublishToggle = () => {
    refresh();
    window.setTimeout(fetchBalance, 500);
  };

  return (
    <div className={cls.root}>
      {ads && ads.length > 0 && (
        <MyRentAdvertsList list={ads} onPublishToggle={onPublishToggle} />
      )}

      {isLoading && <MyRentAdvertsListSkeleton />}

      {noAds && (
        <SearchResultEmpty
          onResetClick={onResetClick}
          categoryId={categoryId}
          noPublished={
            status?.status === AdvertStatusType.published &&
            ads &&
            ads.length <= 0 &&
            selectedParamsCount <= 0 &&
            !searchQuery
          }
        />
      )}

      {!isAllLoaded && (
        <InView
          as="div"
          onChange={(visible) => {
            if (visible && !isLoading) {
              fetchPaginatedData();
            }
          }}
        >
          <div className={cls.observer} />
        </InView>
      )}
    </div>
  );
}
