import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { EngagementByDaySmall, Nullable } from '@/app/types';
import {
  getEngagementStatsSummary,
  getRentEngagementStatsSummary
} from '@/modules/overview/api';
import {
  GetStatsEngagementReq,
  GetStatsEngagementRes
} from '@/modules/overview/types';
import { showAlert } from '@/utils/network';

export function useEngagementStats(
  params: GetStatsEngagementReq,
  lang: string
): [GetStatsEngagementRes | undefined, boolean] {
  const abortRef = useRef<Nullable<AbortController>>(
    new window.AbortController()
  );
  const [data, setData] = useState<GetStatsEngagementRes>();
  const [loading, setLoading] = useState(true);

  const load = useCallback(
    async (
      prms: GetStatsEngagementReq,
      lng: string,
      abort: Nullable<AbortController>
    ) => {
      setLoading(true);

      try {
        const saleRes = await getEngagementStatsSummary(
          prms,
          lng,
          abort || undefined
        );
        const rentRes = await getRentEngagementStatsSummary(
          prms,
          lng,
          abort || undefined
        );

        const saleResData = saleRes.data;
        const rentResData = rentRes.data;

        const merged: GetStatsEngagementRes = {
          shows: [...(saleResData.shows || []), ...(rentResData.shows || [])],
          views: [...(saleResData.views || []), ...(rentResData.views || [])],
          leads: [...(saleResData.leads || []), ...(rentResData.leads || [])],
          views_trend: saleResData.views_trend + rentResData.views_trend,
          shows_trend: saleResData.shows_trend + rentResData.shows_trend,
          leads_trend: saleResData.leads_trend + rentResData.leads_trend,
          count_leads_by_lead_type: {
            phone:
              (saleResData.count_leads_by_lead_type.phone || 0) +
              (rentResData.count_leads_by_lead_type.phone || 0),
            chat:
              (saleResData.count_leads_by_lead_type.chat || 0) +
              (rentResData.count_leads_by_lead_type.chat || 0),
            whatsapp:
              (saleResData.count_leads_by_lead_type.whatsapp || 0) +
              (rentResData.count_leads_by_lead_type.whatsapp || 0),
            summary:
              (saleResData.count_leads_by_lead_type.summary || 0) +
              (rentResData.count_leads_by_lead_type.summary || 0)
          }
        };
        setData(merged);
        setLoading(false);
      } catch (error) {
        // @ts-ignore
        const isCanceled = !!error && error.message === 'canceled';
        if (!isCanceled) {
          showAlert({ error });
          setLoading(false);
        }
      }
    },
    []
  );

  useEffect(() => {
    if (abortRef.current) abortRef.current.abort();
    const ab = new AbortController();
    abortRef.current = ab;
    load(params, lang, ab);
  }, [lang, load, params]);

  useEffect(() => {
    return () => {
      if (abortRef.current) abortRef.current.abort();
    };
  }, []);

  return [data, loading];
}

export function useMapEngagementDays(
  data: EngagementByDaySmall[],
  datesRange?: [Date, Date]
) {
  const mapped = useMemo(
    () => fillMissingDays(data, datesRange),
    [data, datesRange]
  );
  return mapped;
}

function fillMissingDays(
  data: EngagementByDaySmall[],
  datesRange?: [Date, Date]
) {
  const result: EngagementByDaySmall[] = [];

  const datesRangeFrom = datesRange
    ? new Date(datesRange[0].setHours(0, 0, 0, 0))
    : null;
  const datesRangeTo = datesRange
    ? new Date(datesRange[1].setHours(0, 0, 0, 0))
    : null;

  const startDate =
    datesRangeFrom ||
    new Date(new Date(data[0].date * 1000).setHours(0, 0, 0, 0));
  const endDateObj =
    datesRangeTo ||
    new Date(new Date(data[data.length - 1].date * 1000).setHours(0, 0, 0, 0));

  while (startDate <= endDateObj) {
    const currentTimestamp = startDate.getTime();
    const existingData = data.find((item) => {
      const itemDate = new Date(item.date * 1000).setHours(0, 0, 0, 0);
      return itemDate === currentTimestamp;
    });

    if (existingData) {
      result.push(existingData);
    } else {
      result.push({ date: currentTimestamp / 1000, count: 0 });
    }

    startDate.setDate(startDate.getDate() + 1);
  }

  return result;
}
