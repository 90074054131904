import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLang } from '@/hooks/useLang';
import { ChatAdName } from '@/modules/chat/ChatList/ChatCard/ChatAdName';
import {
  getDocName,
  handleText,
  isDocContent,
  isImageContent,
  isLocationContent,
  isVideoContent
} from '@/modules/chat/Detail/Messages/Message/helpers';
import { useAdvertInfo } from '@/modules/chat/hooks';
import { ChatMessageAuthor, ChatSimple } from '@/modules/chat/types';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { TimerLabel } from '@/ui/TimerLabel/TimerLabel';
import { cn } from '@/utils/cn';
import { ADV_CUR } from '@/utils/consts';
import { getMessageDateString } from '@/utils/date';
import { numberWithSpaces } from '@/utils/format';

import cls from './ChatCard.module.scss';
import { ChatImage } from './ChatImage';
import { OfferIcon, ReadIcon, UnreadIcon } from './icons';

type Props = {
  chat: ChatSimple;
  onClick?: () => void;
  link?: boolean;
  active?: boolean;
};

export function ChatCard({ chat, onClick, link, active }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const { ad, companion, last_message, unread_count } = chat;

  const advertInfo = useAdvertInfo(ad);

  const isOwnMessage = last_message?.author === ChatMessageAuthor.my;
  const isRead = last_message?.is_read;
  const isOfferMessage = !!ad?.discount_offer;

  const msgText = last_message?.text || '';
  const firstContent =
    !!last_message && !!last_message.content && last_message.content.length > 0
      ? last_message.content[0]
      : null;
  const isImage = firstContent ? isImageContent(firstContent.type) : false;
  const isVideo = firstContent ? isVideoContent(firstContent.type) : false;
  const isDoc = firstContent ? isDocContent(firstContent.type) : false;
  const isLoc = firstContent ? isLocationContent(firstContent.type) : false;

  const imgSrc = firstContent
    ? firstContent.thumbnail_url || firstContent.url
    : '';

  const date = useMemo(
    () => (last_message ? new Date(last_message.created_at * 1000) : null),
    [last_message]
  );

  const showTop = isOwnMessage || !!date || !!(companion && companion.name);

  const offerPrice = useMemo(() => {
    if (!ad || !ad.discount_offer) return 0;

    const discountOffer = ad.discount_offer;
    return discountOffer.discount_amount_aed
      ? ad.price - discountOffer.discount_amount_aed
      : Math.ceil(
          ad.price -
            (ad.price / 100) * (discountOffer.discount_amount_percent || 0)
        );
  }, [ad]);

  return (
    <div
      className={cn(cls.root, {
        [cls.root_active]: active,
        [cls.root_offer]: isOfferMessage
      })}
    >
      {link && <Link className={cls.link} to={`/chat/${chat.id}`} />}
      {onClick && (
        <button className={cls.link} type="button" onClick={onClick} />
      )}

      <ChatImage chat={chat} />

      <div className={cls.wrapper}>
        {showTop && ad && (
          <div className={cls.top}>
            <p className={cls.ad_name}>
              <ChatAdName advert={ad} />
              {ad.status?.title !== AdvertStatusType.published && (
                <span className={cls.ad_status}>{ad.status?.name}</span>
              )}
            </p>

            <div
              className={cls.read_icon}
              style={{ visibility: isOwnMessage ? 'visible' : 'hidden' }}
            >
              {isRead ? <ReadIcon /> : <UnreadIcon />}
            </div>

            {date && (
              <p className={cls.date}>{getMessageDateString(date, lang)}</p>
            )}
          </div>
        )}

        <div className={cls.content}>
          <div className={cls.text}>
            {ad && (
              <>
                {!!offerPrice && (
                  <ul className={cls.offer}>
                    <li className={cls.offer_price}>
                      <OfferIcon />
                      {t('priceCurrency', {
                        price: numberWithSpaces(offerPrice, lang),
                        currency: ADV_CUR
                      })}
                    </li>
                    {ad.discount_offer?.expired_at_timestamp && (
                      <li>
                        <TimerLabel
                          targetDate={ad.discount_offer.expired_at_timestamp}
                        />
                      </li>
                    )}
                  </ul>
                )}

                {!offerPrice && (
                  <ul className={cls.stats}>
                    {advertInfo.map((row, i) => (
                      <li key={i}>{row}</li>
                    ))}
                  </ul>
                )}
              </>
            )}

            {msgText && (
              <div className={cls.last_msg}>
                <p className={cls.sender}>
                  {t('common.colonSeparated', {
                    first: isOwnMessage ? t('chat.you') : companion?.name,
                    second: ''
                  })}
                </p>
                <p
                  className={cls.trunc}
                  dangerouslySetInnerHTML={{
                    __html: handleText(msgText, true)
                  }}
                />
              </div>
            )}

            {!msgText && firstContent && (
              <>
                {isImage && imgSrc && (
                  <p className={cls.preview_img_text}>
                    <ImgSkeleton
                      className={cls.preview_img}
                      src={imgSrc}
                      width={20}
                      height={20}
                      imgProxyWidth={20}
                      imgProxyHeight={20}
                      alt=""
                    />
                    {t('chat.content.image')}
                  </p>
                )}

                {isVideo && <p>{t('chat.content.video')}</p>}
                {isLoc && <p>{t('chat.content.location')}</p>}
                {isDoc && (
                  <p className={cls.trunc}>
                    {getDocName(firstContent) || t('chat.content.file')}
                  </p>
                )}
              </>
            )}
          </div>

          {!!unread_count && <p className={cls.unread}>{unread_count}</p>}
        </div>
      </div>
    </div>
  );
}
