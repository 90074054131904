import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PromotionPackage } from '@/modules/promotion/types';
import { usePermissionAccess } from '@/modules/roles/helpers';
import { TopUpModal } from '@/modules/showroom/tariff/TopUpModal/TopUpModal';
import { Button } from '@/ui/Button/Button';
import { cn } from '@/utils/cn';
import { ADV_CUR } from '@/utils/consts';
import { numberWithCommas } from '@/utils/format';

import cls from './Result.module.scss';

type Props = {
  carsDays: number;
  carsCount: number;
  carsPrice: number;
  platesDays: number;
  platesCount: number;
  platesPrice: number;
  total: number;
  packages: PromotionPackage[];
  packagesCount: Record<string, number>;
  packagesPrice: Record<string, number>;
};

export function Result({
  carsDays,
  carsCount,
  carsPrice,
  platesDays,
  platesCount,
  platesPrice,
  total,
  packages,
  packagesCount,
  packagesPrice
}: Props) {
  const { t } = useTranslation();

  const permissions = usePermissionAccess();
  const canTopUp = permissions.wallet.control;
  const [isTopUpOpen, setTopUpOpen] = useState(false);

  const carsTotal = numberWithCommas(carsPrice);
  const platesTotal = numberWithCommas(platesPrice);

  return (
    <>
      <div className={cls.box}>
        <p className={cls.box_title}>{t('tariffs.calc.title')}</p>

        <ul className={cls.result_list}>
          {!!carsPrice && (
            <li>
              <div className={cls.result_row}>
                <span>{t('tariffs.passenger.title')}</span>{' '}
                {t('tariffs.calc.countWithDays', {
                  quantity: numberWithCommas(carsCount),
                  days: carsDays
                })}{' '}
                <div className={cls.result_line} />
                {t('priceCurrency', {
                  price: carsTotal,
                  currency: ADV_CUR
                })}
              </div>
            </li>
          )}

          {!!platesPrice && (
            <li>
              <div className={cls.result_row}>
                <span>{t('numbers')}</span>{' '}
                {t('tariffs.calc.countWithDays', {
                  quantity: numberWithCommas(platesCount),
                  days: platesDays
                })}{' '}
                <div className={cls.result_line} />
                {t('priceCurrency', {
                  price: platesTotal,
                  currency: ADV_CUR
                })}
              </div>
            </li>
          )}

          {packages
            .filter((p) => {
              const price = packagesPrice[String(p.id)] || 0;
              return !!price;
            })
            .map((p, i) => {
              return (
                <li key={p.id} className={cn({ [cls.separate]: i === 0 })}>
                  <div className={cls.result_row}>
                    <span>{p.name}</span>{' '}
                    {t('tariffs.calc.count', {
                      quantity: numberWithCommas(
                        packagesCount[String(p.id)] || 0
                      )
                    })}{' '}
                    <div className={cls.result_line} />
                    {t('priceCurrency', {
                      price: numberWithCommas(packagesPrice[String(p.id)] || 0),
                      currency: ADV_CUR
                    })}
                  </div>
                </li>
              );
            })}

          <li className={cls.separate}>
            <div className={cls.result_row}>
              <b>{t('tariffs.calc.result')}</b>
              <div className={cls.result_line} />
              <b>
                {t('priceCurrency', {
                  price: numberWithCommas(total),
                  currency: ADV_CUR
                })}
              </b>
            </div>
          </li>
        </ul>

        {canTopUp && (
          <Button color="green" onClick={() => setTopUpOpen(true)} fullWidth>
            {t('tariffs.topUp.title')}
          </Button>
        )}
      </div>

      <TopUpModal
        isOpen={isTopUpOpen}
        setOpen={setTopUpOpen}
        initialAmount={total}
      />
    </>
  );
}
