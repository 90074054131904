import { useTranslation } from 'react-i18next';

import {
  getDocName,
  handleText,
  isDocContent,
  isImageContent,
  isLocationContent,
  isVideoContent
} from '@/modules/chat/Detail/Messages/Message/helpers';
import { ChatMessage } from '@/modules/chat/types';
import { cn } from '@/utils/cn';

import cls from './RepliedMsg.module.scss';

type Props = {
  isOwn: boolean;
  msg: ChatMessage;
  onClick?: () => void;
};

export const RepliedMsg: React.FC<Props> = ({ msg, isOwn, onClick }) => {
  const { t } = useTranslation();

  const color = isOwn ? 'var(--clr-green)' : 'var(--clr-blue)';
  const { content } = msg;
  const firstContent = content ? content[0] : null;
  const imgUrl = firstContent?.thumbnail_url || firstContent?.url;

  return (
    <div
      className={cn(cls.root, { [cls.root_own]: isOwn })}
      style={{ '--replied-color': color } as React.CSSProperties}
    >
      {onClick && (
        // eslint-disable-next-line
        <button className={cls.btn} type="button" onClick={onClick} />
      )}

      {firstContent && !!content && content.length > 0 && (
        <>
          {isImageContent(firstContent.type) && imgUrl && (
            <img className={cls.img} src={imgUrl} alt="" />
          )}

          {isVideoContent(firstContent.type) && firstContent.thumbnail_url && (
            <img className={cls.img} src={firstContent.thumbnail_url} alt="" />
          )}
        </>
      )}

      <div className={cls.wrap}>
        {msg.author_name && <p className={cls.name}>{msg.author_name}</p>}

        {msg.text && (
          <p
            className={cls.text}
            dangerouslySetInnerHTML={{
              __html: msg.text ? handleText(msg.text) : ''
            }}
          />
        )}

        {firstContent && !msg.text && !!content && content.length > 0 && (
          <>
            {isImageContent(firstContent.type) && (
              <p className={cls.text}>{t('chat.content.image')}</p>
            )}

            {isVideoContent(firstContent.type) && (
              <p className={cls.text}>{t('chat.content.video')}</p>
            )}

            {isLocationContent(firstContent.type) && (
              <p className={cls.text}>{t('chat.content.location')}</p>
            )}

            {isDocContent(firstContent.type) && (
              <p className={cls.text}>
                {getDocName(firstContent) || t('chat.content.file')}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};
