
import { PromotionPackage } from '@/modules/promotion/types';
import { ActivatePromo } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Promotions/ActivatePromo/ActivatePromo';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { cn } from '@/utils/cn';

import cls from './ActivatePromo.module.scss';

type Props = {
  isOpen: boolean;
  close: () => void;
  promoPackage: PromotionPackage;
  onConfirmClick: () => void;
  loading?: boolean;
  autoExtension: boolean;
  setAutoExtension: React.Dispatch<React.SetStateAction<boolean>>;
  hiddenBackdrop?: boolean;
};

export function ActivatePromoModal({
  isOpen,
  close,
  promoPackage,
  onConfirmClick,
  loading,
  autoExtension,
  setAutoExtension,
  hiddenBackdrop
}: Props) {
  return (
    <>
      <AdaptiveModal
        name={`activate-promo-${promoPackage.id}`}
        isOpen={isOpen}
        close={close}
        backdropClass={cn({ [cls.hidden_backdrop]: hiddenBackdrop })}
      >
        <ActivatePromo
          close={close}
          promoPackage={promoPackage}
          onConfirmClick={onConfirmClick}
          loading={loading}
          autoExtension={autoExtension}
          setAutoExtension={setAutoExtension}
        />
      </AdaptiveModal>
    </>
  );
}
