import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, OptionI } from '@/app/types';
import { EmirateType } from '@/modules/emirate/types';
import { SCROLLTO } from '@/modules/showroom/advert/create/helpers';
import { useVrpBaseInfo } from '@/modules/showroom/advert/vrp/api';
import { VrpPlate } from '@/modules/showroom/advert/vrp/CreateVrp/VrpNumber/VrpPlate';
import { MAX_VRP_NUMBER_LEN } from '@/modules/showroom/advert/vrp/helpers';
import { GetVrpBaseInfoReq } from '@/modules/showroom/advert/vrp/types';
import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { Select } from '@/ui/Select/Select';
import { maxLength, onlyNumbers } from '@/utils/format';

import cls from './VrpNumber.module.scss';

type Props = {
  preview: string;
  setPreview: (v: string) => void;
  emirates: EmirateType[];
  emirateId: Nullable<number>;
  onEmirateChange: (id: number) => void;
  typeId: Nullable<number>;
  onTypeChange: (id: Nullable<number>) => void;
  codeId: Nullable<number>;
  onCodeChange: (id: Nullable<number>) => void;
  number: string;
  setNumber: (v: string) => void;
  isSaved: boolean;
  onSaveClick: (preview: string) => void;
  onChangeClick: () => void;
  numberWasSaved: boolean;
  canChange: boolean;
};

export function VrpNumber({
  preview,
  setPreview,
  emirates,
  emirateId,
  onEmirateChange,
  typeId,
  onTypeChange,
  codeId,
  onCodeChange,
  number,
  setNumber,
  isSaved,
  onSaveClick,
  onChangeClick,
  numberWasSaved,
  canChange
}: Props) {
  const { t } = useTranslation();

  const emiratesOptions = useMemo<OptionI[]>(
    () => emirates.map((e) => ({ id: String(e.id), text: e.name })),
    [emirates]
  );
  const emirateOption = emiratesOptions.find((o) => o.id === String(emirateId));
  const onEmirateSelectChange = (v: OptionI) => {
    onEmirateChange(Number(v.id));
  };

  // Base info
  const req = useMemo<Nullable<GetVrpBaseInfoReq>>(
    () => (emirateId ? { emirate_id: emirateId } : null),
    [emirateId]
  );
  const [baseInfo, infoLoading] = useVrpBaseInfo(req);

  // Types
  const typesOptions = useMemo<OptionI[]>(
    () =>
      baseInfo ? baseInfo.map((e) => ({ id: String(e.id), text: e.name })) : [],
    [baseInfo]
  );
  const typeOption = typesOptions.find((o) => o.id === String(typeId));
  const onTypeSelectChange = (v: OptionI) => {
    onTypeChange(Number(v.id));
  };
  const selectedType = baseInfo?.find((tp) => tp.id === typeId);

  useEffect(() => {
    if (typesOptions.length > 0 && typeOption) {
      const ids = typesOptions.map((tp) => tp.id);
      if (!ids.includes(typeOption.id)) {
        onTypeChange(null);
      }
    }

    if (emirateId && typesOptions.length > 0 && !typeOption) {
      onTypeChange(Number(typesOptions[0].id));
    }
  }, [emirateId, onTypeChange, typeOption, typesOptions]);

  // Codes
  const codesOptions = useMemo<OptionI[]>(
    () =>
      selectedType
        ? selectedType.plate_codes.map((e) => ({
            id: String(e.id),
            text: e.name
          }))
        : [],
    [selectedType]
  );
  const codeOption = codesOptions.find((o) => o.id === String(codeId));

  const onCodeSelectChange = (v: OptionI) => {
    onCodeChange(Number(v.id));
  };

  useEffect(() => {
    if (codesOptions && codesOptions.length > 0 && codeId) {
      const ids = codesOptions.map((tp) => tp.id);
      if (!ids.includes(String(codeId))) {
        onCodeChange(null);
      }
    }
  }, [onCodeChange, codeId, codesOptions]);

  // Save
  const [isNumberTouched, setNumberTouched] = useState(false);
  const showNumberError = !number && isNumberTouched;

  const isCodeValid = codesOptions.length > 0 ? !!codeId : !!typeId;
  const isValid =
    !!preview && !!emirateId && !!typeId && isCodeValid && !!number;

  const setPlateNumber = (plateNumber: string) => {
    if (plateNumber[0] === '0') return;
    return setNumber(maxLength(onlyNumbers(plateNumber), MAX_VRP_NUMBER_LEN));
  };

  return (
    <div className={cls.root} id={SCROLLTO.plate}>
      <div className="box box-first">
        <h1 className={cls.title}>{t('number')}</h1>
        <div className={cls.emirates}>
          <Select
            name="vrp-emirate"
            value={emirateOption}
            onChange={onEmirateSelectChange}
            options={emiratesOptions}
            disabled={isSaved || infoLoading}
            label={t('emirate')}
            dropdownModal={false}
          />
        </div>
        {emirateId && selectedType && (
          <VrpPlate
            key={emirateId + selectedType.id + String(codeId) + number}
            preview={preview}
            setPreview={setPreview}
            emirateId={emirateId}
            type={selectedType}
            code={codeOption?.text}
            number={number}
          />
        )}
        <div className={cls.fields}>
          <div className={cls.field}>
            <Select
              name="vrp-type"
              value={typeOption}
              onChange={onTypeSelectChange}
              options={typesOptions}
              disabled={!emirateId || isSaved || infoLoading}
              label={t('type')}
              dropdownTop
              dropdownModal={false}
            />
          </div>
          <div className={cls.field}>
            <Select
              name="vrp-code"
              value={codeOption}
              onChange={onCodeSelectChange}
              options={codesOptions}
              disabled={
                !typeId || isSaved || infoLoading || codesOptions.length <= 0
              }
              label={t('code')}
              dropdownTop
              dropdownModal={false}
            />
          </div>
          <div className={cls.field}>
            <Input
              name="vrp-number"
              value={number}
              disabled={isSaved}
              onBlur={() => setNumberTouched(true)}
              onChange={(e) => setPlateNumber(e.currentTarget.value)}
              label={t('number')}
              errorText={showNumberError ? t('common.required') : ''}
            />
          </div>
        </div>

        {isSaved ? (
          <>
            {canChange && (
              <Button
                onClick={onChangeClick}
                variant="outline"
                color="blue"
                fullWidth
              >
                {t('changeVrpNumber')}
              </Button>
            )}
          </>
        ) : (
          <Button
            onClick={() => onSaveClick(preview)}
            disabled={!isValid || infoLoading}
            color="blue"
            fullWidth
          >
            {numberWasSaved ? t('common.save') : t('common.continue')}
          </Button>
        )}
      </div>
    </div>
  );
}
