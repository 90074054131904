import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { RentTariff } from '@/modules/showroom/advert/rent/tariffs/types';
import { sortTariffs } from '@/modules/showroom/advert/rent/update/helpers';
import { cn } from '@/utils/cn';
import { CURRENCY_DISPLAY } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';

import cls from './Tariffs.module.scss';

type Props = {
  tariffs: RentTariff[];
  hovered: boolean;
  listOnly?: boolean;
};

export function Tariffs({ tariffs: tariffsProp, hovered, listOnly }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const tariffs = sortTariffs(tariffsProp || []);

  const lowestPriceTariff = useMemo(() => {
    if (!tariffs[0]) return null;

    let min = tariffs[0].price;
    tariffs.forEach((tf) => {
      min = Math.min(min, tf.price);
    });
    return min;
  }, [tariffs]);

  if (!tariffs.length) return <></>;

  return (
    <div>
      {lowestPriceTariff && !listOnly && (
        <b className={cls.price}>
          {tariffs.length > 1 && t('from')}
          <Trans
            t={t}
            i18nKey="rentTariffs.price"
            components={{ dayWrap: <span /> }}
            values={{
              price: numberWithSpaces(lowestPriceTariff, lang),
              currency: CURRENCY_DISPLAY.aed
            }}
          />
        </b>
      )}

      <ul className={cn(cls.list, { [cls.list_visible]: hovered })}>
        {tariffs.map((tf) => (
          <li key={tf.id}>
            <div className={cls.tariff}>
              {t('priceCurrency', {
                price: numberWithSpaces(tf.price, lang),
                currency: CURRENCY_DISPLAY.aed
              })}
              <span>
                {tf.days_up_to
                  ? t('rentTariffs.daysPlural.many', {
                      value: t('common.hyphenSeparatedShort', {
                        first: tf.days_from,
                        second: tf.days_up_to
                      })
                    })
                  : t('rentTariffs.daysPlural.many', {
                      value: `${tf.days_from}+`
                    })}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
