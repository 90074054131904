export function CloseIcon() {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35791 23.1411C5.84372 23.6157 6.66308 23.6096 7.12564 23.1471L14.0021 16.2706L20.8746 23.1451C21.3452 23.6157 22.1637 23.6229 22.6403 23.1391C23.117 22.6533 23.119 21.8531 22.6484 21.3805L15.7759 14.4968L22.6484 7.62435C23.119 7.15375 23.1262 6.34444 22.6403 5.8678C22.1545 5.38199 21.3452 5.37998 20.8746 5.85975L14.0021 12.7322L7.12564 5.85774C6.66308 5.38803 5.83455 5.37081 5.35791 5.86579C4.88328 6.3516 4.88932 7.15978 5.35187 7.62234L12.2284 14.4968L5.35187 21.3845C4.88932 21.845 4.87411 22.6644 5.35791 23.1411Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CopyIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.88623 6.02872V4.5769C7.88623 2.88641 8.75976 2 10.4374 2H13.3895C14.2936 2 15.0001 2.23472 15.5876 2.83645L19.5051 6.82247C20.1254 7.45845 20.3402 8.11784 20.3402 9.15051V15.3764C20.3402 17.0655 19.4666 17.9533 17.789 17.9533H16.4553V16.4143H17.6547C18.4179 16.4143 18.8011 16.0112 18.8011 15.2808V8.77647H15.3354C14.3698 8.77647 13.8578 8.27086 13.8578 7.29883V3.53903H10.5653C9.80201 3.53903 9.42526 3.94862 9.42526 4.67258V6.02982C9.38071 6.02907 9.33523 6.02872 9.28877 6.02872H7.88623ZM15.1425 4.18224V7.10464C15.1425 7.37393 15.2539 7.49176 15.5231 7.49176H18.4005L15.1425 4.18224Z"
        fill="currentColor"
      />
      <path
        d="M4 19.4052C4 21.1021 4.8685 21.9821 6.55113 21.9821H13.9042C15.5868 21.9821 16.4553 21.0943 16.4553 19.4052V13.3591C16.4553 12.3069 16.3219 11.8147 15.6638 11.1437L11.4061 6.82023C10.7738 6.17498 10.235 6.02872 9.28877 6.02872L6.55113 6.02871C4.87494 6.02871 4 6.90868 4 8.60561V19.4052ZM5.53903 19.3095V8.69485C5.53903 7.97733 5.91578 7.56774 6.68044 7.56774H9.09443V11.868C9.09443 12.9935 9.65091 13.5422 10.7621 13.5422H14.9148V19.3095C14.9148 20.0399 14.5317 20.443 13.7749 20.443H6.67399C5.91578 20.443 5.53903 20.0399 5.53903 19.3095ZM10.9142 12.1849C10.5901 12.1849 10.4517 12.0479 10.4517 11.7223V7.86971L14.7011 12.1849H10.9142Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function GlobeIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9969 21.2062C14.7257 21.2062 16.9435 17.2512 16.9435 12.0145C16.9435 6.75223 14.7353 2.79551 11.9969 2.79551C9.26018 2.79551 7.05032 6.75223 7.05032 12.0145C7.05032 17.2512 9.26812 21.2062 11.9969 21.2062ZM11.9969 4.05364C13.8676 4.05364 15.5647 7.77055 15.5647 12.0145C15.5647 16.1915 13.8676 19.9402 11.9969 19.9402C10.1279 19.9402 8.43078 16.1968 8.43078 12.0145C8.43078 7.77055 10.1279 4.05364 11.9969 4.05364ZM11.3261 2.92926V21.0241H12.6775V2.92926H11.3261ZM11.9969 15.3937C8.97255 15.3937 6.28171 16.1759 4.94595 17.4155L5.98265 18.2729C7.23401 17.2915 9.3705 16.7451 11.9969 16.7451C14.625 16.7451 16.7616 17.2915 18.0111 18.2729L19.0478 17.4155C17.7138 16.1759 15.023 15.3937 11.9969 15.3937ZM20.8687 11.3146H3.12689V12.666H20.8687V11.3146ZM11.9969 8.64504C15.023 8.64504 17.7138 7.86287 19.0478 6.62313L18.0111 5.76586C16.7616 6.73927 14.625 7.29362 11.9969 7.29362C9.37225 7.29362 7.23749 6.73927 5.98265 5.76586L4.94595 6.62313C6.28171 7.86287 8.97255 8.64504 11.9969 8.64504ZM11.9969 21.9938C17.4739 21.9938 21.9955 17.4659 21.9955 11.9969C21.9955 6.51997 17.4659 2 11.989 2C6.52171 2 2 6.51997 2 11.9969C2 17.4659 6.52965 21.9938 11.9969 21.9938ZM11.9969 20.4442C7.3782 20.4442 3.55136 16.6174 3.55136 11.9969C3.55136 7.37647 7.37027 3.54961 11.989 3.54961C16.6094 3.54961 20.4442 7.37647 20.4442 11.9969C20.4442 16.6174 16.6174 20.4442 11.9969 20.4442Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function TelegramIcon() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H48C54.6274 0 60 5.37258 60 12V48C60 54.6274 54.6274 60 48 60H12C5.37258 60 0 54.6274 0 48V12Z"
        fill="url(#paint0_linear_683_81884)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.562 29.6954C22.3071 25.8606 28.1528 23.382 31.099 22.1661C39.4232 18.7055 41.1535 18.0975 42.2759 18.0508C42.5097 18.0508 43.0709 18.0975 43.4451 18.3781C43.7256 18.612 43.8192 18.9393 43.8659 19.1732C43.9127 19.407 43.9595 19.9214 43.9127 20.3423C43.4451 25.0656 41.5277 36.6166 40.4988 41.9011C40.0779 44.1459 39.2362 44.8941 38.4412 44.9876C36.7108 45.1279 35.3546 43.8185 33.6711 42.7429C31.0522 41.0126 29.5557 39.937 26.9836 38.2534C24.0374 36.2893 25.9548 35.2137 27.6383 33.4834C28.0592 33.0157 35.7755 26.0477 35.9158 25.3929C35.9158 25.2994 35.9626 25.0188 35.7755 24.8785C35.5885 24.7382 35.3546 24.785 35.1676 24.8318C34.887 24.8785 30.6781 27.6845 22.4942 33.2028C21.2783 34.0445 20.2027 34.4187 19.2206 34.4187C18.145 34.4187 16.0873 33.8107 14.544 33.2963C12.6734 32.6883 11.1769 32.361 11.3172 31.3322C11.4108 30.8177 12.159 30.2565 13.562 29.6954Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_683_81884"
          x1="0"
          y1="29.9783"
          x2="59.9566"
          y2="29.9783"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function WhatsAppIcon() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H48C54.6274 0 60 5.37258 60 12V48C60 54.6274 54.6274 60 48 60H12C5.37258 60 0 54.6274 0 48V12Z"
        fill="url(#paint0_linear_683_81888)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.7807 14.3628C41.743 10.3252 36.3697 8.0957 30.6471 8.0957C18.8526 8.0957 9.26696 17.6916 9.25669 29.4758C9.25669 33.2464 10.243 36.9245 12.1129 40.1608L9.08203 51.2464L20.4245 48.2772C23.5478 49.9827 27.0615 50.8765 30.6471 50.8765H30.6574C42.4416 50.8765 52.0375 41.2806 52.0478 29.4964C52.0478 23.7738 49.8183 18.4005 45.7807 14.3628ZM30.6574 47.2601C27.4622 47.2601 24.3286 46.3971 21.606 44.7841L20.9587 44.4039L14.2293 46.171L16.0272 39.6162L15.606 38.9484C13.8286 36.1231 12.8834 32.8457 12.8834 29.4861C12.8834 19.6847 20.8663 11.7121 30.6676 11.7121C35.4142 11.7121 39.8834 13.5615 43.2327 16.921C46.5923 20.2806 48.4416 24.7498 48.4313 29.4964C48.4211 39.2875 40.4485 47.2601 30.6574 47.2601ZM40.4074 33.9553C39.8731 33.6882 37.243 32.3936 36.7601 32.219C36.267 32.0443 35.9176 31.9519 35.5581 32.4861C35.1985 33.0204 34.1813 34.2224 33.8629 34.582C33.5546 34.9416 33.2361 34.9827 32.7019 34.7156C32.1676 34.4484 30.4416 33.8834 28.4074 32.0649C26.8149 30.6471 25.7464 28.9005 25.4382 28.3663C25.13 27.832 25.4074 27.5443 25.6745 27.2772C25.9108 27.0409 26.2087 26.6505 26.4759 26.3423C26.743 26.0341 26.8355 25.808 27.0101 25.4484C27.1848 25.0889 27.1026 24.7806 26.969 24.5135C26.8355 24.2464 25.767 21.6163 25.3252 20.5478C24.8937 19.5101 24.4519 19.6436 24.1231 19.6334C23.8149 19.6128 23.4553 19.6128 23.0957 19.6128C22.7361 19.6128 22.1608 19.7464 21.6676 20.2806C21.1745 20.8149 19.7978 22.1094 19.7978 24.7395C19.7978 27.3697 21.7087 29.9073 21.9759 30.2669C22.243 30.6265 25.7464 36.0204 31.1094 38.332C32.3834 38.8868 33.38 39.2156 34.1608 39.4621C35.445 39.8731 36.606 39.8115 37.5307 39.6779C38.5581 39.5238 40.695 38.3834 41.1368 37.1402C41.5786 35.8971 41.5786 34.8183 41.445 34.6026C41.2909 34.356 40.9416 34.2224 40.4074 33.9553Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_683_81888"
          x1="30.0023"
          y1="60.0046"
          x2="30.0023"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25CF43" />
          <stop offset="1" stopColor="#61FD7D" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function FacebookIcon() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_683_81892)">
        <path
          d="M0 12C0 5.37258 5.37258 0 12 0H48C54.6274 0 60 5.37258 60 12V48C60 54.6274 54.6274 60 48 60H12C5.37258 60 0 54.6274 0 48V12Z"
          fill="#1877F2"
        />
        <path
          d="M41.6777 38.6711L43.0078 29.9992H34.6875V24.3717C34.6875 21.9992 35.8497 19.6867 39.5764 19.6867H43.3594V12.3039C43.3594 12.3039 39.9262 11.718 36.6439 11.718C29.7913 11.718 25.3125 15.8711 25.3125 23.3898V29.9992H17.6953V38.6711H25.3125V60.8348C26.8399 61.0744 28.4053 61.1992 30 61.1992C31.5947 61.1992 33.1601 61.0744 34.6875 60.8348V38.6711H41.6777Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_683_81892">
          <path
            d="M0 12C0 5.37258 5.37258 0 12 0H48C54.6274 0 60 5.37258 60 12V48C60 54.6274 54.6274 60 48 60H12C5.37258 60 0 54.6274 0 48V12Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MailIcon() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H48C54.6274 0 60 5.37258 60 12V48C60 54.6274 54.6274 60 48 60H12C5.37258 60 0 54.6274 0 48V12Z"
        fill="#FF9EBB"
      />
      <path
        d="M29.9923 31.2705C30.465 31.2705 30.9119 31.0839 31.3788 30.6645L46.3835 17.3936C45.5802 16.8922 44.6598 16.666 43.4175 16.666H16.5794C15.3399 16.666 14.4195 16.8922 13.6012 17.3936L28.6086 30.6645C29.085 31.0839 29.5347 31.2705 29.9923 31.2705ZM12.0451 40.3073L23.1628 29.2046L12.0313 19.3534C11.8504 19.738 11.6665 20.5126 11.6665 21.5298V38.0799C11.6665 39.0196 11.8109 39.7193 12.0451 40.3073ZM16.2029 42.9437H43.7968C44.8873 42.9437 45.7598 42.6876 46.3666 42.3064L34.9291 30.8688L32.8801 32.6944C31.9549 33.5121 31.0059 33.9069 29.9923 33.9069C28.9936 33.9069 28.0448 33.5121 27.1196 32.6944L25.0705 30.8688L13.6329 42.3064C14.2397 42.6876 15.1 42.9437 16.2029 42.9437ZM47.9545 40.3073C48.1764 39.7193 48.3332 39.0196 48.3332 38.0799V21.5298C48.3332 20.5126 48.1491 19.738 47.9683 19.3534L36.8341 29.2046L47.9545 40.3073Z"
        fill="white"
      />
    </svg>
  );
}
