import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOnceTrue } from '@/hooks/useOnceTrue';
import { useShowroomAddressList } from '@/modules/showroom/address/api';
import { ShowroomAddress } from '@/modules/showroom/address/types';
import { FilterBtn } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FilterBtn/FilterBtn';
import { FiltersList } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/FiltersList';
import { FilterType } from '@/modules/showroom/advert/search/SearchAdverts/Filters/FiltersList/helpers';
import { useSearchStore } from '@/modules/showroom/advert/search/store';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { cn } from '@/utils/cn';

import cls from './Filters.module.scss';

export function AddressFilter() {
  const { t } = useTranslation();

  const [isAddressOpen, setAddressOpen] = useState(false);
  const onceOpened = useOnceTrue(isAddressOpen);

  const addressId = useSearchStore((s) => s.addressId);
  const setAddressId = useSearchStore((s) => s.setAddressId);

  const skipReq = useMemo<boolean>(() => {
    if (!onceOpened && !addressId) return true;
    return false;
  }, [onceOpened, addressId]);
  const [list, loading] = useShowroomAddressList(skipReq);
  const type = list?.find((tp) => tp.id === addressId);

  const onAddressChange = (v: FilterType) => {
    const tp = v as ShowroomAddress;
    setAddressId(tp.id);
    setAddressOpen(false);
  };

  const onAddressReset = () => {
    setAddressOpen(false);
    setAddressId(null);
  };

  return (
    <>
      <FilterBtn
        text={t('address')}
        onClick={() => setAddressOpen(true)}
        active={isAddressOpen}
        onResetClick={onAddressReset}
        value={type?.name}
      />

      <Dropdown
        className={cn(cls.dropdown, cls.dropdown_select)}
        name="search-filters-address"
        isOpen={isAddressOpen}
        close={() => setAddressOpen(false)}
        toLeft
        withModal
      >
        <li className="hide-scrollbar">
          <FiltersList
            activeId={addressId}
            list={list || []}
            loading={loading}
            onClick={onAddressChange}
            search=""
            allButtonText={t('common.anyN')}
            onAllClick={onAddressReset}
            selectStyle
          />
        </li>
      </Dropdown>
    </>
  );
}
