import { onlyNumbers } from '@/utils/format';

export const makeWhatsAppPhoneSend = (phone: string) => {
  const formattedPhone = onlyNumbers(phone, '+');
  return `https://api.whatsapp.com/send?phone=${formattedPhone}`;
};

export const useWhatsAppPhoneSend = (phone: string) => {
  return makeWhatsAppPhoneSend(phone);
};

export function shareInWhatsapp(text: string) {
  return `https://wa.me/?text=${encodeURIComponent(text)}`;
}

export function shareInTelegram(url: string, text?: string) {
  return `https://telegram.me/share/url?url=${encodeURIComponent(url)}${
    text ? `&text=${encodeURIComponent(text)}` : ''
  }`;
}

export function shareInFacebook(url: string, text?: string) {
  return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    url
  )}${text ? `&quote=${encodeURIComponent(text)}` : ''}`;
}

export function shareInMail(text: string, subject?: string) {
  return `mailto:?body=${encodeURIComponent(text)}${
    subject ? `&subject=${encodeURIComponent(subject)}` : ''
  }`;
}
