
import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { isVideoContent } from '@/modules/chat/Detail/Messages/Message/helpers';
import { ChatMessageContent } from '@/modules/chat/types';
import { cn } from '@/utils/cn';
import { secondsToHHMMSS } from '@/utils/date';

import cls from './AttachedList.module.scss';
import { RemoveIcon } from './icons';

type Props = {
  list: ChatMessageContent[];
  onRemoveClick: (url: string) => void;
};

export const AttachedList: React.FC<Props> = ({ list, onRemoveClick }) => {
  const imgPlaceholder = useImgPlaceholder();

  return (
    <div className={cn(cls.root, 'hide-scrollbar')}>
      <ul className={cls.list}>
        {list.map((v) => {
          const isVideo = isVideoContent(v.type);
          const imgSrc = isVideo ? v.thumbnail_url : v.thumbnail_url || v.url;

          return (
            <li key={v.url}>
              <div className={cls.preview}>
                <img src={imgSrc || imgPlaceholder} alt="" />
                {v.duration && (
                  <span className={cls.time}>
                    {secondsToHHMMSS(v.duration)}
                  </span>
                )}

                <button
                  className={cls.remove_btn}
                  type="button"
                  disabled={!v.url}
                  onClick={() => {
                    if (v.url) onRemoveClick(v.url);
                  }}
                >
                  <RemoveIcon />
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
