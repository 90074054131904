import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, OptionI } from '@/app/types';
import { useWindowClick } from '@/hooks/useWindowClick';
import { PrimarySelectDropdown } from '@/ui/PrimarySelect/PrimarySelectDropdown';
import { cn } from '@/utils/cn';
import { escapePlus } from '@/utils/format';

import { ChevronIcon } from './icons';
import cls from './Select.module.scss';

interface Props {
  name: string;
  value?: OptionI[];
  options: OptionI[];
  onOpen?: () => void;
  onChange?: (option: OptionI[]) => void;
  onOptionRemove?: (option: OptionI) => void;
  onOptionAdd?: (option: OptionI, removeOtherOptions?: boolean) => void;
  onClear?: () => void;
  searchFilterFn?: (searchText: string, id: OptionI['id']) => void;
  clearText?: Nullable<string>;
  label?: Nullable<string>;
  disabled?: boolean;
  dropdownCls?: string;
  dropdownFullWidth?: boolean;
  dropdownToLeft?: boolean;
  dropdownTop?: boolean;
  error?: boolean;
  showSearch?: boolean;
  active?: boolean;
  countries?: boolean;
  size?: 's' | 'default';
  showScrollbar?: boolean;
  renderOption?: (opt: OptionI, closeDropdown: () => void) => React.ReactNode;
}

export const MultipleSelect: React.FC<Props> = ({
  name,
  value,
  onOpen,
  onChange,
  onOptionRemove,
  onOptionAdd,
  onClear,
  searchFilterFn,
  clearText,
  options,
  label,
  disabled,
  error,
  showSearch,
  active,
  dropdownFullWidth,
  dropdownTop,
  size = 'default',
  showScrollbar
}) => {
  const { t } = useTranslation();

  const rootRef = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  useWindowClick((e) => {
    const el = e.target as HTMLElement | null;
    const clickedRoot = el?.closest(`.${escapePlus(cls.root)}`);
    const thisRoot = rootRef?.current;

    if (!clickedRoot || (!!clickedRoot && clickedRoot !== thisRoot)) {
      setOpen(false);
    }
  });

  const hasValue = !!value && value.length > 0;

  // Handlers
  const toggleOpen = () => {
    const newValue = !isOpen;
    setTimeout(() => {
      setOpen(newValue);
    }, 100);

    if (newValue && onOpen) onOpen();
  };

  const onClearClick = () => {
    setOpen(false);
    if (onClear) onClear();
  };

  return (
    <div
      ref={rootRef}
      className={cn(cls.root, {
        [cls.root_small]: size === 's',
        [cls.root_active]: active,
        [cls.root_open]: isOpen,
        [cls.root_selected]: hasValue,
        [cls.root_error]: !!error,
        [cls.root_disabled]: !!disabled,
        [cls.root_labeled]: !!label
      })}
    >
      {label && <span className={cls.label}>{label}</span>}

      <button
        className={cls.select_btn}
        type="button"
        onClick={toggleOpen}
        disabled={disabled}
      >
        <span className={cls.button_name}>
          {hasValue
            ? value.map((v) => v.text).join(t('common.listSeparator') as string)
            : ''}
        </span>
        <ChevronIcon />
      </button>

      <PrimarySelectDropdown
        name={name}
        value={value}
        options={options as OptionI[]}
        dropdownTop={dropdownTop}
        dropdownFullWidth={dropdownFullWidth}
        clearText={clearText}
        isOpen={isOpen}
        setOpen={setOpen}
        onChange={onChange}
        onOptionRemove={onOptionRemove}
        onOptionAdd={onOptionAdd}
        onClearClick={onClearClick}
        showSearch={showSearch}
        searchFilterFn={searchFilterFn}
        showScrollbar={showScrollbar}
        disableOutsideClick
        multiple
      />
    </div>
  );
};

MultipleSelect.displayName = 'MultipleSelect';
