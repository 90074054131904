import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { handleText } from '@/modules/chat/Detail/Messages/Message/helpers';
import { cn } from '@/utils/cn';

import { ChevronIcon } from './icons';
import cls from './ReviewCard.module.scss';

const TRUNC_LEN = 350;

type Props = {
  text: string;
};

export function ReviewText({ text }: Props) {
  const { t } = useTranslation();

  // Trunc
  const mustTruncate = text.length > TRUNC_LEN;
  const [isTruncated, setTruncated] = useState(mustTruncate);
  const toggle = () => {
    setTruncated((p) => !p);
  };

  useEffect(() => {
    setTruncated(mustTruncate);
  }, [mustTruncate]);

  return (
    <div className={cls.text_wrap}>
      <p
        className={cn(cls.text, { [cls.text_trunc]: isTruncated })}
        dangerouslySetInnerHTML={{
          __html: handleText(
            isTruncated ? text.slice(0, TRUNC_LEN) : text,
            true
          )
        }}
      />

      {mustTruncate && (
        <button
          className={cn(cls.toggle, {
            [cls.toggle_opened]: !isTruncated
          })}
          type="button"
          onClick={toggle}
        >
          <span>
            {t(isTruncated ? 'profile.reviews.readFull' : 'common.fold')}
          </span>
          <ChevronIcon />
        </button>
      )}
    </div>
  );
}
