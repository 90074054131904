import { useTranslation } from 'react-i18next';

import { useRegSpecs } from '@/modules/regional-specs/hooks';
import { RegSpec } from '@/modules/regional-specs/types';
import {
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { CreateAdvertSlice } from '@/modules/showroom/advert/create/types';
import { Button } from '@/ui/Button/Button';
import { CheckBtn } from '@/ui/CheckBtn/CheckBtn';

import cls from './RegSpecs.module.scss';

type Props = {
  regSpecs: CreateAdvertSlice['regSpecs'];
  setRegSpecs: CreateAdvertSlice['setRegSpecs'];
  isRegSpecsFilled: CreateAdvertSlice['isRegSpecsFilled'];
  setRegSpecsFilled: CreateAdvertSlice['setRegSpecsFilled'];
};

export function RegSpecs({
  regSpecs,
  setRegSpecs,
  isRegSpecsFilled,
  setRegSpecsFilled
}: Props) {
  const { t } = useTranslation();

  const list = useRegSpecs();
  const onSpecClick = (v: RegSpec) => {
    return () => {
      setRegSpecs(v);
    };
  };

  const onContinueClick = () => {
    setRegSpecsFilled(true);
    scrollToSection(SCROLLTO.warranty, 100);
  };

  return (
    <div className={cls.root} id={SCROLLTO.regspecs}>
      <div className="box">
        <h1 className={cls.title}>{t('regionSpecs')}</h1>

        <ul className={cls.list}>
          {list?.map((v) => (
            <li key={v.id}>
              <CheckBtn active={regSpecs?.id === v.id} onClick={onSpecClick(v)}>
                {v.name}
              </CheckBtn>
            </li>
          ))}
        </ul>

        {!isRegSpecsFilled && (
          <Button
            onClick={onContinueClick}
            color={regSpecs ? 'blue' : 'black'}
            variant={regSpecs ? 'primary' : 'secondary'}
            fullWidth
          >
            {t(regSpecs ? 'common.continue' : 'common.skip')}
          </Button>
        )}
      </div>
    </div>
  );
}
