import { useMemo, useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { useDesktop } from '@/app/common-store';
import { useRTL } from '@/hooks/useRTL';
import { useWindowClick } from '@/hooks/useWindowClick';
import { BottomModal } from '@/ui/modals/BottomModal/BottomModal';
import { cn } from '@/utils/cn';
import { MODAL_ROOT_ID } from '@/utils/consts';

import cls from './Dropdown.module.scss';

const DEFAULT_DROPDOWN_WIDTH = 'fit-content';
const generateUniqueId = () =>
  `dropdown-${Math.random().toString(36).substr(2, 9)}`;

type Position =
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right'
  | 'top-left'
  | 'top-center'
  | 'top-right';

type Props = {
  name: string;
  isOpen: boolean;
  close: () => void;
  children: React.ReactNode;
  fullWidth?: boolean;
  fullHeight?: boolean;
  withModal?: boolean;
  disableOutsideClick?: boolean;
  listTop?: boolean;
  noHover?: boolean;
  isDesktop?: boolean;
  className?: string;
  modalClassName?: string;
  anchorClassName?: string;
  toLeft?: boolean;
  showScrollbar?: boolean;
  actionsModal?: boolean;
  position?: Position;
  verticalOffset?: number;
  horizontalOffset?: number;
  safeScreenEdgeDistance?: number;
  fixed?: boolean;
  alwaysOnTop?: boolean;
};

export const Dropdown: React.FC<Props> = ({
  name,
  isOpen,
  close,
  children,
  fullWidth,
  fullHeight,
  withModal,
  disableOutsideClick,
  className,
  modalClassName,
  anchorClassName,
  noHover,
  isDesktop: isDesktopProp,
  showScrollbar,
  actionsModal,
  position = 'bottom-left',
  verticalOffset = 4,
  horizontalOffset = 0,
  safeScreenEdgeDistance = 16,
  fixed,
  alwaysOnTop
}) => {
  const isDesktop = useDesktop();
  const { isRTL } = useRTL();
  const desktop = useMemo(() => {
    return typeof isDesktopProp === 'boolean' ? isDesktopProp : isDesktop;
  }, [isDesktop, isDesktopProp]);

  const rootRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState<{
    top: number;
    left: number;
  }>({
    top: 0,
    left: 0
  });
  const [dropdownWidth, setDropdownWidth] = useState(DEFAULT_DROPDOWN_WIDTH);
  const [anchorElementId] = useState(generateUniqueId());
  const [isClickActive, setClickActive] = useState(false);
  const [isFirstOpen, setFirstOpen] = useState(false);
  const [isCommonModalOpen, setIsCommonModalOpen] = useState(false);

  const calculatePosition = (newPostion: Position) => {
    const containerElement = document.getElementById(anchorElementId);
    if (containerElement) {
      const rect = containerElement.getBoundingClientRect();
      const dropdownWidth = rootRef.current?.offsetWidth || 0;
      const dropdownHeight = rootRef.current?.offsetHeight || 0;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      const bodyRect = document.body.getBoundingClientRect();

      const scrollTop =
        (!fixed ? window.scrollY : 0) +
        (isCommonModalOpen ? Math.abs(bodyRect.top) : 0);
      const scrollLeft = window.scrollX;

      let top = rect.bottom + scrollTop;
      let left = (isRTL ? rect.right : rect.left) + scrollLeft;

      if (isRTL) left = document.body.clientWidth - left;

      switch (newPostion) {
        case 'bottom-left':
          top = rect.bottom + scrollTop + verticalOffset;
          left = left + horizontalOffset;
          break;
        case 'bottom-center':
          top = rect.bottom + scrollTop + verticalOffset;
          left = left + (rect.width - dropdownWidth) / 2;
          break;
        case 'bottom-right':
          top = rect.bottom + scrollTop + verticalOffset;
          left =
            (isRTL ? document.body.clientWidth - rect.left : rect.right) +
            scrollLeft -
            dropdownWidth -
            horizontalOffset;
          break;
        case 'top-left':
          top = rect.top + scrollTop - dropdownHeight - verticalOffset;
          left = left + horizontalOffset;
          break;
        case 'top-center':
          top = rect.top + scrollTop - dropdownHeight - verticalOffset;
          left = left + (rect.width - dropdownWidth) / 2;
          break;
        case 'top-right':
          top = rect.top + scrollTop - dropdownHeight - verticalOffset;
          left = rect.right + scrollLeft - dropdownWidth - horizontalOffset;
          break;
        default:
          break;
      }

      if (
        top + dropdownHeight >
        viewportHeight + scrollTop - safeScreenEdgeDistance
      ) {
        const topAlternative = rect.top + scrollTop - dropdownHeight;
        if (topAlternative >= safeScreenEdgeDistance) {
          top = topAlternative;
        } else {
          top =
            viewportHeight +
            scrollTop -
            dropdownHeight -
            safeScreenEdgeDistance;
        }
      }

      if (top < scrollTop + safeScreenEdgeDistance) {
        calculatePosition('bottom-center');
        return;
      }

      if (
        left + dropdownWidth >
        viewportWidth + scrollLeft - safeScreenEdgeDistance
      ) {
        left = Math.max(
          viewportWidth + scrollLeft - dropdownWidth - safeScreenEdgeDistance,
          scrollLeft + safeScreenEdgeDistance
        );
      }

      if (left < scrollLeft + safeScreenEdgeDistance) {
        left = scrollLeft + safeScreenEdgeDistance;
      }

      setDropdownPosition({ top, left });
    }
  };

  const calculateWidth = () => {
    const containerEl = anchorRef.current;
    if (!containerEl || !fullWidth) return;

    setDropdownWidth(`${containerEl.offsetWidth}px`);
  };

  useEffect(() => {
    if (isOpen) {
      calculateWidth();
      setFirstOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (withModal && !desktop) return;
    if (isOpen && isFirstOpen) calculatePosition(position);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isFirstOpen, isCommonModalOpen, withModal]);

  useWindowClick((e) => {
    if (withModal && !desktop) return;
    if (disableOutsideClick || !isClickActive) return;

    const el = e.target as HTMLElement | null;
    const clickedRoot = rootRef?.current;
    if (clickedRoot && !clickedRoot.contains(el)) {
      close();
    }
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isOpen) {
      timer = setTimeout(() => setClickActive(true), 50);
    } else {
      setClickActive(false);
    }

    return () => clearTimeout(timer);
  }, [isOpen]);

  useEffect(() => {
    const modalRootEl = document.getElementById(MODAL_ROOT_ID);

    const checkModalContent = () => {
      if (modalRootEl) {
        setIsCommonModalOpen(modalRootEl.hasChildNodes());
      }
    };

    checkModalContent();

    const observer = new MutationObserver(checkModalContent);
    if (modalRootEl) {
      observer.observe(modalRootEl, { childList: true });
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  const jsx = (
    <div
      ref={rootRef}
      className={cn(cls.root, className, {
        [cls.root_open]: isClickActive,
        [cls.root_modal]: withModal && !desktop,
        [cls.root_clickable]: isOpen,
        [cls.root_fullheight]: fullHeight,
        [cls.root_nohover]: noHover
      })}
      style={
        withModal && isOpen && !desktop
          ? {}
          : {
              top: `${dropdownPosition.top}px`,
              [isRTL ? 'right' : 'left']: `${dropdownPosition.left}px`,
              width: dropdownWidth,
              position: fixed ? 'fixed' : 'absolute',
              zIndex: alwaysOnTop || isCommonModalOpen ? 9999 : 99
            }
      }
    >
      <ul
        className={cn(cls.list, {
          ['hide-scrollbar']: !showScrollbar,
          ['custom-scrollbar']: showScrollbar,
          [cls.actions_list]: actionsModal && !isDesktop
        })}
      >
        {children}
      </ul>
    </div>
  );

  return (
    <>
      <div
        id={anchorElementId}
        ref={anchorRef}
        className={cn(anchorClassName, {
          [cls.anchor_fullwidth]: fullWidth
        })}
      />

      {isFirstOpen &&
        ReactDOM.createPortal(
          <>
            {!desktop && withModal ? (
              <BottomModal
                name={name}
                isOpen={isOpen}
                close={close}
                containerClass={cn(
                  cls.modal_container,
                  cls.modal_container_imp,
                  modalClassName,
                  { [cls.actions_modal]: actionsModal && !desktop }
                )}
              >
                {jsx}
              </BottomModal>
            ) : (
              jsx
            )}
          </>,
          document.body
        )}
    </>
  );
};

// Dropdown item
type ItemProps = {
  children: React.ReactNode;
  active?: boolean;
  className?: string;
};

export const DropdownItem: React.FC<ItemProps> = ({
  children,
  active,
  className
}) => {
  return (
    <li className={cn(cls.li, className)} data-active={active}>
      {children}
    </li>
  );
};
