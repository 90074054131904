
import { Profile } from '@/modules/chat/Detail/Profile/Profile';
import { useChatDetailStore } from '@/modules/chat/Detail/store';
import { cn } from '@/utils/cn';

import cls from './Profile.module.scss';

export function ProfileWrapper() {
  const isProfileOpen = useChatDetailStore((s) => s.isProfileOpen);

  return (
    <div className={cn(cls.wrapper, { [cls.wrapper_open]: isProfileOpen })}>
      <Profile />
    </div>
  );
}
