import Tooltip from 'rc-tooltip';
import { useMemo, useState } from 'react';
import { Collapse } from 'react-collapse';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Nullable } from '@/app/types';
import { useImgPlaceholder } from '@/hooks/useImgPlaceholder';
import { useLang } from '@/hooks/useLang';
import { useOnceTrue } from '@/hooks/useOnceTrue';
import { DiscountType } from '@/modules/discount/types';
import { EmojiType } from '@/modules/emojis/types';
import { PromotionPackage } from '@/modules/promotion/types';
import { usePermissionAccess } from '@/modules/roles/helpers';
import { useAdvertName } from '@/modules/showroom/advert/hooks';
import { getMyAds } from '@/modules/showroom/advert/my/api';
import { Actions } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Actions/Actions';
import { MyAdvertCardContext } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/ctx';
import { Discounts } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Discounts/Discounts';
import { AdEffectivenessColorMap } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/helpers';
import { PriceEdit } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/PriceEdit/PriceEdit';
import { Promotions } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Promotions/Promotions';
import { Statistics } from '@/modules/showroom/advert/my/MyAdvertsList/MyAdvertCard/Statistics/Statistics';
import { MyAdvert } from '@/modules/showroom/advert/my/types';
import {
  defaultSearchReq,
  stringifySearchReq
} from '@/modules/showroom/advert/search/helpers';
import { SearchAdvertsReq } from '@/modules/showroom/advert/search/types';
import { AdvertStatusType } from '@/modules/showroom/advert/types';
import { updateAdvertisement } from '@/modules/showroom/advert/update/api';
import { VRP_CATEGORY_ID } from '@/modules/showroom/advert/vrp/helpers';
import { AdEffectiveness } from '@/ui/AdEffectiveness/AdEffectiveness';
import { ImgSkeleton } from '@/ui/ImgSkeleton/ImgSkeleton';
import { Spinner } from '@/ui/Spinner/Spinner';
import { cn } from '@/utils/cn';
import { ADV_CUR, APP_URL, rcTooltipProps } from '@/utils/consts';
import { getDateString, isThisYear, decomposeTime } from '@/utils/date';
import { numberWithSpaces, pluralKey } from '@/utils/format';
import { showAlert } from '@/utils/network';

import {
  EditIcon,
  EyeIcon,
  HeartIcon,
  StatsIcon,
  ClickIcon,
  LeadIcon,
  PlayIcon
} from './icons';
import cls from './MyAdvertCard.module.scss';

type Props = {
  advert: MyAdvert;
  onPublishToggle?: () => void;
  lastChild?: boolean;
  promotionsList?: PromotionPackage[];
  stickersList?: EmojiType[];
  discountTypes?: DiscountType[];
  euroRate?: Nullable<number>;
  usdRate?: Nullable<number>;
};

export function MyAdvertCard({
  advert: advertProp,
  onPublishToggle,
  promotionsList,
  stickersList,
  discountTypes,
  euroRate,
  usdRate,
  lastChild
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  // Ad
  const [advert, setAdvert] = useState(advertProp);
  const { engagement } = advert;

  // Refresh
  const refreshAdvert = async () => {
    const req: Partial<SearchAdvertsReq> = {
      category_id: advert.category.id,
      price_min: advert.price ? advert.price - 1 : null,
      price_max: advert.price ? advert.price + 1 : null
    };

    try {
      const r = await getMyAds(req);
      const ad = r.data.find((v) => v.id === advert.id);
      if (ad) setAdvert((prev) => ({ ...prev, ...ad }));
    } catch (error) {
      console.error(error);
    }
  };

  // Image, name
  const imgPlaceholder = useImgPlaceholder();
  const imgSrc = useMemo(() => {
    if (advert.vrp_image_url) return advert.vrp_image_url;
    if (advert.photos && advert.photos[0]) {
      return advert.photos[0].thumbnail_url || advert.photos[0].photo_url;
    }

    return imgPlaceholder;
  }, [advert.photos, advert.vrp_image_url, imgPlaceholder]);
  const name = useAdvertName(advert);

  // Flags
  const isDraft = advert.status.title === AdvertStatusType.draft;
  const isPublished = advert.status.title === AdvertStatusType.published;
  const isModeration = advert.status.title === AdvertStatusType.moderation;
  const isRejected = advert.status.title === AdvertStatusType.rejected;
  const isSold = advert.status.title === AdvertStatusType.sold;
  const showStatus = !isPublished;
  const isVrp = advert.category.id === VRP_CATEGORY_ID;

  const showPromo = isPublished && !!promotionsList && !!stickersList;
  const showPromoOnce = useOnceTrue(showPromo);

  // Urls
  const advertUrl = advert.url ? `${APP_URL}/${advert.url}` : '';
  const editUrl = isVrp ? `/vrp/edit/${advert.id}` : `/cars/edit/${advert.id}`;

  const searchPositionUrl = useMemo(() => {
    if (!advert.brand?.id || !advert.model?.id) return '';

    const url = stringifySearchReq({
      ...defaultSearchReq,
      auto_filter: [
        {
          brand_id: advert.brand?.id,
          model_id: advert.model?.id,
          generation_id: null
        }
      ]
    });
    return `${APP_URL}/sale/search?${url}&results=true`;
  }, [advert.brand?.id, advert.model?.id]);

  // Modification
  const modName = useMemo(() => {
    const m = advert.modification;
    if (m) {
      if (m.horse_power) {
        const hp = t('horsepower', { value: m.horse_power?.name });
        const v = m.volume?.name;
        const eng = m.engine.short_name;
        if (hp && v && eng)
          return [v, hp, eng].join(
            `${t('common.spaceSeparator')}${t('common.slashSeparator')}`
          );
      }

      return m.name;
    }

    return '';
  }, [advert.modification, t]);

  // Dates
  const publishedAtStr = useMemo(() => {
    if (!advert.published_at) return '';
    const date = new Date(advert.published_at * 1000);

    const thisYear = isThisYear(date);
    const dateStr = getDateString(date, lang, {
      day: '2-digit',
      month: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      year: thisYear ? undefined : 'numeric'
    });
    return t('advertisements.my.publishedAt', { date: dateStr });
  }, [advert.published_at, lang, t]);

  const updatedAtStr = useMemo(() => {
    if (!advert.updated_at_by_request_update) return '';
    const date = new Date(advert.updated_at_by_request_update * 1000);

    const thisYear = isThisYear(date);
    const dateStr = getDateString(date, lang, {
      day: '2-digit',
      month: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      year: thisYear ? undefined : 'numeric'
    });
    return t('advertisements.my.updatedAt', { date: dateStr });
  }, [advert.updated_at_by_request_update, lang, t]);

  const publishDatesStr = useMemo(
    () => `${publishedAtStr}, ${updatedAtStr}`,
    [publishedAtStr, updatedAtStr]
  );

  const publicationTime = useMemo(() => {
    if (!advert.published_at) return null;
    const decomposedTime = decomposeTime(advert.published_at);
    const count =
      decomposedTime.days || decomposedTime.hours || decomposedTime.minutes;
    const pluralPrefix = decomposedTime.days
      ? 'days'
      : decomposedTime.hours
      ? 'hours'
      : 'minutes';

    return {
      count,
      unitWord: pluralKey(
        count,
        t(`publish.${pluralPrefix}Plural.one`),
        t(`publish.${pluralPrefix}Plural.few`),
        t(`publish.${pluralPrefix}Plural.many`)
      )
    };
  }, [advert.published_at, t]);

  // Price
  const permissions = usePermissionAccess();
  const canEditPrice = isVrp
    ? permissions.plateSale.control
    : permissions.carSale.control;
  const [isPriceLoading, setPriceLoading] = useState(false);
  const [isPriceOpen, setPriceOpen] = useState(false);

  const openPriceEdit = () => {
    if (canEditPrice) setPriceOpen(true);
  };

  const savePrice = async (updatedAdvert: MyAdvert) => {
    if (isPriceLoading) return;
    setPriceLoading(true);

    try {
      await updateAdvertisement(advert.id, {
        price: updatedAdvert.price,
        discount: {
          discount_amount: updatedAdvert.discount?.discount_amount || 0,
          discount_ids:
            updatedAdvert.discount?.discounts?.map((d) => d.id) || []
        }
      });
      setAdvert(updatedAdvert);
    } catch (error) {
      showAlert({ error });
    } finally {
      setPriceLoading(false);
    }
  };

  const onEditPriceClick = () => {
    if (isPublished) {
      openPriceEdit();
    }
  };

  // Statistics
  const [statsOpened, setStatsOpened] = useState(false);
  const statsOnceOpened = useOnceTrue(statsOpened);

  // Hover
  const [hovered, setHovered] = useState(false);

  const PubDays: React.FC<{ className: string }> = ({ className }) => (
    <Tooltip
      placement="top"
      overlay={publishDatesStr}
      overlayClassName={cls.tooltip_overlay}
      {...rcTooltipProps}
    >
      <p
        className={cn(cls.days, className)}
        style={{
          visibility: isPublished ? 'visible' : 'hidden'
        }}
      >
        {publicationTime && (
          <Trans
            t={t}
            i18nKey="publish.publishTime"
            components={{ countWrap: <b /> }}
            values={{
              count: publicationTime.count,
              unitWord: publicationTime.unitWord
            }}
          />
        )}
      </p>
    </Tooltip>
  );

  // Stats
  const [visibleStatIndex, setVisibleStatIndex] =
    useState<Nullable<number>>(null);
  const onStatMouseEnter = (index: number) => {
    setVisibleStatIndex(index);
  };
  const onStatMouseLeave = () => {
    setVisibleStatIndex(null);
  };

  const StatsRow = ({
    label,
    icon,
    index,
    count
  }: {
    label: string;
    icon: React.ReactNode;
    index: number;
    count: number;
  }) => (
    <li>
      <div
        className={cls.stats_row}
        onMouseEnter={() => onStatMouseEnter(index)}
        onMouseLeave={onStatMouseLeave}
      >
        <Tooltip
          placement="top"
          overlay={label}
          visible={visibleStatIndex === index}
          {...rcTooltipProps}
        >
          <div className={cls.stats_icon_wrap}>{icon}</div>
        </Tooltip>
        <p>{numberWithSpaces(count, lang)}</p>
      </div>
    </li>
  );

  const SearchPosition: React.FC<{ color?: string }> = ({ color }) => (
    <Tooltip
      placement="top"
      overlay={t('advertisements.searchPositionOverlay')}
      overlayClassName={cls.tooltip_overlay}
      {...rcTooltipProps}
    >
      <a
        href={searchPositionUrl}
        target="_blank"
        rel="noreferrer"
        className={cls.search_position}
        style={{
          color,
          visibility: isPublished ? 'visible' : 'hidden'
        }}
      >
        {t('advertisements.searchPosition', {
          currentPosition: advert.current_position,
          fromPosition: advert.current_position_from
        })}
      </a>
    </Tooltip>
  );

  return (
    <div
      className={cn(cls.root, { [cls.root_gap]: statsOpened })}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <MyAdvertCardContext.Provider value={{ isPublished, onEditPriceClick }}>
        <div className={cls.advert}>
          {isPublished && advertUrl && (
            <a
              className={cls.link}
              href={advertUrl}
              target="_blank"
              rel="noreferrer"
            />
          )}

          <div className={cls.img}>
            <ImgSkeleton
              className={cls.img_inner}
              src={imgSrc}
              alt=""
              imgProxyWidth={232}
              imgProxyHeight={174}
            />
            {isDraft && <Link className={cls.link} to={editUrl} />}
            {!!advert.reels && (
              <div className={cls.reel_icon}>
                <PlayIcon />
              </div>
            )}
            {showStatus && (
              <span
                className={cn(cls.status, {
                  [cls.status_green]: isSold,
                  [cls.status_orange]: isModeration
                })}
              >
                {advert.status.name}
              </span>
            )}
          </div>

          <div className={cls.info}>
            <p className={cls.name_wrap}>
              <b className={cls.name}>{name}</b>
              {isPublished && advertUrl ? (
                <a
                  className={cls.link}
                  href={advertUrl}
                  target="_blank"
                  rel="noreferrer"
                />
              ) : (
                <Link className={cls.link} to={editUrl} />
              )}
            </p>

            {!isVrp ? (
              <div className={cls.specs_wrap}>
                <ul className={cls.specs}>
                  <li>
                    <p>
                      <span>
                        {t('mileageKm', {
                          mileage: numberWithSpaces(advert.mileage || 0, lang)
                        })}
                      </span>
                    </p>
                  </li>

                  {advert.modification && (
                    <>
                      <li>
                        <p>{advert.modification?.drive_unit.short_name}</p>
                      </li>
                      <li>
                        <p>{advert.modification?.transmission.short_name}</p>
                      </li>
                    </>
                  )}
                </ul>
                <ul className={cls.specs}>
                  {modName && (
                    <li>
                      <p>
                        <span>{modName}</span>
                      </p>
                    </li>
                  )}

                  {advert.body && (
                    <li>
                      <p>{advert.body.name}</p>
                    </li>
                  )}

                  {advert.regional_spec && (
                    <li>
                      <p>{advert.regional_spec.name}</p>
                    </li>
                  )}
                </ul>
              </div>
            ) : (
              <div className={cls.specs_wrap}>
                <ul className={cls.specs}>
                  <li>
                    <p>
                      <span>
                        {advert.vrp_plate_code?.name} {advert.vrp_plate_number}
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            )}

            <div
              className={cn(cls.promotions, {
                [cls.promotions_visible]: showPromoOnce
              })}
            >
              {showPromo && (
                <Promotions
                  advert={advert}
                  promotionsList={promotionsList}
                  stickersList={stickersList}
                  hovered={hovered}
                  onPublishToggle={refreshAdvert}
                />
              )}
            </div>
          </div>

          <div className={cls.price_col}>
            <div className={cls.price_wrap}>
              <b className={cls.price}>
                {t('priceCurrency', {
                  price: numberWithSpaces(
                    advert.price ? Number(advert.price) : 0,
                    lang
                  ),
                  currency: ADV_CUR
                })}
              </b>

              {isPublished && discountTypes && canEditPrice && (
                <>
                  {isPriceLoading ? (
                    <Spinner color="var(--clr-blue)" size={16} />
                  ) : (
                    <button
                      className={cls.edit_btn}
                      type="button"
                      onClick={() => openPriceEdit()}
                    >
                      <EditIcon />
                    </button>
                  )}

                  <PriceEdit
                    advert={advert}
                    discountTypes={discountTypes}
                    isOpen={isPriceOpen}
                    close={() => setPriceOpen(false)}
                    savePrice={savePrice}
                    loading={isPriceLoading}
                    euroRate={euroRate}
                    usdRate={usdRate}
                  />
                </>
              )}
            </div>

            {advert.discount && (
              <Discounts advert={advert} setPriceEditOpen={setPriceOpen} />
            )}

            <div className={cls.ad_effectiveness}>
              <AdEffectiveness
                adEffectiveness={advert.ad_effectiveness}
                showInfoDropdown
              />
              {!isVrp && (
                <div className={cls.position_mob}>
                  <SearchPosition
                    color={
                      advert.ad_effectiveness
                        ? AdEffectivenessColorMap[
                            advert.ad_effectiveness.id as number
                          ]
                        : undefined
                    }
                  />
                </div>
              )}
            </div>

            <PubDays className={cls.days_mob} />

            {!isVrp && (
              <div className={cls.position_mob}>
                <SearchPosition />
              </div>
            )}
          </div>

          <div className={cls.pub_details}>
            <AdEffectiveness
              adEffectiveness={advert.ad_effectiveness}
              showInfoDropdown
            />

            {!isVrp && (
              <div className={cls.position_desktop}>
                <SearchPosition />
              </div>
            )}

            <PubDays className={cls.days_desktop} />
          </div>

          <div className={cls.right_col}>
            <ul
              className={cls.stats}
              style={{
                visibility: isPublished || isSold ? 'visible' : 'hidden'
              }}
            >
              <StatsRow
                label={t('views.shows')}
                icon={<EyeIcon />}
                index={0}
                count={engagement.count_shows}
              />

              <StatsRow
                label={t('views.views')}
                icon={<ClickIcon />}
                index={1}
                count={engagement.count_views}
              />

              <StatsRow
                label={t('views.leads')}
                icon={<LeadIcon />}
                index={2}
                count={engagement.count_leads}
              />

              <StatsRow
                label={t('views.inFavsNow')}
                icon={<HeartIcon />}
                index={3}
                count={engagement.count_add_to_favorites}
              />

              {!isModeration && !isRejected && !isDraft && (
                <li>
                  <button
                    className={cls.stats_row}
                    type="button"
                    onClick={() => setStatsOpened((prev) => !prev)}
                  >
                    <StatsIcon />
                    <span>
                      {t(statsOpened ? 'common.hide' : 'common.detailed')}
                    </span>
                  </button>
                </li>
              )}
            </ul>

            <div className={cls.actions}>
              <Actions
                advert={advert}
                onPublishToggle={onPublishToggle}
                dropdownTop={lastChild}
              />
            </div>
          </div>
        </div>

        {(isPublished || isSold) && statsOnceOpened && (
          <Collapse isOpened={statsOpened}>
            <Statistics advertId={advert.id} />
          </Collapse>
        )}
      </MyAdvertCardContext.Provider>
    </div>
  );
}
