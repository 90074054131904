import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMobile } from '@/app/common-store';
import { useShare } from '@/hooks/useShare';
import { LANGS_MAP } from '@/modules/i18n';
import { AdvertDetail } from '@/modules/showroom/advert/types';
import { Button } from '@/ui/Button/Button';
import { AdaptiveModal } from '@/ui/modals/AdaptiveModal';
import { cn } from '@/utils/cn';
import { APP_URL, CURRENCY_DISPLAY } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';
import {
  shareInFacebook,
  shareInMail,
  shareInTelegram,
  shareInWhatsapp
} from '@/utils/share';

import {
  CloseIcon,
  CopyIcon,
  GlobeIcon,
  TelegramIcon,
  WhatsAppIcon,
  FacebookIcon,
  MailIcon
} from './icons';
import cls from './ShareModal.module.scss';

type Props = {
  isOpen: boolean;
  close: VoidFunction;
  shareUrl?: string;
  username?: string;
  advert?: {
    brand?: AdvertDetail['brand'];
    model?: AdvertDetail['model'];
    generation?: AdvertDetail['generation'];
    year_of_issue?: AdvertDetail['year_of_issue'];
    price?: AdvertDetail['price'];
    url: AdvertDetail['url'];
  };
};

export function ShareModal({
  isOpen,
  close,
  username,
  advert,
  shareUrl: shareUrlProp
}: Props) {
  const { t } = useTranslation();

  const isMobile = useMobile(500);

  const shareUrl = useMemo(() => {
    if (shareUrlProp) {
      return shareUrlProp;
    }

    if (username) {
      return `${APP_URL}/${username}`;
    }

    if (advert) {
      return `${APP_URL}/${advert.url}/`;
    }

    return APP_URL;
  }, [advert, shareUrlProp, username]);

  const shareText = useMemo(() => {
    if (username) {
      return `Look what I found on AUTO.AE!`;
    }

    if (advert) {
      const price = advert.price || 0;
      const brand = advert.brand?.name || '';
      const model = advert.model?.name || '';
      const generation =
        ('generation' in advert && advert.generation?.name) || '';
      const year =
        ('year_of_issue' in advert && advert.year_of_issue?.name) || '';
      const adName = [brand, model, generation].filter(Boolean).join(' ');
      const adNameWithYear =
        adName && year
          ? t('common.commaSeparated', { first: adName, second: year })
          : adName || year;

      if (price)
        return `Look at this car: ${adNameWithYear} for ${numberWithSpaces(
          price,
          LANGS_MAP.en
        )} ${CURRENCY_DISPLAY.aed} on AUTO.AE!`;

      return `Look at this car: ${adNameWithYear} on AUTO.AE!`;
    }

    return '';
  }, [advert, t, username]);

  const shareTextWithUrl = useMemo(() => {
    if (username) {
      return `${shareUrl}\n${shareText}`;
    }

    return '';
  }, [shareText, shareUrl, username]);

  const onCopyClick = useShare(shareText, shareUrl);

  return (
    <AdaptiveModal
      isOpen={isOpen}
      close={close}
      name={`share-${username}`}
      containerClass={cn(cls.modal_container, cls.modal_container_imp)}
    >
      <div className={cls.root}>
        <div className={cls.header}>
          <h2 className={cls.title}>{t('common.share')}</h2>
          <button className={cls.close_btn} type="button" onClick={close}>
            <CloseIcon />
          </button>
        </div>

        <ul className={cls.socials}>
          <li>
            <a
              href={shareInTelegram(shareUrl, shareText)}
              className={cls.social}
              target="_blank"
              rel="noreferrer"
            >
              <TelegramIcon />
              <span>{t('common.telegram')}</span>
            </a>
          </li>
          <li>
            <a
              href={shareInWhatsapp(shareTextWithUrl)}
              className={cls.social}
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppIcon />
              <span>{t('common.whatsapp')}</span>
            </a>
          </li>
          <li>
            <a
              href={shareInFacebook(shareUrl, shareText)}
              className={cls.social}
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
              <span>{t('common.facebook')}</span>
            </a>
          </li>
          <li>
            <a
              href={shareInMail(shareTextWithUrl, shareText)}
              className={cls.social}
              target="_blank"
              rel="noreferrer"
            >
              <MailIcon />
              <span>{t('common.mail')}</span>
            </a>
          </li>
        </ul>

        <div className={cls.copy_row}>
          <GlobeIcon />
          <p className={cls.copy_text}>{shareUrl}</p>
          <div className={cls.copy_btn}>
            <Button
              gap={8}
              color="blue"
              size={isMobile ? 'compact' : 'l'}
              variant="primary"
            >
              <CopyIcon />
              {t('common.copyLink')}
            </Button>
          </div>
          <button
            className={cls.copy_click}
            type="button"
            onClick={onCopyClick}
          />
        </div>
      </div>
    </AdaptiveModal>
  );
}
