import { EmirateType } from '@/modules/emirate/types';
import {
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { useVrpAdvertUpdateFlags } from '@/modules/showroom/advert/vrp/CreateVrp/hooks';
import { generatePlateSnapshot } from '@/modules/showroom/advert/vrp/CreateVrp/VrpNumber/helpers';
import { VrpNumber } from '@/modules/showroom/advert/vrp/CreateVrp/VrpNumber/VrpNumber';
import { VRP_MOTO_TYPE_ID } from '@/modules/showroom/advert/vrp/helpers';
import { useUpdateVrpStore } from '@/modules/showroom/advert/vrp/UpdateVrp/store';

type Props = {
  emirates: EmirateType[];
};

export function VrpNumberWrap({ emirates }: Props) {
  const emirateId = useUpdateVrpStore((s) => s.emirateId);
  const setEmirateId = useUpdateVrpStore((s) => s.setEmirateId);
  const typeId = useUpdateVrpStore((s) => s.typeId);
  const setTypeId = useUpdateVrpStore((s) => s.setTypeId);
  const codeId = useUpdateVrpStore((s) => s.codeId);
  const setCodeId = useUpdateVrpStore((s) => s.setCodeId);
  const plateNumber = useUpdateVrpStore((s) => s.plateNumber);
  const setPlateNumber = useUpdateVrpStore((s) => s.setPlateNumber);
  const isNumberSaved = useUpdateVrpStore((s) => s.isNumberSaved);
  const setNumberSaved = useUpdateVrpStore((s) => s.setNumberSaved);
  const numberWasSaved = useUpdateVrpStore((s) => s.numberWasSaved);

  const previewUrl = useUpdateVrpStore((s) => s.previewUrl);
  const setPreviewUrl = useUpdateVrpStore((s) => s.setPreviewUrl);
  const setImageUrl = useUpdateVrpStore((s) => s.setImageUrl);

  const { isPublished } = useVrpAdvertUpdateFlags();

  const onSaveClick = (preview: string) => {
    generatePlateSnapshot(
      preview,
      typeId === VRP_MOTO_TYPE_ID,
      (uploadedSrc) => {
        if (uploadedSrc) {
          setImageUrl(uploadedSrc);
          setNumberSaved(true);
          scrollToSection(SCROLLTO.descr, 100);
        }
      }
    );
  };

  return (
    <VrpNumber
      preview={previewUrl}
      setPreview={setPreviewUrl}
      emirates={emirates}
      emirateId={emirateId}
      onEmirateChange={setEmirateId}
      typeId={typeId}
      onTypeChange={setTypeId}
      codeId={codeId}
      onCodeChange={setCodeId}
      number={plateNumber}
      setNumber={setPlateNumber}
      isSaved={isNumberSaved}
      onChangeClick={() => setNumberSaved(false)}
      onSaveClick={onSaveClick}
      numberWasSaved={numberWasSaved}
      canChange={!isPublished}
    />
  );
}
