import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLang } from '@/hooks/useLang';
import { ChatAdName } from '@/modules/chat/ChatList/ChatCard/ChatAdName';
import { handleText } from '@/modules/chat/Detail/Messages/Message/helpers';
import { ChatWithFoundedMessage } from '@/modules/chat/types';
import { cn } from '@/utils/cn';
import { ADV_CUR } from '@/utils/consts';
import { numberWithSpaces } from '@/utils/format';

import cls from './ChatCard.module.scss';
import { ChatImage } from './ChatImage';

type Props = {
  chat: ChatWithFoundedMessage;
  onClick?: () => void;
  link?: boolean;
  active?: boolean;
};

export function FoundChatCard({ chat, onClick, link, active }: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const { ad, companion, founded_message, unread_count } = chat;

  const msgText = founded_message?.text || '';
  const showTop = !!companion && !!companion.name;

  return (
    <div className={cn(cls.root, { [cls.root_active]: active })}>
      {link && <Link className={cls.link} to={`/chat/${chat.id}`} />}
      {onClick && (
        <button className={cls.link} type="button" onClick={onClick} />
      )}

      <ChatImage chat={chat} />

      <div className={cls.wrapper}>
        {showTop && (
          <div className={cls.top}>
            {companion && companion.name && (
              <p className={cn(cls.title, cls.trunc)}>{companion.name}</p>
            )}
          </div>
        )}

        <div className={cls.content}>
          <div className={cls.text}>
            {ad && (
              <ul className={cls.stats}>
                {ad.price && (
                  <li>
                    {t('priceCurrency', {
                      price: numberWithSpaces(ad.price, lang),
                      currency: ADV_CUR
                    })}
                  </li>
                )}
                <li>
                  <ChatAdName advert={ad} />
                </li>
              </ul>
            )}

            {msgText && (
              <p
                className={cls.trunc}
                dangerouslySetInnerHTML={{
                  __html: handleText(msgText, true)
                }}
              />
            )}
          </div>

          {!!unread_count && <p className={cls.unread}>{unread_count}</p>}
        </div>
      </div>
    </div>
  );
}
